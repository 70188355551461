// React
import React, { useState } from 'react';

// Material UI Components
import { Box, Button, MenuItem, Select, Typography, styled, Dialog } from '@mui/material';

// Components and functions for the project
import { steps } from '../index.js';
import Titles from '../../../components/Titles/Titles.js';
import themeColors from '../../../components/ThemeColors/ThemeColors.js';

// mock plans
const plans = [
    {
        planName: 'FreeZap',
        planPrice: '0,00',
        channels: ['WhatsApp', 'Email'],
        messagesPerMonth: 600,
        features: ['Auto-resposta', 'painel-gerenciamento']
    },
    {
        planName: 'StartZap',
        planPrice: '99,90',
        channels: ['WhatsApp', 'Email'],
        messagesPerMonth: 2000,
        features: ['Auto-resposta', 'Painel Gerenciamento', 'Relatórios com I.A']
    },
    {
        planName: 'ProZap',
        planPrice: '249,90',
        channels: ['WhatsApp', 'Email', 'SMS'],
        messagesPerMonth: 4000,
        features: ['Auto-resposta', 'Painel Gerenciamento', 'Relatórios com I.A', 'Integrações com API']
    },
    {
        planName: 'UltraZap',
        planPrice: '449,90',
        channels: ['WhatsApp', 'Email', 'WhatsApp Business', 'SMS'],
        messagesPerMonth: 'ilimitadas',
        features: ['Auto-resposta', 'Painel Gerenciamento', 'Relatórios com I.A', 'Integrações com API']
    },
];

// Customizating the Dialog Component
const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .css-1hju3x6-MuiPaper-root-MuiDialog-paper': {
        borderRadius: "20px",
        width: "300px",
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    '& .css-hz1bth-MuiDialog-container': {
        height: "100vh",
        width: "100vw",
        position: "fixed",
        top: "0",
        left: "0",
        display: "block",
    }
}))

// Render the Plan Card
const PlanCard = ({ plan, selectedPlan, setShowModal, setPlanToShow }) => {

    return (
        <Box
            sx={{
                display: 'flex',
                padding: '10px',
                border: `1px solid ${plan === selectedPlan ? themeColors.purple400 : themeColors.gray30}`,
                background: `${plan === selectedPlan ? '#E8F0FF' : '#FFF'}`,
                borderRadius: '6px',
                width: '100%',
                gap: '10px',
                cursor: 'pointer',
                transition: '.2s',
                justifyContent: 'space-between'
            }}
        >
            <Box display={'flex'} flexDirection={'column'}>
                <Titles children={plan.planName} bold />
                <Titles children={`R$ ${plan.planPrice}/m`} color={themeColors.gray40} />
            </Box>
            <Button onClick={() => {
                setShowModal(true);
                setPlanToShow(plan);
            }} variant='outlined' sx={{ fontSize: '10px', padding: '0 10px', alignSelf: 'end' }}>
                Saiba Mais
            </Button>
        </Box>
    )
}

// Render the modal
const PlanModal = ({ isOpen, onClose, plan, setSelectedPlan }) => {

    const closeModal = () => {
        onClose(false);
    };

    return (
        <CustomDialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sx"
        onBackdropClick={closeModal}>
            <Box 
            sx={{
                background: '#fff',
                padding: '30px 20px',
                display: 'flex',
                flexDirection: 'column',
                minWidth: '100%',
                gap: '3px'
            }}>
                <Titles type={'H4'} children={`R$ ${plan?.planPrice} /mês`} bold styles={{ marginBottom: '10px' }} />
                <Titles type={'P1'} children={`${plan?.planName}`} bold styles={{ marginBottom: '20px' }} color={themeColors.purple500} />

                <Titles children={`Acesso aos canais:${plan?.channels.map((channel) => ` ${channel}`)}`} color={themeColors.gray40}  />
                <Titles children={`Funcionalidades:${plan?.features.map((feature) => ` ${feature}`)}`} color={themeColors.gray40}  />
                <Titles children={`${plan?.messagesPerMonth} mensagens por mês`} color={themeColors.gray40}  />
                
                <Button onClick={() => {
                    setSelectedPlan(plan);
                    closeModal();
                }} variant='contained' sx={{ marginTop: '20px', borderRadius: '20px' }}>
                    Selecionar
                </Button>
            </Box>
        </CustomDialog>
    )
}

const Step5 = ({ message, selectedPlan, setSelectedPlan, paymentForm, setPaymentForm }) => {
    const [showModal, setShowModal] = useState(false);
    const [planToShow, setPlanToShow] = useState(null);

    return (
        <>
            <PlanModal plan={planToShow} isOpen={showModal} onClose={() => setShowModal(false)} setSelectedPlan={setSelectedPlan} />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden'
                }}
            >
                <Titles
                    type={'H3'}
                    children={steps[4].stepTitle}
                    bold
                    color={themeColors.purple500}
                />
                <Titles
                    styles={{ marginBottom: '20px', width: '100%' }}
                    type={'P2'}
                    children={'Agora vamos escolher um plano e a forma de pagamento para concluir seu cadastro.'}
                    color={themeColors.gray30}
                />

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px',
                        }}
                    >
                        <Titles children={'Escolha um plano'} />
                        <Box
                            sx={{
                                display: 'grid',
                                gap: '16px',
                                gridTemplateColumns: 'repeat(2, 1fr)'
                            }}
                        >
                            {plans.map((plan) => <PlanCard key={plan.planName} setPlanToShow={setPlanToShow} plan={plan} setSelectedPlan={setPlanToShow} selectedPlan={selectedPlan} setShowModal={setShowModal} />)}
                        </Box>
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                color: '#52525B',
                                fontSize: '14px',
                                fontFamily: 'Satoshi',
                                marginBottom: '7px',
                            }}
                        >
                            Forma de Pagamento
                        </Typography>
                        <Select
                            value={paymentForm}
                            fullWidth
                            displayEmpty
                            onChange={(e) => setPaymentForm(e.target.value)}
                            sx={{
                                '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                                    border: `1px solid ${themeColors.gray30}`,
                                    borderRadius: '6px',
                                    padding: '9px'
                                },
                                '.css-1ge0os-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root': {
                                    fontSize: '12px'
                                }
                            }}>
                            <MenuItem value='' disabled>Escolha a forma de pagamento</MenuItem>
                            <MenuItem value='PIX'>PIX</MenuItem>
                        </Select>
                    </Box>
                </Box>
            </Box>
        </>
    )
};

export default Step5