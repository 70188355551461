  
import axios from 'axios';

export async function GetVariables() {
    try {
        const token = localStorage.getItem('jwt_token');

        const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/variables`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            }
        );
        const responseData = response.data;

        return responseData;
    } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
    }
}