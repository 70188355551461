// Desc: Page for self register
// Update in: 16/09/2024

// React Components and DOM Elements
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// Material-UI Components
import { Button, Box, Checkbox, FormControlLabel, Grid, Typography, TextField } from '@mui/material';

// Functions and Components
import useSelfRegister from './useSelfRegister';

// Archives and Styles
import Logo from '../../assets/ilustrations/Logo';
import SelfRegisterBackground from './self-register.png';
import Titles from '../../components/Titles/Titles';

export const steps = [
    {
        stepNumber: 1,
        stepTitle: 'Tipo de Usuário'
    },
    {
        stepNumber: 2,
        stepTitle: 'Informações Pessoais'
    },
    {
        stepNumber: 3,
        stepTitle: 'Endereço'
    },
    {
        stepNumber: 4,
        stepTitle: 'Acesso'
    },
    {
        stepNumber: 5,
        stepTitle: 'Plano e Pagamento'
    },
]

const SelfRegister = () => {
    // States 
    const { currentStep, nextStep, prevStep, renderCurrentStep, message } = useSelfRegister();

    return (
        <>
            <div style={{
                position: 'absolute',
                top: '20px',
                left: '20px',
                padding: '10px',
            }}>
                <Logo />
            </div>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#F9F9F9', flexDirection: 'column' }}>
            <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    padding: '10px',
                    background: message === 'Senha alterada com sucesso.' ? '#D3F8D3' : message ? '#F8D6D3' : 'transparent',
                    marginBottom: '20px',
                    marginTop: '-60px',
                    borderRadius: '10px',
                    marginLeft: '350px'
                }}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask0_34_1183" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                            <rect width="20" height="20" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_34_1183)">
                            <path fill={message === 'Senha alterada com sucesso.' ? 'rgb(47 120 60)' : message ? '#CD4747' : 'transparent'} d="M8 10.9743C8.11622 10.9743 8.21367 10.935 8.29233 10.8563C8.371 10.7778 8.41033 10.6804 8.41033 10.5642C8.41033 10.4478 8.371 10.3504 8.29233 10.2718C8.21367 10.1932 8.11622 10.1538 8 10.1538C7.88378 10.1538 7.78633 10.1932 7.70767 10.2718C7.629 10.3504 7.58967 10.4478 7.58967 10.5642C7.58967 10.6804 7.629 10.7778 7.70767 10.8563C7.78633 10.935 7.88378 10.9743 8 10.9743ZM7.66667 8.76917H8.33333V4.76917H7.66667V8.76917ZM8.00217 14C7.1725 14 6.39244 13.8426 5.662 13.5277C4.93167 13.2128 4.29633 12.7854 3.756 12.2457C3.21567 11.7059 2.78794 11.0711 2.47283 10.3413C2.15761 9.61167 2 8.83194 2 8.00217C2 7.1725 2.15744 6.39244 2.47233 5.662C2.78722 4.93167 3.21456 4.29633 3.75433 3.756C4.29411 3.21567 4.92889 2.78794 5.65867 2.47283C6.38833 2.15761 7.16806 2 7.99783 2C8.8275 2 9.60756 2.15744 10.338 2.47233C11.0683 2.78722 11.7037 3.21456 12.244 3.75433C12.7843 4.29411 13.2121 4.92889 13.5272 5.65867C13.8424 6.38833 14 7.16806 14 7.99783C14 8.8275 13.8426 9.60756 13.5277 10.338C13.2128 11.0683 12.7854 11.7037 12.2457 12.244C11.7059 12.7843 11.0711 13.2121 10.3413 13.5272C9.61167 13.8424 8.83194 14 8.00217 14Z" />
                        </g>
                    </svg>
                    <Typography style={{ color: message === 'Senha alterada com sucesso.' ? 'rgb(47 120 60)' : message ? '#CD4747' : 'transparent', fontSize: '12px', fontFamily: 'Satoshi', marginLeft: '10px' }}>
                        {message}
                    </Typography>
                </div>
                <Box
                    sx={{
                        width: '791px',
                        height: '523px',
                        padding: '30px',
                        borderRadius: '16px',
                        border: '1px solid #D9D9DC',
                        background: '#fff',
                        display: 'flex',
                        gap: '37px'
                    }}
                >
                    <Box
                        sx={{
                            backgroundImage: `url(${SelfRegisterBackground})`,
                            backgroundSize: 'cover',
                            minWidth: '309px',
                            height: '100%',
                            padding: '49px 0 132px 15px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px'
                        }}
                    >
                        {steps.map((step, index) => (
                            <Box
                                key={index}
                                height={'40px'}
                                display={'flex'}
                                gap={'10px'}
                            >
                                <Box sx={{
                                    display: 'flex',
                                    width: '40px',
                                    height: '40px',
                                    borderRadius: '50%',
                                    border: step.stepNumber === currentStep ? 'none' : '1px solid #F5F3FF',
                                    background: step.stepNumber === currentStep ? '#F5F3FF' : 'none',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    flexWrap: 'wrap'
                                }}>
                                    <Titles type={'P2'} children={step.stepNumber} color={step.stepNumber === currentStep ? '#000' : '#F5F3FF'} />
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '3px'
                                    }}
                                >
                                    <Titles type={'P2'} bold children={`Passo ${step.stepNumber}`} color={'#F5F3FF'} />
                                    <Titles type={'P2'} bold children={step.stepTitle} color={'#F5F3FF'} />
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    <Box
                        sx={{
                            width: '383px',
                            height: '100%',
                            paddingTop: '40px',
                            paddingRight: '23px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}
                    >
                        {renderCurrentStep()}
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Button
                                onClick={prevStep}
                                variant='outlined'
                                sx={{
                                    width: '89px',
                                    display: currentStep > 1 ? 'block' : 'none'
                                }}>
                                Voltar
                            </Button>
                            <Button
                                onClick={nextStep}
                                variant='contained'
                                sx={{
                                    width: '89px',
                                    marginLeft: 'auto'
                                }}>
                                Próximo
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box >
        </>
    );
};

export default SelfRegister;