// Desc: This is the page that shows the options of templates that the user can choose to create a new template.
// Update: 21/05/2024 (OK)

// React Components
import React, { useEffect, useState } from 'react';

// Material UI Components
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import CardList from '../../components/CardList/CardList';
import Footer from '../../components/Footer/Footer';

// Custom Components
import Titles from '../../components/Titles/Titles';
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import ThemeColors from '../../components/ThemeColors/ThemeColors';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';

// Render the page with the options of templates
const PageOptionsTemplate = (props) => {

    // Loading Transition state
    const [showLoading, setShowLoading] = useState(true);
    const navigate = useNavigate();

    const data = [
       
        {
            icon: 'Financeiro',
            title: 'Financeiro',
            text: 'Avisos de cobrança, faturas e lembretes de pagamento. personalize mensagens para manter seus clientes informados.',
            onClick: () => {
                localStorage.removeItem('isEmailTemplatePersonalized')
                navigate('/modelos/canal/template/financeiro')
            }
        },
    ];

    useEffect(() => {
        setTimeout(() => setShowLoading(false), 1000);
    }, []);

    if (showLoading) {
        return <Loading open={true} />;
    }

    return (
        <Box>
            <Grid container spacing={2}>
                <HeaderPage title="Modelos" />
            </Grid>
            <div style={{ borderRadius: '2px', overflow: 'hidden', padding: '40px' }}>
                <Grid container spacing={2} justify="center" style={{ marginBottom: '20px' }}>
                    <Grid item xs={12} sm={12}>

                        <div style={{ marginBottom: '20px' }}>
                            <Titles type='H3' color={ThemeColors.gray40} style="medium">Escolha um template inicial</Titles>
                        </div>
                        <CardList data={data} />
                    </Grid>

                </Grid>
                <div style={{ position: 'absolute', bottom: 0 }}>
                    <Footer />
                </div>
            </div>
        </Box>
    );
};

export default PageOptionsTemplate;

