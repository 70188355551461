// This endpoint is used to check if the QR code is in the database.
// The response is an object with a message.
// Author: Vitor de Jesus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/136806402/Channels

import axios from 'axios';

export async function ChannelCheckQRCode(code) {
    try {
        
        const token = localStorage.getItem('jwt_token');

        const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/check`,
            {
                headers: {
                    Authorization: `Bearer ${token}` 
                },
                params: {
                    code: code
                }
            }
            
        );
        
        const responseData = response.data;

        return responseData;
    } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
        throw new Error('Erro ao buscar dados da API');
    }
}