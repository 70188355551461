// Description: This component render the card info
// Update: 12/08/2024

// React Components and DOM Elements
import React from 'react';
import { useNavigate } from 'react-router-dom';

// Material UI Components
import { Card, CardContent, CardMedia, Grid, CardActionArea } from '@mui/material';

// Components and functions from the project
import ThemeColors from '../ThemeColors/ThemeColors';
import Titles from '../Titles/Titles';

// Import Styles
import './cardInfo.css'

// Render Your Component
const CardInfo = (props) => {
    const Navigate = useNavigate();
    
    return (
        <Grid container spacing={4}>
            {props['cards'].map((item, index) => (
                <Grid className='cardInfo_card' item sm={6} lg={12 / props['cards'].length} key={index}>  {/* Ajusta dinamicamente o tamanho do Grid item */}
                    <Card sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',  // Ajusta todos os cards para terem a mesma altura
                    }}>
                        <CardActionArea onClick={() => Navigate(item.href)} sx={{ minHeight: '100%' }}>
                            <CardMedia
                                component="img"
                                sx={{
                                    objectFit: 'contain',  // Garante que toda a imagem seja visível
                                    alignSelf: 'center',  // Centraliza a imagem no card
                                    maxHeight: (props['cards'].length -1) < 3 ? '277px' : '277px',  // Ajusta a altura da imagem
                                    marginTop: '20px',  // Ajusta o espaçamento entre a imagem e o texto
                                }}
                                image={item.image}
                                alt={item.title}
                            />
                            <CardContent sx={{ flexGrow: 1 }} style={{ padding: '40px', height: '203px' }}>
                                <div style={{marginBottom: '15px'}}>
                                <Titles styles={{ fontSize: { xl: "24px", lg: "20px", sm: "16px" }}} color={ThemeColors.purple600} font="medium">{item.title}</Titles>
                                </div>
                                <Titles styles={{ fontSize: { xl: "16px", lg: "13px", sm: "13px" }}} color={ThemeColors.black} >{item.desc}</Titles>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default CardInfo;
