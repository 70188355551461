// This component is responsible for rendering the WhatsApp Normal channel configuration page.
// The client can configure the channel settings, such as the type of message, the time to start and end sending messages, and the automatic response.
// The component also displays the WhatsApp screen, which shows the messages that will be sent to the client.

// Author: Vitor de Jeus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/137396228/Como+configurar+o+WhatsApp
// Prototype: https://www.figma.com/design/NqSBP9Hjy6KFGUik7FKy4F/UIUX-design?node-id=794-67&t=EGN3IbFNlKWQyQKU-4

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';

import { ChannelConfig } from '../../api/backend/Channels/ChannelConfig';
import { GetInfoChannel } from '../../api/backend/Channels/GetInfoChannel';
import { isAuthenticated } from '../../authMiddleware'; 

import RenderWhatsAppBusiness from './ChannelRenderWhatsAppBusiness';
import RenderWhatsAppNormal from './ChannelRenderWhatsAppNormal';

import Footer from '../../components/Footer/Footer';

function ChannelPageConfig() {

    const { authenticated } = isAuthenticated();
    const [userInfo, setUserInfo] = useState({});

    const [autoReply, setAutoReply] = useState('');
    const [defaultMaxSendTime, setDefaultMaxSendTime] = useState(new Date()); 
    const [defaultMinSendTime, setDefaultMinSendTime] = useState(new Date()); 
    const location = useLocation();
    const [openModalRequest, setOpenModalRequest] = useState(false);
    const [autoRemoveOption, setAutoRemoveOption] = useState(false); 
    const [autoReplyOption, setAutoReplyOption] = useState(false);  
    const [saveLoading, setSaveLoading] = useState(false); 
    const selectedItem = location.state ? location.state.selectedItem : null;
    const [successSave, setSuccessSave] = useState(null);  
    const [type, setType] = useState(''); 
    const [minSendTime, setMinSendTime] = useState(new Date());
    const [maxSendTime, setMaxSendTime] = useState(new Date());

    const [viewWhatsAppScreenProps, setViewWhatsAppScreenProps] = useState({
        type: 'whatsapp',
        messages: {
            messages: []
        }
    });

    useEffect(() => {
        if (authenticated) {
            const { decryptedData } = isAuthenticated();
            setUserInfo(decryptedData);
            console.log('selectedItem', selectedItem);
        }
    }, []);

    useEffect(() => {
        if (userInfo['UUID']) {
            fetchData();
        }
    }, [userInfo])

    useEffect(() => {
        setSuccessSave(null);
        setSaveLoading(false);
    }, [autoReply, type, defaultMaxSendTime, defaultMinSendTime, autoReplyOption, autoRemoveOption])

    useEffect(() => {

        const commonMessages = [
            { id: 1, type: 'whatsapp', message: 'Ola, Preciso de ajuda!', fromMe: false, disabledRemove: true },
        ];

        const additionalMessages = [];

        if (autoReplyOption) {
            additionalMessages.push({ id: 2, type: 'whatsapp', message: `${autoReply}`, fromMe: true, disabledRemove: false, options: () => setAutoReplyOption(!autoReplyOption), config: true });
        }
        if (autoRemoveOption) {
            additionalMessages.push({ id: 3, type: 'whatsapp', message: 'Para parar de receber alertas, envie "PARAR".', fromMe: true, disabledRemove: false, options: () => setAutoRemoveOption(!autoRemoveOption), config: true });
            additionalMessages.push({ id: 4, type: 'whatsapp', message: 'PARAR', fromMe: false, disabledRemove: true });
            additionalMessages.push({ id: 5, type: 'whatsapp', message: 'Você não receberá mais alertas.', fromMe: true, disabledRemove: true });
        }

        setViewWhatsAppScreenProps((prevProps) => ({
            ...prevProps,
            messages: {
                messages: [...commonMessages, ...additionalMessages],
            },
        }));

    }, [autoReply, autoRemoveOption, autoReplyOption]);

    const fetchData = async () => {
 
        if (!selectedItem.instance_name) {
            alert('Erro ao buscar dados da API');
            return;
        }

        try {
            
            const   code = selectedItem.instance_name;
            const resultJson = await GetInfoChannel(code);

            console.log('entrou aqui', resultJson);
            setAutoReply(resultJson.autoReplyText);   
            setType(resultJson.type);
            const maxHour = resultJson.maxHour.split(':');
            const minHour = resultJson.minHour.split(':');
            const maxHourDate = new Date();
            maxHourDate.setHours(maxHour[0]);
            maxHourDate.setMinutes(maxHour[1]);
            const minHourDate = new Date();
            minHourDate.setHours(minHour[0]);
            minHourDate.setMinutes(minHour[1]);
            setDefaultMaxSendTime(maxHourDate);
            setDefaultMinSendTime(minHourDate);
            setAutoReplyOption(resultJson.autoReplyOption);   
            setAutoRemoveOption(resultJson.autoRemoveOption);   
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

    
const saveConfig = async () => {

    setSaveLoading(true);

    try {

        let autoReplyTemp = autoReply;
        const finalAutoReplyOption = autoReplyTemp.replace(/\n/g, '\\n');

        let configData = {
            code: selectedItem.instance_name,
            autoReplyText: finalAutoReplyOption, 
            type: type,                           
            maxHour: maxSendTime.getHours() + ':' + maxSendTime.getMinutes(), 
            minHour: minSendTime.getHours() + ':' + minSendTime.getMinutes(),
            autoReplyOption: autoReplyOption,    
            autoRemoveOption: autoRemoveOption 
        };

        const setConfig = await ChannelConfig(configData);

        if (setConfig.message==='Configuração do canal atualizada com sucesso') {
            setSuccessSave(true);
            setSaveLoading(false);
        } else {
            setSaveLoading(false);
            setSuccessSave(false);
        }
    } catch (error) {
        console.error('Erro ao salvar configuração:', error);
        setSaveLoading(false);
    }
};

    const handleCloseModalRequest = () => {
        setOpenModalRequest(false);
    }

    const handleOpenModalRequest = () => {
        setOpenModalRequest(true);
    }

    if (!selectedItem) {
        return null;
    }

    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
                bgcolor: 'transparent',
                overflow: 'auto',
            }}
        >
            {selectedItem.integration_icons === 'whatsAppOfficial' ? (
                <RenderWhatsAppBusiness
                    selectedItem={selectedItem}
                    type={type}
                    setType={setType}
                    defaultMinSendTime={defaultMinSendTime}
                    setMinSendTime={setMinSendTime}
                    defaultMaxSendTime={defaultMaxSendTime}
                    setMaxSendTime={setMaxSendTime}
                    autoReplyOption={autoReplyOption}
                    setAutoReplyOption={setAutoReplyOption}
                    autoRemoveOption={autoRemoveOption}
                    setAutoRemoveOption={setAutoRemoveOption}
                    autoReply={autoReply}
                    setAutoReply={setAutoReply}
                    handleOpenModalRequest={handleOpenModalRequest}
                    saveConfig={saveConfig}
                    saveLoading={saveLoading}
                    successSave={successSave}
                    handleCloseModalRequest={handleCloseModalRequest}
                    openModalRequest={openModalRequest}
                    viewWhatsAppScreenProps={viewWhatsAppScreenProps}
                />
            ) : (
                <RenderWhatsAppNormal
                    selectedItem={selectedItem}
                    type={type}
                    setType={setType}
                    defaultMinSendTime={defaultMinSendTime}
                    setMinSendTime={setMinSendTime}
                    defaultMaxSendTime={defaultMaxSendTime}
                    setMaxSendTime={setMaxSendTime}
                    autoReplyOption={autoReplyOption}
                    setAutoReplyOption={setAutoReplyOption}
                    autoRemoveOption={autoRemoveOption}
                    setAutoRemoveOption={setAutoRemoveOption}
                    autoReply={autoReply}
                    setAutoReply={setAutoReply}
                    handleOpenModalRequest={handleOpenModalRequest}
                    saveConfig={saveConfig}
                    saveLoading={saveLoading}
                    successSave={successSave}
                    handleCloseModalRequest={handleCloseModalRequest}
                    openModalRequest={openModalRequest}
                    viewWhatsAppScreenProps={viewWhatsAppScreenProps}
                />
            )}
            <Box sx={{ display: { md: 'flex', lg: 'inherit' }, alignItems: 'start', position: { lg: 'absolute' }, bottom: { lg: 0 }, padding: { xs: '20px 0 0 64px', lg: '0 0 0 64px' } }}>
                <Footer />
            </Box>
        </Box>
    );
};

export default ChannelPageConfig;
