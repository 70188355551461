// Desc: This component is responsible for rendering the sidebar of the application.
// Update: 12/08/2024

// React Components and DOM Elements
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// Material UI Components
import { Box, Grid, useMediaQuery } from '@mui/material';
import { ListItem, ListItemIcon, ListItemText, Typography, IconButton, Menu, MenuItem, CircularProgress } from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

// Components and functions from the project
import { isAuthenticated } from '../../authMiddleware';
import Logo from '../../assets/Icons/components/Logo';
import LogoSimple from '../../assets/Icons/components/LogoSimple';
import ThemeColors from '../ThemeColors/ThemeColors';
import ConfigIcon from '../../assets/images/config.png';
import AlternativeAvatar from '../../assets/images/avatar-padrao.png';
import DoubleArrowOutlinedIcon from '@mui/icons-material/DoubleArrowOutlined';
import { ReactComponent as HomeIcon } from '../../assets/Icons/svg/HomeIcon.svg';
import { ReactComponent as TagIcon } from '../../assets/Icons/svg/TagIcon.svg';
import { ReactComponent as TemplateIcon } from '../../assets/Icons/svg/AlternateEmailIcon.svg';
import { ReactComponent as SheetsIcon } from '../../assets/Icons/svg/SheetsIcon.svg';
import { ReactComponent as CampaignIcon } from '../../assets/Icons/svg/CampaignIcon.svg';
import { ReactComponent as SupportIcon } from '../../assets/Icons/svg/SupportIcon.svg';
import { useAuth } from '../../context/AuthContext';
import { checkAuth } from '../../utils/utils';
import WebhookIcon from '@mui/icons-material/Webhook';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';

// Styles
import './Sidebar.css';
import axios from 'axios';


function ExpansiveButton({ isCollapsed, handleCollapse, itemsSecondary }) {

    function hexToRGBA(hex, alpha) {
        const r = parseInt(hex.substring(1, 3), 16);
        const g = parseInt(hex.substring(3, 5), 16);
        const b = parseInt(hex.substring(5, 7), 16);
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }


    return (
        <Box
            sx={{
                display: { xl: 'flex', lg: 'flex', sm: 'none' },
                alignItems: 'center',
                justifyContent: 'center',
                position: 'fixed',
                marginLeft: isCollapsed ? '85px' : '280px',
                backgroundColor: hexToRGBA(ThemeColors.gray40, 0),
                marginTop: '17px',
                borderRadius: '12px',
                width: '35px',
                height: '35px',
                cursor: 'pointer',
                transition: 'margin-left 0.3s ease'
            }}

            onClick={handleCollapse}
        >
            {isCollapsed ? <DoubleArrowOutlinedIcon style={{ color: ThemeColors.purple500 }}

            /> : <DoubleArrowOutlinedIcon style={{ color: ThemeColors.purple400, transform: 'rotate(180deg)' }}


            />}
        </Box>
    );
}

function getLocation(fullLocation) {
    // Verify if fullLocation is defined
    if (fullLocation) {

        const locationName = fullLocation.split('/')[1];
        
        // Corrigindo a comparação sem a barra inicial
        if (locationName === 'cobran%C3%A7as') {
            return '/cobranças';
        }
        
        return '/' + locationName;
    } else {
        return '';
    }
}


// Render the navigation menu
function NavigationMenu({ isCollapsed, location, handleClick, handleLogout, anchorEl, handleClose, isSmallScreen }) {
    const navigate = useNavigate();
    const { setUserAuthenticated } = useAuth();

    // This function check if the user is authenticated them return the path the link will have
    const handleNavigate = (path) => {
        const authStatus = checkAuth()

        if (authStatus === true) {
            navigate(path)
        } else {
            setUserAuthenticated(false);
            navigate("/");
        }
    };


    const linksInitial = [
        { path: "/painel", icon: <HomeIcon />, text: "Painel" },
        { path: "/relatorios", icon: <InsightsRoundedIcon />, text: "Relatórios" },
        { path: "/canais", icon: <TagIcon />, text: "Canais" },
        { path: "/modelos", icon: <TemplateIcon />, text: "Modelos" },
        { path: "/planilhas", icon: <SheetsIcon />, text: "Planilhas" },
        { path: "/Financeiro", icon: <PaidOutlinedIcon/>, text: "Financeiro" },
        { path: "/campanhas", icon: <CampaignIcon />, text: "Campanhas" },
        
    ];
    
    const linksFinal = [
        { path: "/apis", icon: <WebhookIcon />, text: "APIs" },
        { path: "/suporte", icon: <SupportIcon />, text: "Suporte" },
        //{ path: "/Notifications", icon: <NotificationsOutlinedIcon />, text: "Notificações" },
        //{ path: "/Settings", icon: <SettingsOutlinedIcon />, text: "Configurações" },
    ];

    return (
        <ul style={{ marginBottom: isCollapsed ? '40%' : '10%', margin: '20px', alignContent: 'center', display: 'flex', flexDirection: 'column', minHeight: 'calc(100% - 132px)' }}>

            <ListItemIcon style={{ minWidth: 'auto', marginRight: '10px', marginBottom: isCollapsed ? '20px' : '48px' }}>
                {(isCollapsed || isSmallScreen) ? <LogoSimple /> : <Logo />}
            </ListItemIcon>
            {linksInitial.map(link => (
                <ListItem
                    key={link.path}
                    style={{
                        padding: '10px',
                        background: getLocation(location.pathname) === link.path ? '#2A2A2F' : '#151517',
                        marginBottom: link.path === '/campanhas' ? (isCollapsed ? '15px' : '57px') : '5px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        justifyContent: (isCollapsed || isSmallScreen) ? 'center' : null,
                        alignItems: (isCollapsed || isSmallScreen) ? 'center' : null,
                        height: (isCollapsed || isSmallScreen) ? '55px' : '',
                        width: (isCollapsed || isSmallScreen) ? '56px' : '',
                    }}
                    onClick={() => handleNavigate(link.path)}
                >
                    <ListItemIcon sx={{ minWidth: 'auto', marginRight: (isCollapsed || isSmallScreen) ? '' : '10px' }}>
                        {React.cloneElement(link.icon, {
                            style: {
                                fill: getLocation(location.pathname) === link.path ? '#A986FF' : '#666673',
                            },
                        })}
                    </ListItemIcon>
                    {(isCollapsed || isSmallScreen) ? null : (
                        <ListItemText disableTypography>
                            <Typography sx={{
                                fontWeight: 500,
                                color: getLocation(location.pathname) === link.path ? '#A986FF' : '#666673',
                                fontFamily: 'Satoshi',
                                fontSize: '16px',
                            }}>{link.text}</Typography>
                        </ListItemText>
                    )}
                </ListItem>
            ))}

            {linksFinal.map(link => (
                <ListItem
                    key={link.path}
                    style={{
                        padding: '10px', background: getLocation(location.pathname) === link.path ? '#2A2A2F' : '#151517',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        justifyContent: (isCollapsed || isSmallScreen) ? 'center' : null,
                        alignItems: (isCollapsed || isSmallScreen) ? 'center' : null,
                        height: (isCollapsed || isSmallScreen) ? '55px' : '',
                        width: (isCollapsed || isSmallScreen) ? '56px' : '',
                        marginBottom: '5px'
                    }}
                    onClick={() => handleNavigate(link.path)}
                >
                    <ListItemIcon sx={{ minWidth: 'auto', marginRight: (isCollapsed || isSmallScreen) ? '' : '10px' }}>
                        {React.cloneElement(link.icon,
                            {
                                style: {
                                    fill: getLocation(location.pathname) === link.path ? '#A986FF' : '#666673',
                                },
                            }
                        )}
                    </ListItemIcon>
                    {(isCollapsed || isSmallScreen) ? null : (
                        <ListItemText disableTypography>
                            <Typography variant="caption" sx={{
                                fontWeight: 500,
                                color: getLocation(location.pathname) === link.path ? '#A986FF' : '#666673',
                                fontSize: '16px',
                            }}>{link.text}</Typography>
                        </ListItemText>
                    )}
                </ListItem>
            ))}
            {
                (isCollapsed || isSmallScreen ) && (
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '10px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '55px',
                            width: '56px',
                            marginTop: 'auto'  
                        }}
                    >
                        <IconButton
                            style={{ padding: '0', cursor: 'pointer' }}
                            onClick={handleClick}
                        >
                            <Box
                                component={'img'}
                                src={ConfigIcon}
                                width={'17.94px'}
                                height={'17.12px'}
                            />
                        </IconButton>
                        <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            sx={
                                // remove the menu's box-shadow
                                {
                                    '& .MuiPaper-root': {
                                        boxShadow: 'none',
                                        borderRadius: '8px',
                                        border: '1px solid #DBDBDB',
                                        // align menu to the left of the icon
                                        marginLeft: '50px',
                                    }

                                }
                            }
                        >
                            <MenuItem onClick={handleClose}>
                                <Grid container justifyContent="left" alignItems="center" spacing={1}>
                                    <Grid item>
                                        <SettingsOutlinedIcon style={{ color: 'gray', fontSize: '15px', marginTop: '8px' }} />
                                    </Grid>
                                    <Grid item style={{ paddingLeft: '20px' }}>
                                        Configurações
                                    </Grid>
                                </Grid>
                            </MenuItem>
                            <MenuItem onClick={handleLogout}>
                                <Grid container justifyContent="left" alignItems="center" spacing={1}>
                                    <Grid item>
                                        <LogoutOutlinedIcon style={{ color: 'gray', fontSize: '15px', marginTop: '8px' }} />
                                    </Grid>
                                    <Grid item style={{ paddingLeft: '20px' }}>
                                        Sair
                                    </Grid>
                                </Grid>
                            </MenuItem>

                        </Menu>
                    </Box>
                )}
        </ul>
    );

}

// Render the user avatar and the logout button
function UserEspace({ loading, userAvatar, userInfo, handleClick, isCollapsed, handleLogout, anchorEl, handleClose }) {
    return (
        <ListItem className="avatar-section" style={{ position: 'absolute', bottom: 0, padding: '10px', width: '100%', background: '#2A2A2F', height: '92px' }}>
            {loading ? (
                <CircularProgress style={{
                    width: '40px',
                    height: '40px'
                }} thickness={1} />
            ) : (
                <div >
                    <div style={{
                        width: '50px',
                        height: '50px',
                        transform: isCollapsed ? 'scale(1)' : 'scale(1)',
                        marginLeft: isCollapsed ? '10px' : '0px',
                        transition: 'transform 0.3s ease',
                        // center the avatar
                        marginLeft: '18px',

                    }}>
                        <div style={{
                            borderRadius: '12px',
                            overflow: 'hidden',
                            width: '100%',
                            height: '100%',

                        }}>
                            <img
                                key={userAvatar}
                                src={`${userAvatar}`}
                                style={{ width: '100%', height: '100%', borderRadius: '12px' }}
                                alt="Avatar"
                                aria-haspopup="true"

                            />
                        </div>
                    </div>

                </div>
            )}

            {!isCollapsed && (
                <Box sx={{ display: { xl: 'flex', md: 'none' }, justifyContent: 'space-between', alignItems: 'center', marginLeft: '15px', flex: 1 }}>

                    <div>
                        <p style={{
                            fontWeight: 500, color: '#f9f9f9', fontSize: '16px', marginBottom: '5px',
                            whiteSpace: 'nowrap', // Adicionado para evitar quebras de linha
                            overflow: 'hidden', // Esconde o texto que excede o container
                            textOverflow: 'ellipsis' // Adiciona "..." se o texto for cortado
                        }}>
                            {userInfo['NAME']}
                        </p>
                        <p style={{ fontWeight: 500, color: '#B7B7B7', fontSize: '14px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                            {userInfo['EMAIL']}
                        </p>
                    </div>
                    <IconButton
                        style={{ padding: '0', marginRight: '18px', cursor: 'pointer' }}
                        onClick={handleClick}
                    >
                        <Box
                            component={'img'}
                            src={ConfigIcon}
                            width={'17.94px'}
                            height={'17.12px'}
                        />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        sx={
                            // remove the menu's box-shadow
                            {
                                '& .MuiPaper-root': {
                                    boxShadow: 'none',
                                    borderRadius: '8px',
                                    border: '1px solid #DBDBDB',
                                    // align menu to the left of the icon
                                    marginLeft: '50px',
                                }

                            }
                        }
                    >
                        <MenuItem onClick={handleClose}>
                            <Grid container justifyContent="left" alignItems="center" spacing={1}>
                                <Grid item>
                                    <SettingsOutlinedIcon style={{ color: 'gray', fontSize: '15px', marginTop: '8px' }} />
                                </Grid>
                                <Grid item style={{ paddingLeft: '20px' }}>
                                    Configurações
                                </Grid>
                            </Grid>
                        </MenuItem>
                        <MenuItem onClick={handleLogout}>
                            <Grid container justifyContent="left" alignItems="center" spacing={1}>
                                <Grid item>
                                    <LogoutOutlinedIcon style={{ color: 'gray', fontSize: '15px', marginTop: '8px' }} />
                                </Grid>
                                <Grid item style={{ paddingLeft: '20px' }}>
                                    Sair
                                </Grid>
                            </Grid>
                        </MenuItem>

                    </Menu>

                </Box>
            )}

        </ListItem>
    );
}


export default function Sidebar() {

    const location = useLocation();
    const { userAuthenticated, userInfo, setUserInfo } = useAuth();

    const [userAvatar, setUserAvatar] = useState(null);
    const [isCollapsed, setIsCollapsed] = React.useState(true);
    const isSmallScreen = useMediaQuery('(max-width:960px)');

    const [loading, setLoading] = useState(true);
    const [isAvatarLoaded, setIsAvatarLoaded] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const path = getLocation();

    // Effect responsible for verifying if the user is authenticated
    useEffect(() => {
        if (userAuthenticated) {
            const { decryptedData } = isAuthenticated();
            setUserInfo(decryptedData);
            console.log('decryptedData:', decryptedData);

        }
    }, [userAuthenticated]);

    // Effect responsible for fetching the user's avatar
    useEffect(() => {
        if (userInfo['UUID']) {
            const userHash = userInfo['UUID'] + '.png';
            console.log('userHash:', userHash); 

            fetchUserAvatar(userHash);
        }
    }, [userInfo]);

    // Effect responsible for collapsing the sidebar
    useEffect(() => {
        const mainContent = document.querySelector('.MainContent');
        if (mainContent) {
            if (isCollapsed) {
                mainContent.classList.add('collapsed');
            } else {
                mainContent.classList.remove('collapsed');
            }
        }
    }, [isCollapsed]);


    // Function to logout the user
const handleLogout = () => {
    const FRONTURL_BASED = process.env.REACT_APP_FRONTEND_URL;

    if (userInfo['UUID']) {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/logout`, {
            id_client: userInfo['UUID']
        }).then((response) => {
            console.log('response:', response);
            
            // Remove o token do localStorage
            localStorage.removeItem('jwt_token');
            
            // Redireciona para a URL base
            window.location.href = `${FRONTURL_BASED}`;
        }).catch((error) => {
            console.error('error:', error);
        });
    }
};

    const handleClose = () => {
        setAnchorEl(null);
    };

    const fetchUserAvatar = async (userHash) => {
        try {
            const imageUrl = 'https://files123zap.s3.amazonaws.com/avatar_users/' + userHash;
            const img = new Image();
            img.onload = () => {
                setUserAvatar(imageUrl);
                setIsAvatarLoaded(true);
                setLoading(false);
            };
            img.onerror = () => {
                console.error('Erro ao carregar a imagem:', imageUrl);
                setUserAvatar(AlternativeAvatar);
                setIsAvatarLoaded(true);
                setLoading(false);
            };
            img.src = imageUrl;
        } catch (error) {
            console.error('Erro ao buscar o avatar do usuário:', error);
            setUserAvatar(AlternativeAvatar);
            setIsAvatarLoaded(true);
            setLoading(false);
        }
    };

    // Render the sidebar
    return (
        <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
            <ExpansiveButton isCollapsed={isCollapsed} handleCollapse={() => setIsCollapsed(!isCollapsed)} itemsSecondary={ThemeColors.gray10} />
            <NavigationMenu isCollapsed={isCollapsed} location={location} handleClick={(event) => setAnchorEl(event.currentTarget)} handleLogout={handleLogout} anchorEl={anchorEl} handleClose={handleClose} isSmallScreen={isSmallScreen} />
            <UserEspace loading={loading} userAvatar={userAvatar} userInfo={userInfo} handleClick={(event) => setAnchorEl(event.currentTarget)} isCollapsed={isCollapsed} handleLogout={handleLogout} anchorEl={anchorEl} handleClose={handleClose} />
        </div>
    );
}