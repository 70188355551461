// Desc: Page to add new APIs to the system

// React Components
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

// Material UI Components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { FormControl, InputLabel, Typography, TextField, Button, FormHelperText, Select, MenuItem } from "@mui/material";
import ReactJson from 'react-json-view';

import { Tab, Tabs } from "@mui/material";

// Components and Functions from the project
import AlertSnackbar from "../../components/Alerts/AlertSnackbar";
import HelpAdd from "../../components/Cards/HelpAdd";
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import ModalR from "../../components/Modals/ModalR";
import ThemeColors from "../../components/ThemeColors/ThemeColors";
import Loading from "../../components/Loading/Loading";

import { ApiInsert } from "../../api/n8n/Listagens/ApiInsert";
import { VerifyAPI } from "../../api/n8n/Listagens/VerifyAPI";

import { isAuthenticated } from "../../authMiddleware";
import { fi } from "date-fns/locale";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// Render Form to add new APIs
function ApiForm({ copyRespostas, setCopyRespostas, setErrors, errors, uuid_client, handleVerifyAPI, loadingVerify, setReturnApi, setTabIndex }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (name, value) => {
    setCopyRespostas((prev) => ({ ...prev, [name]: value }));

    if (name === 'apiBody') {
      validateJson(value);
    }

  };

  const validateJson = (value) => {
    try {
      JSON.parse(value);
      setErrors((prev) => ({ ...prev, apiBody: "" }));
    } catch (error) {
      setErrors((prev) => ({ ...prev, apiBody: "JSON inválido" }));
    }
  };

  const CheckApiPermission = async () => {
    const { apiName, apiDescription, apiUrl, apiToken, apiBody, apiMethod } = copyRespostas;
  
    if (!apiName || !apiDescription || !apiUrl || !apiToken || !apiBody || !apiMethod) {
      const newErrors = {
        apiName: !apiName ? "Campo obrigatório" : "",
        apiDescription: !apiDescription ? "Campo obrigatório" : "",
        apiUrl: !apiUrl ? "Campo obrigatório" : "",
        apiToken: !apiToken ? "Campo obrigatório" : "",
        apiBody: apiMethod === "POST" && !apiBody ? "Campo obrigatório" : apiMethod === "POST" && errors.apiBody ? "JSON inválido" : "",
        apiMethod: !apiMethod ? "Campo obrigatório" : "",
        uuid_client: uuid_client
      };
  
      setErrors(newErrors);
  
      if (newErrors.apiName || newErrors.apiDescription || newErrors.apiUrl || newErrors.apiToken || newErrors.apiBody || newErrors.apiMethod) {
        return;
      }
    }
  
    try {
      copyRespostas.uuid_client = uuid_client;
      setLoading(true);
  
      const verify = await VerifyAPI(copyRespostas);
  
      if (verify && verify.data && verify.data.status && verify.data.status !== 500) {
        try {
          const response = await ApiInsert(copyRespostas);
          console.log('response', response);
  
          if (response && response.status === 200) {
            navigate('/apis');
          } else {
            console.warn("Erro ao inserir API:", response);
          }
  
        } catch (error) {
          console.error("Erro ao inserir API:", error);
        }
      } else {
        setReturnApi(verify);
        setTabIndex(1);
      }
  
    } catch (error) {
      console.error("Erro ao verificar API:", error);
    } finally {
      setLoading(false);
    }
  };

  
    return (
      <Grid item xs={12} md={12}
        style={{ background: '#FFF', borderRadius: '12px', padding: '20px' }}>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <TextField
            variant="outlined"
            name="apiName"
            label="Digite um nome para identificar a API"
            value={copyRespostas.apiName || ""}
            onChange={(e) => handleInputChange("apiName", e.target.value)}
            sx={{ background: errors.apiName ? '#F8D6D3' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
            error={!!errors.apiName}
            InputLabelProps={{ style: { color: ThemeColors.gray50 } }}
          />
          {errors.apiName && <FormHelperText style={{ color: "red" }}>{errors.apiName}</FormHelperText>}
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <TextField
            name="apiDescription"
            label="Digite uma descrição para a API"
            sx={{ background: errors.apiDescription ? '#F8D6D3' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
            value={copyRespostas.apiDescription || ""}
            onChange={(e) => handleInputChange("apiDescription", e.target.value)}
            error={!!errors.apiDescription}
            InputLabelProps={{ style: { color: ThemeColors.gray50 } }}
          />
          {errors.apiDescription && <FormHelperText style={{ color: "red" }}>{errors.apiDescription}</FormHelperText>}
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel style={{ color: ThemeColors.gray50 }}>Qual o método HTTP da API?</InputLabel>
          <Select
            name="apiMethod"
            sx={{ background: errors.apiMethod ? '#F8D6D3' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
            value={copyRespostas.apiMethod || ""}
            onChange={(e) => handleInputChange("apiMethod", e.target.value)}
            error={!!errors.apiMethod}
            fullWidth
            InputLabelProps={{ style: { color: ThemeColors.gray50 } }}
          >
            <MenuItem value="GET">GET</MenuItem>
            <MenuItem value="POST">POST</MenuItem>
          </Select>
          {errors.apiMethod && <FormHelperText style={{ color: "red" }}>{errors.apiMethod}</FormHelperText>}
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <TextField
            autoFocus
            name="apiUrl"
            label="Digite a URL da API:"
            sx={{ background: errors.apiUrl ? '#F8D6D3' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
            value={copyRespostas.apiUrl || ""}
            onChange={(e) => handleInputChange("apiUrl", e.target.value)}
            error={!!errors.apiUrl}
            fullWidth
            InputLabelProps={{ style: { color: ThemeColors.gray50 } }}
          />
          {errors.apiUrl && <FormHelperText style={{ color: "red" }}>{errors.apiUrl}</FormHelperText>}
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <TextField
            autoFocus
            name="apiToken"
            label="Authorization Token"
            sx={{ background: errors.apiToken ? '#F8D6D3' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
            value={copyRespostas.apiToken || ""}
            onChange={(e) => handleInputChange("apiToken", e.target.value)}
            error={!!errors.apiToken}
            fullWidth
            InputLabelProps={{ style: { color: ThemeColors.gray50 } }}
          />
          {errors.apiToken && <FormHelperText style={{ color: "red" }}>{errors.apiToken}</FormHelperText>}
        </FormControl>
        {copyRespostas.apiMethod === "POST" && (
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <TextField
              autoFocus
              name="apiBody"
              multiline
              rows={4}
              placeholder="Body da requisição"
              sx={{
                background: errors.apiBody ? '#F8D6D3' : '#F9F9F9',
                marginTop: '5px',
                borderRadius: '12px',
              }}
              value={copyRespostas.apiBody || ''}
              onChange={(e) => handleInputChange('apiBody', e.target.value)}
              error={!!errors.apiBody}
              fullWidth
              InputLabelProps={{ style: { color: '#7B7B7B' } }} // Cor do rótulo
            />
            {errors.apiBody && <FormHelperText style={{ color: 'red' }}>{errors.apiBody}</FormHelperText>}
          </FormControl>
        )}
        < Box display="flex" justifyContent="center" alignItems="center" >

          <Button
            variant="outlined"
            onClick={() => {
              handleVerifyAPI();
            }}
            sx={{ padding: '15px', marginRight: '10px' }}
            fullWidth
            disabled={loadingVerify}
          >
            {loadingVerify ? "Verificando..." : "Verificar API"}
          </Button>


          <Button
            variant="contained"
            onClick={() => {
              CheckApiPermission();
            }}
            sx={{ padding: '15px' }}
            fullWidth
            disabled={loading}
          >
            {loading ? "Carregando..." : "Adicionar API"}
          </Button>

        </Box>
      </Grid>
    );
  }

  function PageAddApis() {

    const { authenticated } = isAuthenticated();
    const [userInfo, setUserInfo] = useState({});
    const [copyRespostas, setCopyRespostas] = useState({});
    const [errors, setErrors] = useState({});
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [showLoading, setShowLoading] = useState(true);
    const [tabIndex, setTabIndex] = useState(0);
    const [loadingVerify, setLoadingVerify] = useState(false);
    const [returnApi, setReturnApi] = useState({});

    const handleChangeTab = (event, newValue) => {
      setTabIndex(newValue);
    };

    const steps = [
      { desc: "Escolha um nome para identificar a API" },
      { desc: "Crie uma descrição para a API" },
      { desc: "Indique a URL da API" },
      { desc: "Insira o token de autorização" },
      { desc: 'Clique em "Adicionar API" para finalizar' }
    ];

    useEffect(() => {
      if (authenticated) {
        const { decryptedData } = isAuthenticated();
        setUserInfo(decryptedData);
      }
    }, [authenticated]);

    const handleVerifyAPI = async () => {

      if (!copyRespostas.apiName || !copyRespostas.apiDescription || !copyRespostas.apiUrl || !copyRespostas.apiToken || !copyRespostas.apiBody || !copyRespostas.apiMethod) {
        const newErrors = {
          apiName: !copyRespostas.apiName ? "Campo obrigatório" : "",
          apiDescription: !copyRespostas.apiDescription ? "Campo obrigatório" : "",
          apiUrl: !copyRespostas.apiUrl ? "Campo obrigatório" : "",
          apiToken: !copyRespostas.apiToken ? "Campo obrigatório" : "",
          apiBody: copyRespostas.apiMethod === "POST" && !copyRespostas.apiBody ? "Campo obrigatório" : copyRespostas.apiMethod === "POST" && errors.apiBody ? "JSON inválido" : "",
          apiMethod: !copyRespostas.apiMethod ? "Campo obrigatório" : "",
          uuid_client: userInfo.UUID
        };

        setErrors(newErrors);

        if (newErrors.apiName || newErrors.apiDescription || newErrors.apiUrl || newErrors.apiToken || newErrors.apiBody || newErrors.apiMethod) {
          return;
        }
      }

      setLoadingVerify(true);
      setReturnApi({});

      const dados = { ...copyRespostas, uuid_client: userInfo.UUID, id: userInfo.EMAIL, token: userInfo.TOKEN };
      try {
        const response = await VerifyAPI(dados);
        setReturnApi(response);
      } catch (error) {
        console.error("Erro ao verificar API:", error);
      } finally {
        setLoadingVerify(false);
        setTabIndex(1);
      }
    };

    useEffect(() => {
      setTimeout(() => setShowLoading(false), 1000);
    }, []);

    if (showLoading) {
      return <Loading open={true} />;
    }

    return (
      <>
        <HeaderPage title="Adicionar APIs" />


        <Grid container spacing={4} sx={{ padding: 5 }}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: "flex", flexDirection: "column" }}>
            <HelpAdd Steps={steps} Title="Adicione sua API" />
            {
              (copyRespostas.apiName === "" || copyRespostas.apiDescription === "" || copyRespostas.apiUrl === "" || copyRespostas.apiToken === "") && (
                <AlertSnackbar message="Preencha todos os campos obrigatórios" severity="error" open={true} />
              )
            }
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Tabs value={tabIndex} onChange={handleChangeTab} aria-label="form tabs" style={{ 'background': '#FFF', 'borderRadius': '12px', marginBottom: '10px' }}>
              <Tab label="Dados da API" />
              <Tab label="Respostas" />
            </Tabs>
            <TabPanel value={tabIndex} index={0} style={{ 'background': "#FFF", 'borderRadius': '12px', padding: '20px', minHeight: '60vh' }}>
              <ApiForm
                copyRespostas={copyRespostas}
                setCopyRespostas={setCopyRespostas}
                setErrors={setErrors}
                errors={errors}
                uuid_client={userInfo.UUID}
                handleVerifyAPI={handleVerifyAPI}
                loadingVerify={loadingVerify}
                setReturnApi={setReturnApi}
                setTabIndex={setTabIndex}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={1} style={{ background: "#FFF", borderRadius: '12px', padding: '20px', minHeight: '60vh' }}>
              {returnApi && returnApi.data && (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <ReactJson
                      src={returnApi.data}
                      style={{ color: ThemeColors.gray50 }}
                      displayDataTypes={false}
                      collapsed={false}  
                      enableClipboard={false}  
                      name={false} 
                      displayObjectSize={false}  
                      displayArrayKey={false} 
                      indentWidth={8}
                      theme="light"
                    />
                  </Grid>
                </Grid>
              )}
            </TabPanel>
          </Grid>
        </Grid>
      </>
    );
  }

  export default PageAddApis;
