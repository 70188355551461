// React
import React, { useState } from "react";

// Material UI Components
import { Box, TextField, Typography } from "@mui/material";

// Components and functions for the project
import { steps } from "../index.js";
import Titles from "../../../components/Titles/Titles.js";
import themeColors from "../../../components/ThemeColors/ThemeColors.js";
import checkIcon from "../check-icon.png";

const Step1 = ({ message, selected, setSelected }) => {

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Titles
                type={"H3"}
                children={steps[0].stepTitle}
                bold
                color={themeColors.purple500}
            />
            <Titles
                styles={{ marginBottom: "97px" }}
                type={"P2"}
                children={"Selecione o tipo de usuário que você é para prosseguirmos."}
                color={themeColors.gray30}
            />

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                        cursor: "pointer",
                    }}
                    onClick={() => setSelected("Empresa(Pessoa Jurídica)")}
                >
                    <Box
                        sx={{
                            width: "20px",
                            height: "21px",
                            border:
                                selected === "Empresa(Pessoa Jurídica)"
                                    ? "none"
                                    : `1px solid ${themeColors.gray30}`,
                            borderRadius: "2px",
                            background:
                                selected === "Empresa(Pessoa Jurídica)"
                                    ? `${themeColors.purple500}`
                                    : "none",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <img
                            src={checkIcon}
                            alt="check"
                            style={{
                                display:
                                    selected === "Empresa(Pessoa Jurídica)" ? "block" : "none",
                                width: "11px",
                                height: "8.9px",
                            }}
                        />
                    </Box>
                    <Titles
                        color={
                            selected === "Empresa(Pessoa Jurídica)"
                                ? "#000"
                                : themeColors.gray30
                        }
                        children={"Empresa(Pessoa Jurídica)"}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                        cursor: "pointer",
                    }}
                    onClick={() => setSelected("Pessoa Física")}
                >
                    <Box
                        sx={{
                            width: "20px",
                            height: "21px",
                            border:
                                selected === "Pessoa Física"
                                    ? "none"
                                    : `1px solid ${themeColors.gray30}`,
                            borderRadius: "2px",
                            background:
                                selected === "Pessoa Física"
                                    ? `${themeColors.purple500}`
                                    : "none",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <img
                            src={checkIcon}
                            alt="check"
                            style={{
                                display: selected === "Pessoa Física" ? "block" : "none",
                                width: "11px",
                                height: "8.9px",
                            }}
                        />
                    </Box>
                    <Titles
                        color={selected === "Pessoa Física" ? "#000" : themeColors.gray30}
                        children={"Pessoa Física"}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default Step1