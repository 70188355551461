  
import axios from 'axios';

export async function TemplateUpdate(template_id) {
    try {

        const token = localStorage.getItem('jwt_token');

        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/update/template`,
                template_id,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            }
        );

        const responseData = response.data;

        return responseData;

    } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
    }
}