// Description: Page to create email templates that will be rendered with HTML strings
// Updated: 21/10/2024
// Author: Simon Franklin

// React
import React, { useState, useEffect } from "react";

// Material UI Components
import { Box } from "@mui/material";

// Components and functions for the project
import HeaderPage from "../../components/HeaderPage/HeaderPage";
import DynamicForm from "../../components/DynamicForm/DynamicForm";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../authMiddleware";
import { GetVariables } from "../../api/backend/Templates/GetVariables";
import { initialHtml, personalizedBackgrounds } from "../../utils/templates";
import { CreateTemplate } from "../../api/backend/Templates/CreateTemplate";
import { createObjectFromArray } from "../../utils/utils";

// Render your component
const TemplateEmailHTML = () => {
  // Authentication and state variables
  const { userAuthenticated } = useAuth();

  // Function to extract variables
  const extractVariables = (html) => {
    const pattern = /{{\s*(.*?)\s*}}/g;
    let variables = [];
    let match;
    while ((match = pattern.exec(html)) !== null) {
      variables.push(match[1]);
    }
    return variables;
  };

  const htmlToBeRendered = initialHtml
    .replace("$json.Title01", "Title01")
    .replace("$json.Title02", "URL01");

  const variables = extractVariables(htmlToBeRendered);

  // Email Template States
  const [title, setTitle] = useState("");
  const [emailTitle, setEmailTitle] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [variablesOfTemplate, setVariablesOfTemplate] = useState({});
  const [isEmailTemplatePersonalized, setIsEmailTemplatePersonalized] =
    useState(
      JSON.parse(localStorage.getItem("isEmailTemplatePersonalized")) || false
    );
  const [background, setBackground] = useState(
    JSON.parse(localStorage.getItem("isEmailTemplatePersonalized"))
      ? ""
      : personalizedBackgrounds[5].base64
  );
  const typeChannel = "E-mail"
  const [typeMessage, setTypeMessage] = useState("Atrasos");
  const palletOfColors = "N/A";
  const [formState, setFormState] = useState(createObjectFromArray(variables, ""));
  const [selected, setSelected] = useState("Atrasos");


  // Function to replace the dynamic variables in the HTML
  const replaceVariables = (html, formState) => {
    return html.replace(/{{\s*(.*?)\s*}}/g, (match, p1) => {
      const value = formState[p1] || "";
  
      return value.replace(/\n/g, '<br />');
    });
  };

  // updated HTML with the value of the inputs
  const updatedHtml = replaceVariables(htmlToBeRendered, formState);

  // Component Props State
  const [viewWhatsAppScreenProps, setViewWhatsAppScreenProps] = useState({
    messages: {
      messages: [],
    },
  });

  useEffect(() => {
    if (typeChannel === "WhatsApp") {
      setViewWhatsAppScreenProps((prevProps) => ({
        ...prevProps,
        type: "whatsapp",
        messages: {
          messages: [],
        },
      }));
    } else if (typeChannel === "SMS") {
      setViewWhatsAppScreenProps((prevProps) => ({
        ...prevProps,
        type: "sms",
        messages: {
          messages: [],
        },
      }));
    } else if (typeChannel === "E-mail") {
      setViewWhatsAppScreenProps((prevProps) => ({
        ...prevProps,
        type: "email",
        messages: {
          messages: [],
        },
      }));
    }
  }, [typeChannel]);

  const Navigate = useNavigate();

  // Effect to Check Authentication and Fetch User Info
  useEffect(() => {
    if (userAuthenticated) {
      const { decryptedData } = isAuthenticated();
      setUserInfo(decryptedData);
    } else {
      Navigate("/");
    }
  }, []);

  // Effect to Fetch Data from API
  useEffect(() => {
    if (userInfo["UUID"]) {
      fetchData();
    }
  }, [userInfo]);

  // Catch template variables
  const fetchData = async () => {
    try {
      
      const translations = {
        Personal_information: "Informações Pessoais",
        Contact: "Contato",
        Address: "Endereço",
        Financial_References: "Referências Financeiras",
      };

      const resultJson = await GetVariables();

      console.log(resultJson, "Result JSON");
      const result = resultJson.reduce((acc, item) => {
        const category = translations[item[1]] || item[1]; // Traduz a categoria, ou usa o nome original caso não haja tradução
        if (!acc[category]) {
          acc[category] = [];
        }

        // limita a 10 itens

       acc[category].push(item[0]);

        return acc;
      }, {});

      console.log(result, "Template Variables");

      setVariablesOfTemplate(result);
    } catch (error) {
      console.error("Error fetching data from API:", error);
    }
  };

  // Function to save the template
  const saveTemplate = async () => {
    const template = {
      template: {
        html: htmlToBeRendered,
        variables: [
          {
            emailTitle: emailTitle,
            title01: formState.Title01,
            url01: formState.URL01,
          },
        ],
      },
    };

    let info = {
      uuid_client: userInfo["UUID"],
      id: userInfo["EMAIL"],
      token: userInfo["TOKEN"],
      title: title,
      message_json: template,
      integration_system: "aws",
      status: "Pending",
      msg_type: typeMessage,
      channel_type: "E-mail",
      background: background,
      type: 'HTML'
    };

    info = JSON.stringify(info);

    const resultJson = await CreateTemplate(info);

    if (resultJson["status"] === "success") {
      Navigate("/modelos");
    }
  };

  return (
    <>
      <HeaderPage />
      <Box
        sx={{
          position: "relative",
          width: "100%",
          minHeight: "calc(100vh - 100px)",
          paddingBottom: 10,
        }}
      >
        <DynamicForm
          title={title}
          setTitle={setTitle}
          variablesOfTemplate={variablesOfTemplate}
          emailTitle={emailTitle}
          setEmailTitle={setEmailTitle}
          Navigate={Navigate}
          typeMessage={typeMessage}
          setTypeMessage={setTypeMessage}
          updatedHtml={updatedHtml}
          variables={variables}
          handleSubmit={saveTemplate}
          formState={formState}
          setFormState={setFormState}
          selected={selected}
          setSelected={setSelected}
        />
      </Box>
    </>
  );
};

export default TemplateEmailHTML;
