// This component is used to display the support requests.
// The component fetches the data from the backend API using the RequestByID function.
// The data is displayed in a table using the DynamicTable component.
// Author: Vitor de Jesus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/106463233/Suporte
// Prototype: https://www.figma.com/design/NqSBP9Hjy6KFGUik7FKy4F/UIUX-design?node-id=1854-254&t=RqPxWd9hgguzht2p-4

import React, { Fragment, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import Grid from '@mui/material/Grid';
import Fade from '@mui/material/Fade';
import AddCommentIcon from '@mui/icons-material/AddComment'; import CustomCard from '../../components/Cards/CustomCard';
import Typography from '@mui/material/Typography';

import DynamicTable from '../../components/Tables/DynamicTable';
import { isAuthenticated  } from '../../authMiddleware'; // Corrigido aqui
import StatusLabel from '../../components/StatusLabel/StatusLabel';
import StatusIcon from '../../components/StatusLabel/StatusIcon';
import EditIcon from '../../assets/Icons/components/Edit';
import PlusIcon from '../../assets/Icons/components/Plus';
import HeaderPage from '../../components/HeaderPage/HeaderPage';

import { RequestByID } from '../../api/backend/Request/MyRequests';
import Loading from '../../components/Loading/Loading';
import EmptyTemplate from "../../assets/ilustrations/EmptyTemplates.png"
import Titles from "../../components/Titles/Titles";
import Button from "@mui/material/Button";
 
function Support() {

  const [loading, setLoading] = useState(true);
  const [selectedMessages, setSelectedTemplate] = useState(null); 
  const [openModalView, setOpenModalView] = useState(false);

  const fontPrimary = getComputedStyle(document.documentElement).getPropertyValue('--fontPrimary').trim();
  const fontSecondary = getComputedStyle(document.documentElement).getPropertyValue('--fontSecondary').trim();

  let Navigate = useNavigate();

  const { authenticated } = isAuthenticated();

  const [userInfo, setUserInfo] = useState({});


  useEffect(() => {
    if (authenticated) {
      const { decryptedData } = isAuthenticated();
      setUserInfo(decryptedData);
    }
  }, []);

  useEffect(() => {
    if (userInfo['UUID']) {
      fetchData();
    }
  }, [userInfo])

  const [dataCards, setDataCards] = useState([
    { title: 'Minhas Solicitações', dataKey: 'my_request', icon: null, qtd: 0, titleColor: fontSecondary, valueColor: fontPrimary },
    { title: 'Solicitações em aberto', dataKey: 'open_request', icon: null, qtd: 0, titleColor: fontSecondary, valueColor: fontPrimary }
  ]);

  const [itemsToShow, setItemsToShow] = useState([]);

  const formataData = (data) => {
    const dataSplit = data.split(' ');
    const dataFormatada = dataSplit[0].split('-').reverse().join('/');
    return dataFormatada;

  }

  const fetchData = async () => {
    try {

      setLoading(true);

      const resultJson = await RequestByID();

      let itemsToshow = [];

      itemsToshow = resultJson.map(item => {
        return {

          id: item.id,
          title: item.title,
          description: item.description,
          status: item.status,
          icon: 'Suporte',
          requesterid: item.requesterid,
          body: item.body,
          priority: item.priority,
          sectorStatus: item.sector,
          creationdate: formataData(item.creationdate),
          updatedate: formataData(item.updatedate),
        };
      });

      setItemsToShow(itemsToshow);

      const dataCardsCopy = [...dataCards];
      dataCardsCopy[0].qtd = resultJson.length;
      dataCardsCopy[1].qtd = resultJson.filter(item => item.status === 'Pendente').length;

      setDataCards(dataCardsCopy);

    } catch (error) {
      console.error('Erro ao obter modelos:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenModalAdd = () => {
    Navigate('/suporte/adicionar');
  };
  
  const handleOpenModalView = (template) => {
    setSelectedTemplate(template); // Atualiza o estado com os dados do template selecionado
    setOpenModalView(true);
  };


  if (loading) {
    return < Loading open={true} />;
  }


  return (
    <Fragment>

      <Fade in={!loading} timeout={1000}>
        <Box width={"100%"} height={"100vh"}>

          < HeaderPage title="Solicitações" icon={<AddCommentIcon />} />

          {

            itemsToShow && itemsToShow.length > 0 && (
              <DynamicTable
                title="Campanhas"
                data={itemsToShow}
                columns={[
                  { header: '', field: 'icon', render: (icon) => <StatusIcon status={icon} /> },
                  { header: 'Titulo', field: 'title' },
                  { header: 'Abertura', field: 'creationdate' },
                  { header: 'Categoria', field: 'sectorStatus', render: (status) => <StatusLabel status={status} usingOnTable /> },
                  { header: 'Status', field: 'status', render: (status) => <StatusLabel status={status} usingOnTable />, filter: true },

                ]}
                actions={[

                  { icon: <EditIcon />, tooltip: 'Visualizar Solicitação', action: handleOpenModalView, isVisible: (item) => true },

                ]}

                button={{ icon: <PlusIcon />, tooltip: 'Adicionar Solicitação ', action: handleOpenModalAdd }
                }

                activeSearch={true}
                titleSearch={'Pesquisar solicitação'}
                titleLabel={'Solicitações por página'}
              />

            ) || (

              <Box display={"flex"} sx={{ justifyContent: "center", alignItems: "center", width: "100%", height: "90%" }}>
                <Box>
                  <img src={EmptyTemplate} alt="Você não tem modelos" width={"494px"} height={"330px"} />
                  <Box display={"flex"} sx={{ flexDirection: "column", gap: "10px", justifyContent: "center", textAlign: "center" }}>
                    <Titles bold type={"H3"} children={"Parece que você não tem solicitações :("} color={"#8033F4"} />
                    <Titles type={"P1"} children={"Abra uma agora mesmo, é fácil e rápido"} />
                    <Button
                      variant='contained'
                      style={{ color: '#fff', width: '100%', padding: '10px', marginTop: "10px" }}
                      onClick={() => Navigate("/suporte/adicionar")}
                    >
                      Abrir Solicitação
                    </Button>
                  </Box>
                </Box>
              </Box>
            )
          }
        </Box>
      </Fade>
      <Dialog
        open={openModalView}
        onClose={() => setOpenModalView(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent style={{ borderRadius: '12px' }}>

          <Grid container spacing={1}>

            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '20px'
              }}
            >
              <Typography variant="h5">
                Detalhes da Solicitação
              </Typography>
              <div style={{ marginLeft: 'auto' }}> {/* Move o StatusLabel para a direita */}
                <StatusLabel status={selectedMessages?.body?.sector} />
              </div>
            </Grid>

            <Grid item xs={12} style={{ display: 'flex' }}>
              <Typography variant="body1" style={{ fontWeight: 'bold', marginRight: '4px' }}>Titulo:</Typography>
              <Typography variant="body1">{selectedMessages?.title}</Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
              <Typography variant="body1" style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
                <span style={{ fontWeight: 'bold', marginRight: '4px' }}>Descrição:</span>
                {selectedMessages?.description}</Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                minHeight={'48px'}
                sx={{
                  background: '#F5F5F5',
                  borderRadius: '4px',
                  padding: '0 10px'
                }}
              >
                {selectedMessages?.body?.files?.map((file, index) => (
                  <>
                    <Box
                      key={index}
                      display={'flex'}
                      gap={'10px'}
                      alignItems={'center'} 
                      sx={{
                        maxWidth: '100%',
                        wordBreak: 'break-word',
                      }}
                    >
                      <img src={file.base64String} alt="file Image" width={'24px'} height={'24px'} style={{ objectFit: 'cover' }} />
                      <Typography variant="body2" style={{ fontSize: '13px', color: '#303035', maxWidth: '200px' }}>
                        {file.name.length > 25 ? `${file.name.substring(0, 25)}...` : file.name}
                      </Typography>
                    </Box>

                  </>
                ))}
              </Box>
            </Grid>

            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                minHeight={'48px'}
                marginTop={'20px'}
                sx={{
                  background: '#E9F5E3',
                  borderRadius: '4px',
                  padding: '0 10px'
                }}
              >
                <Typography variant="body1" >Estamos analisando sua solicitação, entraremos em contato em breve.</Typography>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModalView(false)} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default Support;
