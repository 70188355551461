import React, { useState } from 'react';
import {
  Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  TablePagination, TextField, Box, Checkbox
} from '@mui/material';
import SearchIcon from '../../assets/Icons/components/Search';

// Utility functions for sorting
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

// Main component
const DynamicTableSelected = ({ data, columns, activeSearch, titleSearch, button, titleLabel, itensPerPage = 15, onSelectionChange }) => {
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(itensPerPage);
  const [page, setPage] = useState(0);
  const [selectedItems, setSelectedItems] = useState(new Set());

  const handleSearch = (event) => setSearch(event.target.value);
  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (columnId) => {
    const isAsc = orderBy === columnId && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(columnId);
  };

  const handleSelect = (itemId) => {
    setSelectedItems(prev => {
      const updated = new Set(prev);
      if (updated.has(itemId)) {
        updated.delete(itemId);
      } else {
        updated.add(itemId);
      }
      const selectedArray = [...updated];
      onSelectionChange && onSelectionChange(selectedArray);
      return updated;
    });
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      const newSelected = new Set(slicedData.map(item => item.id));
      setSelectedItems(newSelected);
      onSelectionChange && onSelectionChange([...newSelected]);
    } else {
      setSelectedItems(new Set());
      onSelectionChange && onSelectionChange([]);
    }
  };

  const filteredData = search
    ? data.filter((item) =>
      Object.keys(item).some((key) =>
        item[key]?.toString().toLowerCase().includes(search.toLowerCase())
      )
    )
    : data;

  const sortedData = stableSort(filteredData, getComparator(order, orderBy));
  const slicedData = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box sx={{ borderRadius: 2, overflow: 'hidden', padding: 2 }}>
      {/* Search input */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
        {activeSearch && (
          <TextField
            variant="outlined"
            size="small"
            value={search}
            onChange={handleSearch}
            sx={{ width: '25%', backgroundColor: '#fff', borderRadius: 1 }}
            placeholder={titleSearch || 'Pesquisar'}
            InputProps={{
              startAdornment: (
                <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 1 }}>
                  <SearchIcon sx={{ paddingLeft: 1, color: '#52525B' }} />
                </Box>
              )
            }}
          />
        )}

        {button && (
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#8142FC', color: '#fff', textTransform: 'none',
              fontFamily: 'Satoshi', fontSize: 16, boxShadow: 'none',
              display: 'flex', alignItems: 'center', justifyContent: 'center',
            }}
            onClick={button.action || (() => alert('Estamos trabalhando nesta opção.'))}
          >
            {button.icon && (
              <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
                {button.icon}
              </Box>
            )}
            {button.tooltip}
          </Button>
        )}
      </Box>

      {/* Table */}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {/* Render checkbox header */}
              <TableCell sx={{ backgroundColor: '#FFF', borderBottom: 'none' }}>
                <Checkbox
                  indeterminate={selectedItems.size > 0 && selectedItems.size < slicedData.length}
                  checked={selectedItems.size === slicedData.length}
                  onChange={handleSelectAll}
                />
              </TableCell>

              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  align={column.align || 'left'}
                  sx={{
                    fontSize: { xl: 16, lg: 13, sm: 13 },
                    fontFamily: 'Satoshi',
                    padding: { lg: '16px 30px', sm: '16px 10px' },
                    backgroundColor: '#FFF',
                    borderBottom: 'none',
                    cursor: column.filter ? 'pointer' : 'default',
                    whiteSpace: 'nowrap', // Evita quebra de linha
                    overflow: 'hidden',  // Oculta o texto que ultrapassa a largura da célula
                    textOverflow: 'ellipsis' // Adiciona reticências quando o texto é muito longo
                  }}
                  onClick={column.filter ? () => handleSort(column.field) : null}
                >
                  <Box display="flex" alignItems="center">
                    {column.header}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {slicedData.map((row, index) => (
              <TableRow key={index}>
                {/* Render checkbox cell */}
                <TableCell sx={{ backgroundColor: index % 2 === 0 ? '#F5F3FF' : '#fff', borderBottom: 'none' }}>
                  <Checkbox
                    checked={selectedItems.has(row.id)}
                    onChange={() => handleSelect(row.id)}
                  />
                </TableCell>

                {columns.map((column, colIndex) => (
                  <TableCell
                    key={colIndex}
                    align={column.align || 'left'}
                    sx={{
                      fontSize: { xl: 16, lg: 13, sm: 13 },
                      fontFamily: 'Satoshi',
                      padding: { lg: '16px 30px', sm: '16px 10px' },
                      backgroundColor: index % 2 === 0 ? '#F5F3FF' : '#fff',
                      borderBottom: 'none',
                      whiteSpace: 'nowrap', // Evita quebra de linha
                      overflow: 'hidden',  // Oculta o texto que ultrapassa a largura da célula
                      textOverflow: 'ellipsis' // Adiciona reticências quando o texto é muito longo
                    }}
                  >
                    {row[column.field]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="center" mt={2}>
        <TablePagination
          rowsPerPageOptions={[20,25, 50, 100]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
};

export default DynamicTableSelected;
