// Description: This component renders the Header 
// Update: 12/08/2024

// React Components and DOM Elements
import React from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';

// Material UI Components
import { Box, Breadcrumbs, Link } from '@mui/material';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';

// Components and functions from the project
import ThemeColors from '../ThemeColors/ThemeColors';
import HelpIcon from '../../assets/Icons/components/Help';

// Function to convert a path segment into a more readable format
const formatSegment = (segment) => {
    return decodeURIComponent(segment)
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

// Render Your Component
function HeaderPage() {
    
    const location = useLocation();
    const path = location.pathname;
    const pathSegments = path.split('/').filter(x => x);
    
    const createPathToSegment = (index) => {
        const path = pathSegments.slice(0, index + 1).join('/');
        return '/' + path.split('/').map(decodeURIComponent).join('/');
    };
    
    return (
        <Box sx={{
            width: '100%',
            backgroundColor: '#FFFFFF',
            border: '1.5px solid #DBDBDB',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: '60px',
            paddingRight: '60px',
            paddingTop: '35px',
            paddingBottom: '25px',
        }}>
            <Breadcrumbs 
                aria-label="breadcrumb"
                separator={<NavigateNextOutlinedIcon style={{ fontSize: '35px', color: ThemeColors.purple600 }} />}
            >
                {pathSegments.map((segment, index) => (
                    <Link
                        key={index}
                        component={RouterLink}
                        to={createPathToSegment(index)}
                        sx={{ color: index === pathSegments.length - 1 ? ThemeColors.purple600 : ThemeColors.gray30 , textDecoration: 'none' , fontSize: '24px', fontFamily:'Satoshi-Medium' }}  
                    >
                        {formatSegment(segment)}
                    </Link>
                ))}
            </Breadcrumbs>
            <HelpIcon />
        </Box>
    );
}

export default HeaderPage;
