// Desc: Formulário de Email para criação de novos modelos de email e também para visualização e edição de modelos de email já existentes.
// Update: 21/05/2024 (OK)

// React Components
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';

// Material UI Components
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem, Grid, FormControl, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import ThemeColors from '../../components/ThemeColors/ThemeColors';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';


// render the form of WhatsApp
const FormWhatsApp = ({ messageTemporaryWhatsApp, setMessageTemporary, variablesOfTemplate, setViewWhatsAppScreenProps, saveTemplate, edit = false }) => {

    const [imageBase64, setImageBase64] = useState('');

    // State to control the visibility of the variables
    const [expanded, setExpanded] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (imageBase64) {
            setViewWhatsAppScreenProps((prevProps) => {
                const newMessage = {
                    id: prevProps.messages.messages.length + 1,
                    type: 'img',
                    message: `${imageBase64}`,
                    fromMe: true
                };

                return {
                    ...prevProps,
                    messages: {
                        ...prevProps.messages,
                        messages: [...prevProps.messages.messages, newMessage]
                    }
                };
            });
        }
    }, [imageBase64, setViewWhatsAppScreenProps]);


    // Function to toggle the visibility of the variables
    const toggleAccordionVisibility = () => {
        setIsVisible(!isVisible);
    };

    // Function to handle the change of the accordion
    const handleChangeAccordion = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    // Function to add a variable in the temporary message
    const addVariableInTemporaryMessage = (variable) => {
        setMessageTemporary(messageTemporaryWhatsApp + '{{' + variable + '}}');
    };

    //  Function to add a message
    function addMessage() {
        setViewWhatsAppScreenProps((prevProps) => {
            const newMessage = {
                id: prevProps.messages.messages.length + 1,
                type: 'whatsapp',
                message: messageTemporaryWhatsApp,
                fromMe: true
            };

            return {
                ...prevProps,
                messages: {
                    ...prevProps.messages,
                    messages: [...prevProps.messages.messages, newMessage]
                }
            };
        });

        setMessageTemporary('');
    }

    const handleDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.onload = () => {
            setImageBase64(reader.result);
        };
        reader.readAsDataURL(file);
    };

    return (
        <>
            <Grid container spacing={2} style={{ marginTop: '20px' }}>
                <Grid item xs={6}>
                    <Typography style={{ color: ThemeColors.gray40, fontSize: '20px' }}>Mensagem</Typography>
                </Grid>
                <Grid item xs={6} style={{ position: 'relative' }}>
                    <FormControl fullWidth>
                        <Typography onClick={toggleAccordionVisibility} style={{
                            color: ThemeColors.purple500,
                            marginRight: '5px',
                            marginTop: '2px',
                            fontFamily: 'Satoshi-Medium',
                            fontSize: '18px',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            cursor: 'pointer'
                        }}>
                            {isVisible ? "Esconder Variáveis" : "+ Adicionar Variáveis"}
                        </Typography>
                        {isVisible && (
                            <div style={{
                                position: 'absolute',
                                right: 0, 
                                top: '100%',  
                                width: 'auto', 
                                backgroundColor: ThemeColors.purple200,
                                boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                                overflow: 'hidden',   
                                zIndex: 1,
                                borderRadius: '12px'
                            }}>
                                {Object.entries(variablesOfTemplate).map(([category, values]) => (
                                    <Accordion key={category} expanded={expanded === category} onChange={handleChangeAccordion(category)} style={{ background: ThemeColors.purple200, border: 'none !important' }}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography>{category}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{ border: 'none !important', padding: 0 }}>
                                            {values.length > 0 ? (
                                                values.map((value) => (
                                                    <MenuItem key={value} onClick={() => addVariableInTemporaryMessage(value)} style={{ color: ThemeColors.purple950 }}>
                                                        {value}
                                                    </MenuItem>
                                                ))
                                            ) : (
                                                <Typography>Adicionar variáveis</Typography>
                                            )}
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </div>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            fullWidth
                            multiline
                            rows={12}
                            variant="outlined"
                            value={messageTemporaryWhatsApp}
                            onChange={(e) => setMessageTemporary(e.target.value)}
                            autoComplete="off"
                            style={{ background: 'white', color: 'black', borderRadius: '12px', margin: 0, border: `1px solid ${ThemeColors.gray20}`, color: ThemeColors.gray40 }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Dropzone onDrop={handleDrop}>
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <Button style={{
                                        width: '100%', padding: '23px', background: 'white', color: 'black',
                                        border: `1px solid ${ThemeColors.gray20}`, color: ThemeColors.gray30, fontSize: '14px'
                                    }}
                                        startIcon={<AddPhotoAlternateIcon style={{ color: ThemeColors.gray30, fontSize: '22px' }} />}
                                    >
                                        Clique para adicionar uma imagem
                                    </Button>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <Button style={{ width: '100%', padding: '13px' }} onClick={addMessage} variant="outlined"
                        >
                            Adicionar Mensagem
                        </Button>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <Button style={{ width: '100%', padding: '13px' }} variant="contained"
                            onClick={saveTemplate}
                        >
                            { edit ? "Editar Modelo" : "Salvar Modelo" }
                        </Button>
                    </FormControl>
                </Grid>
            </Grid >
        </>
    );
};

export default FormWhatsApp;