// Endpoint used to change the password.
// The token and the new password are sent to the backend.
// The backend changes the password and returns a message.
// Author: Vitor de Jesus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/133201922/AuthRoutes

import axios from 'axios';

export async function ChangePassword(data) {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/change-password`,
            data,
            { headers: { 'Content-Type': 'application/json' } }
        );
        return response.data;
    } catch (error) {
        if (error.response) {
            const status = error.response.status;
            if (status === 400) {
                return { 
                    status: 400,
                    message: 'Erro ao verificar token.'
                };
            } else if (status === 429) {
                return {
                    status: 429,
                    message: 'Muitas solicitações. Tente novamente mais tarde.'
                };
            } else if (status === 404){
                return {
                    status: 404,
                    message: 'Token inválido.'
                };
            }
        }  
    }
}
