import axios from 'axios';

export async function PortalConfig(data) {
    try {
        const token = localStorage.getItem('jwt_token');

        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/configurar/portal`,
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            }
        );

        return response.data;
    } catch (error) {
        if (error.response) {
            const errorCode = error.response.status;
            const errorMessage = error.response.data.error || 'Erro desconhecido';

            return { errorCode, errorMessage };
            
        }   

        console.error('Erro ao buscar dados da API:', error);
    }   
}
