// This endpoint is used to generate a new channel whatsapp code.
// The response is an object with a message.
// Author: Vitor de Jesus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/136806402/Channels

import axios from 'axios';

export async function ChannelAdd() {
    try {

        const token = localStorage.getItem('jwt_token');

        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/generate/code`, 
            {},
            { 
                headers: { 
                    Authorization: `Bearer ${token}` 
                },
            }
        );
        
        const responseData = response.data;

        return responseData;
    } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
        throw new Error('Erro ao buscar dados da API');
    }
}
