import axios from 'axios';

export async function DeleteListagens(data) {
    try {

        const token = localStorage.getItem('jwt_token');
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/delete-listagem`, 
            data,
            {
                headers: {
                    Authorization: `Bearer ${token}` ,
                    'Content-Type': 'application/json'
                },
            }   
        );
        
        const responseData = response.data;

        return responseData;
    } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
        throw new Error('Erro ao buscar dados da API');
    }
}