import axios from 'axios';

export async function CreateTemplate(data) {
    try {

        const token = localStorage.getItem('jwt_token');

        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/template/create`, 
            data,  
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            }
        );
        
        const responseData = response.data;

        return responseData;
    } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
    }
}
