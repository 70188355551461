// This endpoint is used to export the analytics data present in the dashboard page.
// Author: Vitor de Jesus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/135593992/Dashboard

import axios from 'axios';

export async function Analytics(onProgress) {
    try {

        const token = localStorage.getItem('jwt_token');

        const response = await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BACKEND_URL}/export-analytics`,
            headers: { 
                Authorization: `Bearer ${token}`  // Envia o token no cabeçalho Authorization
            },
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
                if (onProgress) {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    onProgress(percentCompleted);
                }
            },
        });

        console.log('response', response);

        return response.data;
    } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
        throw new Error('Erro ao buscar dados da API');
    }
}
