// Desc: Component render the screen of cell phone with the messages
// Update: 12/08/2024

// React Components and DOM Elements
import React, { useEffect, useState } from 'react';

// Material-UI Components
import { Box, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

// Functions of the project
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import RenderTypeAplication from './RenderTypeAplication';
import RenderNotificationHeader from './RenderNotificationHeader';
import RenderMessageContent from './RenderMessageContent';
import ThemeColors from '../ThemeColors/ThemeColors';

import "./Phone.css";
import Background from '../../assets/ilustrations/Background.png';
import BORDER from '../../assets/ilustrations/BORDER.png';
import QRCODE from '../../assets/ilustrations/Qrcode.png';

// Render the screen of cell phone with the messages
const ViewWhatsAppScreen = (props) => {
    // State variables
    const [loading, setLoading] = useState(false);

    // Function to remove a message
    const handleRemoveMessage = (id) => {

        const newMessages = props.viewWhatsAppScreenProps.messages.messages.filter((message) => message.id !== id);

        props.setViewWhatsAppScreenProps((prevProps) => {
            return {
                ...prevProps,
                messages: {
                    ...prevProps.messages,
                    messages: newMessages
                }
            };
        });

    };

    const generateQRCode = async () => {
        props.handleGenerateQRCode();
    }

    return (
        <div className="contain">
            <div className="phonebody-external">
                <div className="phonebody-internal">
                    <div className="top">
                        <div className="speaker"></div>
                    </div>
                    <div className="icon">
                        <i className="fa fa-lock" aria-hidden="true"></i>
                    </div>
                    <div
                        style={{
                            overflow: 'hidden',
                            overflowY: props.type === 'qrCode' ? 'auto' : 'hidden',
                            scrollbarWidth: 'none',
                            scrollbarColor: 'transparent transparent',
                            backgroundImage: 'url(' + Background + ')',
                            backgroundColor: '#fff',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            height: 'calc(100% - 2px)',
                            maxHeight: 'calc(100% - 2px)',
                            borderRadius: '50px',
                        }}
                    >
                        {props.type === 'qrcode' && (
                            <>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginTop: '20%',

                                }}>
                                    <Typography style={{ color: 'black', fontSize: '15px', marginBottom: '20px' }}>{props.name ? props.name : 'Nome do WhatsApp'}</Typography>
                                    <div style={{ background: 'white', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', height: '100%' }}>
                                        <div style={{
                                            position: 'relative',
                                            maxWidth: '260px',
                                            maxHeight: '260px',
                                            marginBottom: '20px',
                                            marginTop: '50px',
                                            textAlign: 'center',
                                            border: '10px solid transparent',
                                            borderImage: `url(${BORDER}) 30 stretch`,
                                            borderImageSlice: '30 fill',
                                            padding: '10px',
                                        }}>
                                            <img src={props.qrCode} alt="QR Code" style={{
                                                width: '100%',
                                                height: '100%',
                                                display: 'block',
                                                filter: 'grayscale(100%)'
                                            }} />
                                            <div style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                color: ThemeColors.gray40,
                                                fontSize: '15px',
                                                width: '60%',
                                            }}>
                                                Seu código aparecerá aqui
                                            </div>
                                        </div>
                                        <Button
                                            onClick={generateQRCode}
                                            style={{
                                                'marginBottom': '50px',
                                                'padding': '15px',
                                                'width': '260px',
                                                'fontSize': '16px',
                                                color: props.countdownActive ? ThemeColors.gray40 : ThemeColors.gray10,
                                                background: props.countdownActive ? ThemeColors.gray30 : ThemeColors.purple500,
                                                'borderRadius': '6px',
                                            }}
                                            sx={{
                                                opacity: loading ? '0' : '1',
                                                pointerEvents: loading ? 'none' : '',
                                            }}
                                            disabled={props.countdownActive}
                                        >
                                            {props.countdownActive ? `Verificando sua conexão (${props.countdown})` : 'Gerar novo código'}
                                        </Button>
                                    </div>
                                </div>

                            </>
                        )}

                        <RenderNotificationHeader type={props.type} />
                        <RenderTypeAplication type={props.type} messages={props.messages} />

                        <Grid container spacing={2} sx={{ padding: { sm: '15px 20px 20px 20px', xl: '15px 0px 20px 20px' }, overflowY: 'scroll', maxHeight: { sm: '712px', lg: '548px' }, marginTop: '1px', gap: '16px' }}>
                            {props.messages && props.messages.messages && props.messages.messages.map((message) => (
                                <Grid
                                    item
                                    xs={12}
                                    key={message.id}
                                    className="message-item"
                                    style={{ display: 'flex', justifyContent: message.fromMe ? 'flex-end' : 'flex-start', paddingTop: '0' }}
                                >
                                    <div className="message-content">
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', maxWidth: { sm: '330px', lg: '255px' } }}>
                                            {props.type === 'whatsapp' && message.fromMe && !message.disabledRemove && !message.config && (
                                                <IconButton
                                                    style={{ color: '#CD4747 !important', marginRight: '5px' }}
                                                    aria-label="remove"
                                                    onClick={() => handleRemoveMessage(message.id)}
                                                    disabled={props.isEditing}
                                                >
                                                    <DeleteForeverOutlinedIcon style={{ color: '#CD4747' }} />
                                                </IconButton>
                                            )
                                            }
                                            {
                                                props.type === 'whatsapp' && message.config && (
                                                    <IconButton
                                                        style={{ color: '#CD4747 !important', marginRight: '5px' }}
                                                        aria-label="remove"
                                                        onClick={message.options}
                                                        disabled={props.isEditing}
                                                    >
                                                        <DeleteForeverOutlinedIcon style={{ color: '#CD4747' }} />
                                                    </IconButton>
                                                )
                                            }
                                            <RenderMessageContent message={message} />
                                        </Box>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                </div>
                <div className="bottom"></div>
            </div>
        </div>
    );
};

export default ViewWhatsAppScreen;