import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { Button, Typography, Box } from '@mui/material';

const SocketTest = () => {
    const [isConnected, setIsConnected] = useState(false);
    const [messages, setMessages] = useState([]);
    const [socket, setSocket] = useState(null);
    const [token, setToken] = useState(null);
    const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    const paymentId = '8984eeb449564a75ba0915daf3214003';
    const [txid, setTxid] = useState('8984eeb449564a75ba0915daf3214003');
    const [paymentStatus, setPaymentStatus] = useState('');
    
     
    // Função para buscar o token
    const fetchToken = async () => {
        try {
            const response = await fetch(`${REACT_APP_BACKEND_URL}/auth/portal`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ cpf_cnpj: '49452310000136' }),
            });

            if (!response.ok) {
                throw new Error('Falha ao obter o token');
            }

            const data = await response.json();
            setToken(data.token);
        } catch (error) {
            console.error('Erro ao buscar token:', error);
        }
    };

    useEffect(() => {
        fetchToken();
    }, []);

      // Efeito colateral para gerenciar a conexão com o servidor via Socket.IO
      useEffect(() => {
        if (token && paymentStatus !== 'CONFIRMADO') {
            const socket = io(process.env.REACT_APP_BACKEND_URL, {
                reconnection: true, // Permite reconectar automaticamente
                reconnectionAttempts: 5, // Número máximo de tentativas de reconexão
                reconnectionDelay: 3000, // Delay entre tentativas (3 segundos)
            });

            const handleConnect = () => {
                console.log('Conectado ao servidor Flask via SocketIO!'); // Log de conexão
                setMessages(prev => [...prev, 'Conectado ao servidor Flask via SocketIO!']);
                setIsConnected(true);
                const txid = paymentId;
                if (token && txid) {
                    // Envia o token e o txid para reautenticação/reconexão
                    socket.emit('authenticate', { token, txid });
                }
            };

            // Lida com a desconexão
            const handleDisconnect = () => {
                console.log('Desconectado do servidor Flask.'); // Log de desconexão
                setMessages(prev => [...prev, 'Desconectado do servidor Flask.']);
                setIsConnected(false);
            };

            // Lida com a reconexão
            const handleReconnect = (attemptNumber) => {
                console.log(`Reconectado ao servidor Flask após ${attemptNumber} tentativas.`); // Log de reconexão
                setMessages(prev => [...prev, `Reconectado ao servidor Flask após ${attemptNumber} tentativas.`]);
                const txid = paymentId;
                if (token && txid) {
                    // Reenvia a autenticação e o txid após reconexão
                    socket.emit('authenticate', { token, txid });
                }
            };

            // Lida com erros de reconexão
            const handleReconnectError = () => {
                console.error('Erro ao tentar reconectar ao servidor Flask.'); // Log de erro
                setMessages(prev => [...prev, 'Erro ao tentar reconectar ao servidor Flask.']);
            };

            // Lida com respostas do servidor
            const handleResponse = (data) => {
                console.log('Recebido do servidor:', data); // Log da resposta
                setMessages(prev => [...prev, `Resposta do servidor: ${data.data}`]);
            };

            // Lida com mensagens da fila
            const handleMessage = (data) => {
                console.log('Mensagem da fila:', data); // Log da mensagem
                setMessages(prev => [...prev, `Mensagem da fila: ${data.data}`]);
                if (data.data.status === 'received') {
                    setPaymentStatus('CONFIRMADO'); // Atualiza status do pagamento
                    console.log('Pagamento confirmado!');
                } else {
                    console.log('Pagamento não confirmado!');
                }
            };

            // Define os manipuladores de eventos para o socket
            socket.on('connect', handleConnect);
            socket.on('disconnect', handleDisconnect);
            socket.on('reconnect', handleReconnect);
            socket.on('reconnect_error', handleReconnectError);
            socket.on('response', handleResponse);
            socket.on('message', handleMessage);

            // Cleanup ao desmontar o componente
            return () => {
                socket.off('connect', handleConnect);
                socket.off('disconnect', handleDisconnect);
                socket.off('reconnect', handleReconnect);
                socket.off('reconnect_error', handleReconnectError);
                socket.off('response', handleResponse);
                socket.off('message', handleMessage);
                socket.disconnect(); // Desconecta ao desmontar
            };
        }
    }, [token, paymentStatus]);

    // Função para enviar uma mensagem de teste para o servidor
    const sendMessage = () => {
        if (socket) {
            socket.emit('message', 'Hello from client!');
            setMessages(prev => [...prev, 'Message sent: Hello from client!']);
        }
    };

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant="h4">Socket.IO Test Component</Typography>
            <Typography variant="h6" color={isConnected ? 'green' : 'red'}>
                {isConnected ? 'Connected' : 'Disconnected'}
            </Typography>
            <Button variant="contained" onClick={sendMessage} disabled={!isConnected}>
                Send Test Message
            </Button>
            <Box sx={{ marginTop: 2 }}>
                <Typography variant="h6">Messages:</Typography>
                {messages.map((msg, index) => (
                    <Typography key={index}>{msg}</Typography>
                ))}
            </Box>
        </Box>
    );
};

export default SocketTest;
