// Desc: Page to display the list of spreadsheets

// React imports
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Material-UI imports
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";

// Fuctions and Components to project
import DynamicTable from "../../components/Tables/DynamicTable";
import DeleteIcon from '../../assets/Icons/components/Delete';
import EmptyTemplate from "../../assets/ilustrations/EmptyTemplates.png"
import HeaderPage from '../../components/HeaderPage/HeaderPage'
import PlusIcon from '../../assets/Icons/components/Plus';
import StatusLabel from '../../components/StatusLabel/StatusLabel';
import StatusIcon from '../../components/StatusLabel/StatusIcon';
import ThemeColors from "../../components/ThemeColors/ThemeColors";
import Titles from "../../components/Titles/Titles";
import ViewStreamIcon from '@mui/icons-material/ViewStream';

import { isAuthenticated } from '../../authMiddleware';
import Loading from "../../components/Loading/Loading";

import { ApiAlls } from "../../api/n8n/Listagens/ApisAll";

function Apis() {

  // States to contro authetication and user info
  const { authenticated, userType, uuidClient } = isAuthenticated();
  const [userInfo, setUserInfo] = useState({});
  let navigate = useNavigate();

  // States to page control
  const [loading, setLoading] = useState(true);
  const [videoUrl, setVideoUrl] = useState("./listagens.mp4");
  const [openModalHelp, setOpenModalHelp] = useState(false);
  const [listagens, setListagens] = useState([]);

  useEffect(() => {
    if (authenticated) {
      const { decryptedData } = isAuthenticated();
      setUserInfo(decryptedData);
    }
  }, []);

  useEffect(() => {
    if (userInfo['UUID']) {
      fetchData();
    }
  }, [userInfo])

  const fetchData = async () => {

    try {

      const response = await ApiAlls(userInfo['UUID']);

      console.log(response);
      const listagens = response.map((item) => {
        return {
          id: item.id,
          title: item.title,
          desc: item.description,
          url: item.url,
          method: item.apiMethod

        }

      });

      setListagens(listagens);


    } catch (error) {
      console.error("Erro ao obter dados da API:", error);
      setLoading(false);
    }
    finally {
      setLoading(false);
    }

  }

  const handlePageAdd = () => {
    navigate("/apis/adicionar");
  };

  if (loading) {
    return <Loading open={true} />
  }

  return (
    <Fragment>
      <Fade in={!loading} timeout={1000}>
        <Box width={"100%"} height={"100vh"}>
          <HeaderPage title="Planilhas" />
          {
            listagens && listagens.length > 0 && (
              <DynamicTable
                title="APIs"
                data={listagens}
                columns={[

                  { header: '', field: '', render: (item) => <StatusIcon status={'WEBHOOK'} /> },
                  { header: 'Título', field: 'title' },
                  { header: 'Descrição', field: 'desc' },
                  { header: 'Url', field: 'url' },
                  { header: 'Método', field: 'method', render: (type) => <StatusLabel status={type} usingOnTable /> },
                ]}
                actions={[


                ]}
                button={{ icon: <PlusIcon />, tooltip: 'Adicionar Integração ', action: h => handlePageAdd() }}
                activeSearch={true}
                titleSearch={'Pesquisar api'}
                titleLabel={'Planilhas por api'}
              />
            )
            || (
              <Box display={"flex"} sx={{ justifyContent: "center", alignItems: "center", width: "100%", height: "90%" }}>
                <Box>
                  <img src={EmptyTemplate} alt="Você não tem modelos" width={"494px"} height={"330px"} />
                  <Box display={"flex"} sx={{ flexDirection: "column", gap: "10px", justifyContent: "center", textAlign: "center" }}>
                    <Titles bold type={"H3"} children={"Parece que você não tem APIs cadastradas :("}
                      color={"#8033F4"} />
                    <Titles type={"P1"} children={"Integre uma API agora mesmo, é fácil e rápido"} />

                    <Button
                      variant='contained'
                      style={{ color: '#fff', width: '100%', padding: '10px', marginTop: "10px" }}
                      onClick={() => handlePageAdd()}
                    >
                      Adicionar integração API
                    </Button>
                  </Box>
                </Box>
              </Box>
            )
          }
        </Box>
      </Fade>

    </Fragment>
  );
}

export default Apis;