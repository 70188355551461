
import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { TimePicker } from '@mui/x-date-pickers';

import { createTheme, ThemeProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import ptLocale from 'date-fns/locale/pt-BR';
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import HelpAdd from '../../components/Cards/HelpAdd';
import AlertSnackbar from "../../components/Alerts/AlertSnackbar";

import ThemeColors from '../../components/ThemeColors/ThemeColors';
import themeColors from '../../components/ThemeColors/ThemeColors';
import Loading from '../../components/Loading/Loading';
import LockIcon from '@mui/icons-material/Lock';
import { CampaignSave } from '../../api/backend/Campaigns/CampaignSave';
import axios from 'axios';
import { GetAllTemplates } from '../../api/backend/Templates/GetAllTemplates';

import { GetListagens } from "../../api/backend/Listagens/GetListagens";
import { isAuthenticated } from '../../authMiddleware';
import { useNavigate } from 'react-router-dom';

import { Channels } from '../../api/backend/Channels/Channels';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function PageAddFast() {

    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState('');
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null);
    const [minSendTime, setMinSendTime] = useState(null)
    const [maxSendTime, setMaxSendTime] = useState(null);
    const [whatsAppChannel, setWhatsAppChannel] = useState([]);
    const [selectedWhatsAppChannel, setSelectedWhatsAppChannel] = useState('');
    const [whatsAppModel, setWhatsAppModel] = useState('');
    const [smsChannel, setSmsChannel] = useState([]);
    const [selectedSmsChannel, setSelectedSmsChannel] = useState('');
    const [smsModel, setSmsModel] = useState('');
    const [emailChannel, setEmailChannel] = useState([]);
    const [selectedEmailChannel, setSelectedEmailChannel] = useState('');
    const [emailModel, setEmailModel] = useState('');
    const [listSelected, setListSelected] = useState('');
    const [dateDynamic, setDateDynamic] = useState('');
    const [dateColumn, setDateColumn] = useState('');
    const [weekDays, setWeekDays] = useState();
    const [sendReference, setSendReference] = useState('');
    const [daysBeforeAfter, setDaysBeforeAfter] = useState('');
    const [sendRule, setSendRule] = useState('');
    const [column, setColumn] = useState('');
    const [operator, setOperator] = useState('');
    const [value, setValue] = useState('');
    const [fieldChannelSelected, setFieldChannelSelected] = useState('');
    const [tabIndex, setTabIndex] = useState(0);
    const [type, setType] = useState('');
    const { authenticated, userType, uuidClient } = isAuthenticated();
    // Loading Transition state
    const [showLoading, setShowLoading] = useState(true);

    const navigate = useNavigate();


    const handleChangeTab = (event, newValue) => {
        setTabIndex(newValue);
    };


    const steps = [
        {
            'desc': 'Escolha um título para sua campanha'
        },
        {
            'desc': 'Escolha uma descrição para sua campanha'
        },
        {
            'desc': 'Defina a data de início e fim da campanha'
        },
        {
            'desc': 'Defina o horário de inicio e limite para envio'
        },
        {
            'desc': 'Escolha cada canal que deseja utilizar juntamente com o modelo'
        },
        {
            'desc': 'Salve a campanha'
        }
    ]


    const [errors, setErrors] = useState([]);
    const [listagens, setListagens] = useState([]);
    const [userInfo, setUserInfo] = useState({});
    const [columns, setColumns] = useState([]);
    const [modelsAvailable, setModelsAvailable] = useState([]);

    useEffect(() => {
        if (userInfo['UUID']) {
            fetchData();
            fetchData2();
            fetchData3();
        }
    }, [userInfo])

    useEffect(() => {
        if (authenticated) {
            const { decryptedData } = isAuthenticated();
            setUserInfo(decryptedData);
        }
    }, []);

    const fetchData = async () => {
        try {

            const resultJson = await GetListagens();

            console.log(resultJson);


            const listagens = resultJson.map((listagem) => {
                return {
                    id: listagem[0],
                    icon: listagem[7],
                    title: listagem[1],
                    desc: listagem[2],
                    type: listagem[7],
                    link: listagem[5],
                };
            });

            console.log(listagens);

            setListagens(listagens);


        } catch (error) {
            console.error("Erro ao obter dados da API:", error);
        }
    };

    const fetchData3 = async () => {
        try {
            
            const resultJson = await Channels();

            // Filtra e mapeia os canais WhatsApp disponíveis
            const whatsAppChannels = resultJson.status
                .filter(item => item.integration=== 'WhatsApp' || item.integration=== 'WhatsApp WEB')
                .map(item => ({
                    id: item.id,
                    title: item.owner
                }));

            whatsAppChannels.unshift({ id: false, title: 'Não enviar' });
            setWhatsAppChannel(whatsAppChannels);

            const smsChannels = resultJson.status
                .filter(item => item.integration === 'SMS')
                .map(item => ({
                    id: item.id,
                    title: item.owner
                }));

            smsChannels.unshift({ id: false, title: 'Não enviar' });
            setSmsChannel(smsChannels);

            const emailChannels = resultJson.status
                .filter(item => item.integration=== 'E-mail')
                .map(item => ({
                    id: item.id,
                    title: item.owner
                }));

            emailChannels.unshift({ id: false, title: 'Não enviar' });
            setEmailChannel(emailChannels);

            console.log('channels:', whatsAppChannels, smsChannels, emailChannels);

        } catch (error) {
            console.error('Erro ao obter status da instância:', error);
        }
    };


    const getColumns = async () => {

        const response = await axios.post('https://api.123zap.com.br/webhook/get-variables', {
            uuid_account: userInfo['UUID'],
            full_token: userInfo['TOKEN'],
            listagem: listagens.find(listagem => listagem.id === listSelected).link,
            id: userInfo['EMAIL'],
            listSelected: listSelected
        });

        console.log(response.data.data);

        const items = response.data.data.map(item => {
            return {
                id: item.id,
                name: item.name,
                column: item.column,
            }
        });

        setColumns(items);
    }

    useEffect(() => {
        if (listSelected !== '') {
            getColumns();
        }
    }, [listSelected])

    const startDateTheme = createTheme({
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        backgroundColor: errors.includes('startDate') ? '#FFEBEE' : '#F9F9F9',
                        color: themeColors.gray30,
                        borderRadius: '12px',
                    },
                },
            },
            MuiPickersDay: {
                styleOverrides: {
                    root: {
                        color: ThemeColors.purple400,
                        borderRadius: '2px',
                        borderWidth: '1px',
                        border: '1px solid',
                        backgroundColor: '#FFF',
                        '&:hover': {
                            backgroundColor: ThemeColors.purple400,
                            color: '#FFF',
                        },
                        '&.Mui-selected': {
                            backgroundColor: ThemeColors.purple400 + " !important",
                            color: '#FFF !important',
                        },
                    }
                }

            }

        }
    });

    const endDateTheme = createTheme({
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        backgroundColor: errors.includes('endDate') ? '#FFEBEE' : '#F9F9F9',
                        borderRadius: '12px',
                        color: themeColors.gray30,
                    },
                },
            },
            MuiPickersDay: {
                styleOverrides: {
                    root: {
                        color: ThemeColors.purple400,
                        borderRadius: '2px',
                        borderWidth: '1px',
                        border: '1px solid',
                        backgroundColor: '#FFF',
                        '&:hover': {
                            backgroundColor: ThemeColors.purple400,
                            color: '#FFF',
                        },
                        '&.Mui-selected': {
                            backgroundColor: ThemeColors.purple400 + " !important",
                            color: '#FFF !important',
                        },
                    }
                }

            }

        }
    });

    const startTimeTheme = createTheme({
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        backgroundColor: errors.includes('minSendTime') ? '#FFEBEE' : '#F9F9F9',
                        borderRadius: '12px',
                        color: themeColors.gray30,
                    },
                },
            },
            MuiPickersClock: {
                styleOverrides: {
                    clock: {
                        backgroundColor: '#FFF',
                        borderRadius: '12px',
                        border: '1px solid #DBDBDB',
                        padding: '10px',
                    },
                    pin: {
                        backgroundColor: ThemeColors.purple400,
                    }
                }
            }
        }
    });

    useEffect(() => {
        //emailModel
        console.log('emailModel', emailModel);
    }, [emailModel])


    useEffect(() => {
        setTimeout(() => setShowLoading(false), 1000);
    }, []);


    const saveCampaign = async () => {

        const newErrors = [];

        if (title === '') {
            newErrors.push('title');
        }
        if (desc === '') {
            newErrors.push('desc');
        }
        if (startDate === null) {
            newErrors.push('startDate');
        }
        if (endDate === null) {
            newErrors.push('endDate');
        }
        if (minSendTime === null) {
            newErrors.push('minSendTime');
        }
        if (listSelected === '') {
            newErrors.push('listSelected');
        }
        if (selectedWhatsAppChannel === '') {
            newErrors.push('whatsAppChannel');
        }
        if (selectedWhatsAppChannel != '' && whatsAppModel === '') {
            newErrors.push('whatsAppModel');
        }
        if (selectedSmsChannel === '') {
            newErrors.push('smsChannel');
        }
        if (selectedSmsChannel !== '' && smsModel === '') {
            newErrors.push('smsModel');
        }
        if (selectedEmailChannel === '') {
            newErrors.push('emailChannel');
        }
        if (selectedEmailChannel != '' && emailModel === '') {
            newErrors.push('emailModel');
        }

        if (newErrors.length > 0) {
            console.log(newErrors);
            setErrors(newErrors);
            return;
        }

        let data = {
            title: title ? title : null,
            desc: desc ? desc : null,
            startDate: startDate ? startDate : null,
            endDate: endDate ? endDate : null,
            minSendTime: minSendTime ? minSendTime : null,
            maxSendTime: maxSendTime ? maxSendTime : null,
            whatsapp_id: selectedWhatsAppChannel ? selectedWhatsAppChannel : null,
            whatsAppModel: whatsAppModel ? whatsAppModel : null,
            sms_id: selectedSmsChannel ? selectedSmsChannel : null,
            smsModel: smsModel ? smsModel : null,
            email_id: selectedEmailChannel ? selectedEmailChannel : null,
            emailModel: emailModel ? emailModel : null,
            listSelected: listSelected ? listSelected : null,
            type: type ? type : null,
            dateDynamic: dateDynamic ? true : false,
            dateColumn: dateColumn ? dateColumn : null,
            weekDays: weekDays ? weekDays : false,
            sendReference: sendReference ? sendReference : null,
            daysBeforeAfter: daysBeforeAfter ? daysBeforeAfter : null,
            sendRule: sendRule ? sendRule : false,
            column: column ? column : null,
            operator: operator ? operator : null,
            value: value ? value : null,
            uuid_account: userInfo['UUID'],
            mode: 'fast',
            list_type: listagens.find(listagem => listagem.id === listSelected).type,
        }

        const response = await CampaignSave(data);

        console.log(response, 'response');

        if (response.status === 200) {
            navigate('/campanhas');
        }


    }


    const fetchData2 = async () => {
        try {

           
            const resultJson = await GetAllTemplates();

            console.log('modelos encontrados:', resultJson);

            const items = resultJson.map(item => {
                return {
                    id: item.id,
                    title: item.title,
                    uuidAccount: item.uuid_account,
                    integration_icons:  item.channel_type,
                    status: item.status,
                    msg_type:   item.msg_type,
                    channel_type: item.channel_type,
                };

            }).filter(item => item !== null);

            console.log('models:', items)

            setModelsAvailable(items);
        } catch (error) {
            console.error('Erro ao obter modelos:', error);
        } finally {
        }
    };

    // Show the loading screen
    if (showLoading) {
        return <Loading open={true} />;
    }



    return (
        <>
            <HeaderPage title="Campanhas" />
            <Grid container spacing={4} sx={{ padding: 5 }}>
                <Grid item xs={12} lg={6} sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                    <HelpAdd Steps={steps} Title="Crie uma nova campanha" />
                    {
                        errors.length > 0 && <AlertSnackbar message="Preencha todos os campos obrigatórios" severity="error" open={true} />
                    }
                    {
                        fieldChannelSelected === 'ERROR' &&
                        <AlertSnackbar message="É necessário selecionar um canal para enviar a campanha ( WhatsApp, SMS ou Email )" severity="error" open={true} />
                    }
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Tabs value={tabIndex} onChange={handleChangeTab} aria-label="form tabs" style={{ 'background': '#FFF', 'borderRadius': '12px', marginBottom: '10px' }}>
                        <Tab label="Informações Gerais" disabled={false} />
                    </Tabs>
                    <TabPanel value={tabIndex} index={0} style={{ 'background': "#FFF", 'borderRadius': '12px', padding: '20px', minHeight: '60vh' }}>
                        <Grid container spacing={3}>

                            <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <TextField
                                        placeholder='Título da campanha'
                                        id="Titulo"
                                        variant="outlined"
                                        fullWidth
                                        onChange={(e) => setTitle(e.target.value)}
                                        sx={{ background: errors.includes('title') ? '#FFEBEE' : '#F9F9F9', borderRadius: '12px' }}
                                        autoComplete='off'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <TextField
                                        placeholder='Descrição da campanha'
                                        id="Descrição"
                                        variant="outlined"
                                        fullWidth
                                        onChange={(e) => setDesc(e.target.value)}
                                        sx={{ background: errors.includes('desc') ? '#FFEBEE' : '#F9F9F9', borderRadius: '12px' }}
                                    />
                                </FormControl>
                            </Grid>

                        </Grid>

                        <Grid container spacing={3} style={{ marginTop: '25px' }}>
                            <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <ThemeProvider theme={startDateTheme}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"pt-br"}>
                                            <DesktopDatePicker
                                                label="Qual a data de início?"
                                                inputFormat="DD/MM/YYYY" // Corrigido o formato
                                                value={startDate}
                                                onChange={(newValue) => setStartDate(newValue)}
                                                minDate={dayjs()} // Define a data mínima como hoje
                                                InputLabelProps={{ shrink: true }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </ThemeProvider>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <ThemeProvider theme={endDateTheme}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"pt-br"}>
                                            <DesktopDatePicker
                                                inputFormat="DD/MM/YYYY"
                                                label="Qual a data de término?"
                                                renderInput={(params) => <TextField {...params} />}
                                                value={endDate}
                                                minDate={dayjs()} // Define a data mínima como hoje
                                                onChange={(newValue) => setEndDate(newValue)}
                                            />
                                        </LocalizationProvider>
                                    </ThemeProvider>
                                </FormControl>
                            </Grid>
                        </Grid>


                        <Grid container spacing={3} style={{ marginTop: '25px' }}>
                            <Grid item xs={6}>
                                <FormControl style={{ marginBottom: '15px' }} fullWidth>
                                    <ThemeProvider theme={startTimeTheme}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                                            <TimePicker
                                                label="Qual o horário de início?"
                                                ampm={false}
                                                minutesStep={60} // Define os minutos apenas em intervalos de 60
                                                renderInput={(params) => <TextField {...params} />}
                                                value={minSendTime}
                                                onChange={(newValue) => setMinSendTime(newValue)}
                                            />
                                        </LocalizationProvider>
                                    </ThemeProvider>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="Listagem">Listagem</InputLabel>
                                    <Select
                                        labelId="Listagem"
                                        id="Listagem"
                                        value={listSelected}
                                        onChange={(e) => setListSelected(e.target.value)}
                                        label="Listagem"
                                        fullWidth
                                        style={{ 'background': errors.includes('listSelected') ? '#FFEBEE' : '#F9F9F9', 'borderRadius': '12px' }}
                                    >
                                        {
                                            listagens.map((listagem) => {
                                                return (
                                                    <MenuItem value={listagem.id}>{(listagem.title + ': ' + listagem.desc).substring(0, 30) + '...'
                                                    }</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} style={{ marginTop: '25px' }}>
                            <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="WhatsApp" style={{ color: themeColors.gray30 }}>Deseja enviar pelo WhatsApp?</InputLabel>
                                    <Select
                                        labelId="WhatsApp"
                                        id="WhatsApp"
                                        label="WhatsApp"
                                        fullWidth
                                        onChange={(e) => setSelectedWhatsAppChannel(e.target.value)}
                                        value={selectedWhatsAppChannel}
                                        style={{ 'background': errors.includes('whatsAppChannel') ? '#FFEBEE' : '#F9F9F9', 'borderRadius': '12px' }}
                                    >
                                        {
                                            whatsAppChannel.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            {whatsAppChannel && selectedWhatsAppChannel !== false ? (
                                <Grid item xs={6}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel id="modelo-whatsapp" style={{ color: themeColors.gray30 }}>Qual modelo vai usar no WhatsApp?</InputLabel>
                                        <Select
                                            labelId="modelo-whatsapp"
                                            id="modelo-whatsapp"
                                            value={whatsAppModel}
                                            label="Modelo"
                                            fullWidth
                                            onChange={(e) => setWhatsAppModel(e.target.value)}
                                            style={{ 'background': errors.includes('whatsAppModel') ? '#FFEBEE' : '#F9F9F9', 'borderRadius': '12px' }}
                                        >
                                            {
                                                // Exibe somente os modelos disponíveis para o canal WhatsApp
                                                modelsAvailable.filter(model => model.channel_type === 'WhatsApp').map((model) => {
                                                    return (
                                                        <MenuItem value={model.id}>{model.title}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            ) : (
                                <Grid item xs={6}>
                                    <FormControl variant="outlined" fullWidth>
                                        <TextField
                                            label="Modelo (Bloqueado)"
                                            id="Modelo"
                                            variant="outlined"
                                            fullWidth
                                            sx={{ background: '#F9F9F9', borderRadius: '12px' }}
                                            autoComplete='off'
                                            disabled={true}
                                            InputProps={{
                                                endAdornment: (
                                                    <LockIcon style={{ color: ThemeColors.gray30 }} />
                                                ),
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            )}

                            <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="SMS" style={{ color: themeColors.gray30 }}>Deseja enviar pelo SMS?</InputLabel>
                                    <Select
                                        labelId="SMS"
                                        id="SMS"
                                        onChange={(e) => setSelectedSmsChannel(e.target.value)}
                                        label="SMS"
                                        fullWidth
                                        value={selectedSmsChannel}
                                        style={{ 'background': errors.includes('smsChannel') ? '#FFEBEE' : '#F9F9F9', 'borderRadius': '12px' }}
                                    >
                                        {
                                            smsChannel?.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            {
                                smsChannel && selectedSmsChannel !== false ? (

                                    <Grid item xs={6}>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="modelo-sms" style={{ color: themeColors.gray30 }}>Qual modelo vai usar no SMS?</InputLabel>
                                            <Select
                                                labelId="modelo-sms"
                                                id="modelo-sms"
                                                label="Modelo"
                                                fullWidth
                                                onChange={(e) => setSmsModel(e.target.value)}
                                                style={{ 'background': errors.includes('smsModel') ? '#FFEBEE' : '#F9F9F9', 'borderRadius': '12px' }}
                                            >
                                                {
                                                    // Exibe somente os modelos disponíveis para o canal SMS
                                                    modelsAvailable.filter(model => model.channel_type === 'SMS').map((model) => {
                                                        return (
                                                            <MenuItem value={model.id}>{model.title}</MenuItem>
                                                        )
                                                    }
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                ) : (
                                    <Grid item xs={6}>
                                        <FormControl variant="outlined" fullWidth>
                                            <TextField
                                                label="Modelo (Bloqueado)"
                                                id="Modelo"
                                                variant="outlined"
                                                fullWidth
                                                sx={{ background: '#F9F9F9', borderRadius: '12px' }}
                                                autoComplete='off'
                                                disabled={true}
                                                InputProps={{
                                                    endAdornment: (
                                                        <LockIcon style={{ color: ThemeColors.gray30 }} />
                                                    ),
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>)
                            }

                            < Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="Email" style={{ color: themeColors.gray30 }}>Deseja enviar pelo Email?</InputLabel>
                                    <Select
                                        labelId="Email"
                                        id="Email"
                                        onChange={(e) => setSelectedEmailChannel(e.target.value)}
                                        label="Email"
                                        value={selectedEmailChannel}
                                        fullWidth
                                        style={{ 'background': errors.includes('emailChannel') ? '#FFEBEE' : '#F9F9F9', 'borderRadius': '12px' }}
                                    >
                                        {
                                            emailChannel.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            {
                                emailChannel && selectedEmailChannel !== false ? (

                                    <Grid item xs={6}>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="modelo-email">Qual modelo vai usar no Email?</InputLabel>
                                            <Select
                                                labelId="modelo-email"
                                                id="modelo-email"
                                                value={emailModel}
                                                onChange={(e) => setEmailModel(e.target.value)}
                                                label="Modelo"
                                                fullWidth
                                                style={{ 'background': errors.includes('emailModel') ? '#FFEBEE' : '#F9F9F9', 'borderRadius': '12px' }}
                                            >
                                                {
                                                    // Exibe somente os modelos disponíveis para o canal Email
                                                    modelsAvailable.filter(model => model.channel_type === 'E-mail').map((model) => {
                                                        return (
                                                            <MenuItem value={model.id}>{model.title}</MenuItem>
                                                        )
                                                    }
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                ) : (
                                    <Grid item xs={6}>
                                        <FormControl variant="outlined" fullWidth>
                                            <TextField
                                                label="Modelo (Bloqueado)"
                                                id="Modelo"
                                                variant="outlined"
                                                fullWidth
                                                sx={{ background: '#F9F9F9', borderRadius: '12px' }}
                                                autoComplete='off'
                                                disabled={true}
                                                InputProps={{
                                                    endAdornment: (
                                                        <LockIcon style={{ color: ThemeColors.gray30 }} />
                                                    ),
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>)
                            }
                        </Grid>
                        <Grid container spacing={3} style={{ marginTop: '20px', justifyContent: 'flex-end' }}>
                            <Grid item xs={6}>
                                <Button variant="outlined" color="primary" onClick={() => saveCampaign()}
                                style={{ padding: '10px' }}
                                    fullWidth>Salvar Campanha</Button>
                            </Grid>
                        </Grid>
                    </TabPanel>
                </Grid >
            </Grid >
        </>
    );


}

export default PageAddFast;