// This component is responsible for rendering the WhatsApp Business channel configuration page.
// The client can configure the channel settings, such as the type of message, the time to start and end sending messages, and the automatic response.
// The component also displays the WhatsApp screen, which shows the messages that will be sent to the client.

// Author: Vitor de Jeus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/137396228/Como+configurar+o+WhatsApp
// Prototype: https://www.figma.com/design/NqSBP9Hjy6KFGUik7FKy4F/UIUX-design?node-id=794-67&t=EGN3IbFNlKWQyQKU-4

import React , { useState, useEffect } from 'react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import Switch from '@mui/material/Switch';
import ptLocale from 'date-fns/locale/pt-BR';
import LockIcon from '@mui/icons-material/Lock';

import HeaderPage from '../../components/HeaderPage/HeaderPage';
import ModalR from '../../components/Modals/ModalR';
import StatusLabel from '../../components/StatusLabel/StatusLabel';
import TextField from '@mui/material/TextField';
import ThemeColors from '../../components/ThemeColors/ThemeColors';
import Typography from '@mui/material/Typography';
import ViewWhatsAppScreen from '../../components/WhatsAppScreen/ViewWhatsAppScreen';

const RenderWhatsAppBusiness = ({
    selectedItem,
    setType,
    defaultMinSendTime,
    setMinSendTime,
    defaultMaxSendTime,
    setMaxSendTime,
    autoReplyOption,
    setAutoReplyOption,
    autoRemoveOption,
    setAutoRemoveOption,
    autoReply,
    setAutoReply,
    saveConfig,
    saveLoading,
    successSave,
    viewWhatsAppScreenProps,
    handleCloseModalRequest,
    openModalRequest
}) => {

    const [selected, setSelected] = useState(selectedItem.type_msg);
    const buttons = ['Atrasos', 'Avisos', 'Faturas', 'Inadimplências', 'Marketing', 'Outros'];

    const handleClick = (button) => {
        setSelected(button);
    }

    useEffect(() => {
        setType(selected);
    }, [selected, setType]);

    return (
        <>
            <HeaderPage />
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    bgcolor: 'transparent',
                    overflow: 'auto',
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingTop: 5,
                    paddingBottom: 5
                }}
            >
                <Grid container spacing={4}> 

                    <Grid item xs={12} md={6} sx={{ paddingRight: 1 }}> 

                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <Typography style={{ color: ThemeColors.gray40, fontSize: '24px' }}> Configurar<span style={{ color: ThemeColors.gray40, fontFamily: 'Satoshi-Medium', marginLeft: '10px' }}>{selectedItem.name}</span></Typography>
                            </Grid>

                            <Grid item xs={8} style={{ marginTop: '10px' }}>
                                <FormControl fullWidth>
                                    <TextField
                                        id="number"
                                        value={selectedItem.number}
                                        variant="outlined"
                                        disabled
                                        sx={{ border: `1px solid ${ThemeColors.gray30}`, borderRadius: '6px', padding: '5px' }}
                                        size='small'

                                        InputProps={{
                                            endAdornment: (
                                                <LockIcon style={{ color: ThemeColors.gray30 }} />
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} style={{ marginTop: '10px' }}>
                                <StatusLabel status={selectedItem.status} widthLabel="100%" heightLabel="100%" />
                            </Grid>

                            <Grid item xs={12}>
                                <Box item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px' }}>

                                    {buttons.map((button, index) => (
                                        <Button
                                            key={button}
                                            onClick={() => handleClick(button)}
                                            sx={{
                                                flexGrow: 1,
                                                mx: index === 0 || index === buttons.length - 1 ? 0 : 0.5,
                                                bgcolor: selected === button ? '#8142FC' : ThemeColors.gray30,
                                                color: '#fff',
                                                borderRadius: '15px',
                                                '&:hover': { bgcolor: selected === button ? '#8142FC' : 'gray' },
                                            }}
                                        >
                                            {button}
                                        </Button>
                                    ))}
                                </Box>
                            </Grid>


                            <Grid item xs={6} md={6} sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                <Switch
                                    checked={autoReplyOption}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    onChange={(e) => setAutoReplyOption(e.target.checked)}
                                    sx={{
                                        transform: 'scale(1.3)',
                                        '& .MuiSwitch-switchBase.Mui-checked': {
                                            color: ThemeColors.purple500,
                                        },
                                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                            backgroundColor: ThemeColors.purple500
                                        },
                                        '& .MuiSwitch-track': {
                                            backgroundColor: ThemeColors.gray40
                                        },
                                    }} />
                                <Typography sx={{ marginLeft: '15px', fontSize: '16px', color: ThemeColors.gray50 }}>
                                    Ativar resposta automática
                                </Typography>
                            </Grid>


                            <Grid item xs={6} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Switch
                                    checked={autoRemoveOption}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    onChange={(e) => setAutoRemoveOption(e.target.checked)}
                                    sx={{
                                        transform: 'scale(1.3)',
                                        '& .MuiSwitch-switchBase.Mui-checked': {
                                            color: ThemeColors.purple500,
                                        },
                                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                            backgroundColor: ThemeColors.purple500
                                        },
                                        '& .MuiSwitch-track': {
                                            backgroundColor: ThemeColors.gray40
                                        },
                                    }} />
                                <Typography sx={{ marginLeft: '15px', fontSize: '16px', color: ThemeColors.gray50 }}>
                                    Ativar descadastro automático
                                </Typography>
                            </Grid>


                            <Grid item xs={6} style={{ marginTop: '5px' }}>
                                <Typography sx={{ fontSize: '16px', marginBottom: '-10px', color: ThemeColors.gray50 }}>
                                    Iniciar envio às
                                </Typography>
                            </Grid>

                            <Grid item xs={6} style={{ marginTop: '5px', marginBottom: '-10px' }}>
                                <Typography sx={{ fontSize: '16px', color: ThemeColors.gray50 }}>
                                    Finalizar envio às
                                </Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl style={{ marginBottom: '15px' }} fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                                        <TimePicker
                                            ampm={false}
                                            value={defaultMinSendTime}
                                            onChange={(newValue) => setMinSendTime(newValue)}
                                            slotProps={{
                                                textField: {
                                                    sx: {
                                                        backgroundColor: 'white',
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                border: '1px solid' + ThemeColors.gray20 + ' !important',
                                                            },
                                                            '&:hover fieldset': {
                                                                border: '1px solid' + ThemeColors.gray20 + ' !important',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                border: '1px solid' + ThemeColors.gray20 + ' !important',
                                                            },
                                                            borderRadius: '4px',
                                                        },
                                                        '& .MuiInputBase-input': {
                                                            color: ThemeColors.gray50, 
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                            color: ThemeColors.gray50, 
                                                        },
                                                        '& .MuiFormLabel-root.Mui-focused': {
                                                            color: 'transparent', 
                                                        },
                                                        '& .MuiSvgIcon-root': {
                                                            color: ThemeColors.gray50, 
                                                        },
                                                    }
                                                }
                                            }} />
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl style={{ marginBottom: '15px' }} fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                                        <TimePicker
                                            ampm={false}
                                            value={defaultMaxSendTime}
                                            onChange={(newValue) => setMaxSendTime(newValue)}
                                            slotProps={{
                                                textField: {
                                                    sx: {
                                                        backgroundColor: 'white',
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                border: '1px solid' + ThemeColors.gray20 + ' !important',
                                                            },
                                                            '&:hover fieldset': {
                                                                border: '1px solid' + ThemeColors.gray20 + ' !important',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                border: '1px solid' + ThemeColors.gray20 + ' !important',
                                                            },
                                                            borderRadius: '4px',
                                                        },
                                                        '& .MuiInputBase-input': {
                                                            color: ThemeColors.gray50,
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                            color: ThemeColors.gray50,
                                                        },
                                                        '& .MuiFormLabel-root.Mui-focused': {
                                                            color: 'transparent',
                                                        },
                                                        '& .MuiSvgIcon-root': {
                                                            color: ThemeColors.gray50,
                                                        },
                                                    }
                                                }
                                            }}
                                        />

                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6} style={{ margin: '0px' }}>
                                <Typography style={{ color: ThemeColors.gray50, fontSize: '16px', marginBottom: '-10px' }}>
                                    Resposta automática
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={7}
                                        variant="outlined"
                                        value={autoReply}
                                        onChange={(e) => setAutoReply(e.target.value)}
                                        autoComplete="off"
                                        style={{ background: 'white', color: 'black', borderRadius: '12px', margin: 0, border: `1px solid ${ThemeColors.gray20}`, color: ThemeColors.gray40 }}
                                    />
                                </FormControl>
                            </Grid>


                            <Grid item xs={6}>
                           
                            </Grid>

                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    style={{ width: '100%', padding: '13px' }}
                                    onClick={saveConfig}
                                    disabled={saveLoading || successSave === true}
                                >
                                    {saveLoading ? 'Salvando...' : successSave === true ? 'Salvo com sucesso!' : successSave === false ? 'Erro ao salvar!' : 'Salvar configurações'}
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item xs={12} md={6} sx={{ paddingLeft: 1 }}> {/* Coluna Direita */}
                        <div style={{ background: '#fff', padding: '10px', borderRadius: '4px' }}>
                            <ViewWhatsAppScreen {...viewWhatsAppScreenProps} />
                        </div>
                    </Grid>

                </Grid>
                <ModalR
                    cancelText={"Mudei de ideia"}
                    color={ThemeColors.purple500}
                    confirmText={"Sim, desejo solicitar"}
                    subTitle={"Este serviço é pago e será cobrado mensalmente."}
                    isOpen={openModalRequest}
                    onClose={handleCloseModalRequest}
                    onConfirm={ () => {}}
                    title={"Deseja confirmar a solicitação?"}
                    emoji={"emoji02"}
                    iconColor={ThemeColors.purple400}
                />

            </Box>
        </>

    );
}

export default RenderWhatsAppBusiness;