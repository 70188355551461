
// This endpoint is used to get the data for the dashboard page.
// The data is used to populate the charts and tables on the dashboard page.
// the data contains information about the user's account and I.A data.
// The data is fetched from the backend API using an axios GET request.
// Author: Vitor de Jesus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/135593992/Dashboard

import axios from 'axios';

export async function DashboardData2() {
    try {

        const token = localStorage.getItem('jwt_token');

        const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/dashboard-data2`,
            { 
                headers: { 
                    Authorization: `Bearer ${token}`  // Envia o token no cabeçalho Authorization
                },
            }
        );

        const responseData = response.data;

        return responseData;
    } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
        throw new Error('Erro ao buscar dados da API');
    }
}
