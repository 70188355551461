// Description: Page to create invoice email templates
// Updated: 21/10/2024
// Author: Simon Franklin

// React
import React, { useState, useEffect } from "react";

// Material UI Components
import { Box } from "@mui/material";

// Components and functions for the project
import HeaderPage from "../../../components/HeaderPage/HeaderPage";
import DynamicForm from "../../../components/DynamicForm/DynamicForm";
import { useAuth } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../../authMiddleware";
import { GetVariables } from "../../../api/backend/Templates/GetVariables";
import { personalizedBackgrounds } from "../../../utils/templates";
import { CreateTemplate } from "../../../api/backend/Templates/CreateTemplate";
import { createObjectFromArray } from "../../../utils/utils";

import { invoicesHtmlPurple } from "../../../utils/templates";
import { invoicesHtmlPink } from "../../../utils/templates";
import { invoicesHtmlBlue } from "../../../utils/templates";
import { invoicesHtmlGreen } from "../../../utils/templates";
import { invoicesHtmlOrange } from "../../../utils/templates";
import { invoicesHtmlRed } from "../../../utils/templates";


// Render your component
const InvoicesTemplate = () => {
  // Authentication and state variables
  const { userAuthenticated } = useAuth();

  // Function to extract variables
  const extractVariables = (html) => {
    const pattern = /{{\s*(.*?)\s*}}/g;
    let variables = [];
    let match;
    while ((match = pattern.exec(html)) !== null) {
      variables.push(match[1]);
    }
    return variables;
  };

  const [htmlToBeRendered, setHtmlToBeRendered] = useState(invoicesHtmlPurple);

  const mapHTMLColors = {
    '7B25FF': invoicesHtmlPurple,
    'ed15be': invoicesHtmlPink,
    '1837D7': invoicesHtmlBlue,
    '82BD69': invoicesHtmlGreen,
    'DEA73F': invoicesHtmlOrange,
    'BB0404': invoicesHtmlRed
  };

  const [selectedColor, setSelectedColor] = useState('7B25FF');

  const handleColorChange = (color) => {
    setSelectedColor(color);
    setHtmlToBeRendered(mapHTMLColors[color]);
  };

  const variables = extractVariables(htmlToBeRendered);

  // Email Template States
  const [title, setTitle] = useState("");
  const [emailTitle, setEmailTitle] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [variablesOfTemplate, setVariablesOfTemplate] = useState({});
  const [isEmailTemplatePersonalized, setIsEmailTemplatePersonalized] =
    useState(
      JSON.parse(localStorage.getItem("isEmailTemplatePersonalized")) || false
    );
  const [background, setBackground] = useState(
    JSON.parse(localStorage.getItem("isEmailTemplatePersonalized"))
      ? ""
      : personalizedBackgrounds[5].base64
  );
  const typeChannel = "E-mail"
  const [typeMessage, setTypeMessage] = useState("Marketing");
  const palletOfColors = "N/A";
  const [formState, setFormState] = useState(createObjectFromArray(variables, ""));
  const [selected, setSelected] = useState("Marketing");


  // Function to replace the dynamic variables in the HTML
  const replaceVariables = (html, formState) => {
    return html.replace(/{{\s*(.*?)\s*}}/g, (match, p1) => {
      const value = formState[p1] || "";
  
      return value.replace(/\n/g, '<br />');
    });
  };

  // updated HTML with the value of the inputs
  const updatedHtml = replaceVariables(htmlToBeRendered, formState);

  // Component Props State
  const [viewWhatsAppScreenProps, setViewWhatsAppScreenProps] = useState({
    messages: {
      messages: [],
    },
  });

  useEffect(() => {
    if (typeChannel === "WhatsApp") {
      setViewWhatsAppScreenProps((prevProps) => ({
        ...prevProps,
        type: "whatsapp",
        messages: {
          messages: [],
        },
      }));
    } else if (typeChannel === "SMS") {
      setViewWhatsAppScreenProps((prevProps) => ({
        ...prevProps,
        type: "sms",
        messages: {
          messages: [],
        },
      }));
    } else if (typeChannel === "E-mail") {
      setViewWhatsAppScreenProps((prevProps) => ({
        ...prevProps,
        type: "email",
        messages: {
          messages: [],
        },
      }));
    }
  }, [typeChannel]);

  const Navigate = useNavigate();

  // Effect to Check Authentication and Fetch User Info
  useEffect(() => {
    if (userAuthenticated) {
      const { decryptedData } = isAuthenticated();
      setUserInfo(decryptedData);
    } else {
      Navigate("/");
    }
  }, []);

  // Effect to Fetch Data from API
  useEffect(() => {
    if (userInfo["UUID"]) {
      fetchData();
    }
  }, [userInfo]);

  // Catch template variables
  const fetchData = async () => {
    try {
      const translations = {
        Personal_information: "Informações Pessoais",
        Contact: "Contato",
        Address: "Endereço",
        Financial_References: "Referências Financeiras",
      };


      const resultJson = await GetVariables();

      const result = resultJson.reduce((acc, item) => {
        const category = translations[item[1]] || item[1]; // Traduz a categoria, ou usa o nome original caso não haja tradução
        if (!acc[category]) {
          acc[category] = [];
        }

        // limita a 10 itens

        acc[category].push(item[0]);

        return acc;
      }, {});

      console.log(result, "Template Variables");

      setVariablesOfTemplate(result);
    } catch (error) {
      console.error("Error fetching data from API:", error);
    }
  };

  // Function to save the template
  const saveTemplate = async () => {
    const template = {
      template: {
        html: htmlToBeRendered,
        variables: [
          {
            EmailTitle: emailTitle,
            Title01: formState.Title01,
            Url01: formState.URL01,
            Text: formState.Text,

          },
        ],
      },
    };

    let info = {
    
      title: title,
      message: template,
      msg_type: typeMessage,
      channel_type: "E-mail",
      reference_design: palletOfColors,
      background: background,
      type: 'HTML'
    };

    const resultJson = await CreateTemplate(info);

    if (resultJson["status"] === "success") {
      Navigate("/modelos");
    }
  };

  return (
    <>
      <HeaderPage />
      <Box
        sx={{
          position: "relative",
          width: "100%",
          minHeight: "calc(100vh - 100px)",
          paddingBottom: 10,
        }}
      >
        <DynamicForm
          title={title}
          setTitle={setTitle}
          variablesOfTemplate={variablesOfTemplate}
          emailTitle={emailTitle}
          setEmailTitle={setEmailTitle}
          Navigate={Navigate}
          typeMessage={typeMessage}
          setTypeMessage={setTypeMessage}
          updatedHtml={updatedHtml}
          variables={variables}
          handleSubmit={saveTemplate}
          formState={formState}
          setFormState={setFormState}
          selected={selected}
          setSelected={setSelected}
          mapHTMLColors={mapHTMLColors}
          setHtmlToBeRendered={setHtmlToBeRendered}
          handleColorChange={handleColorChange}
          selectedColor={selectedColor}
          isHTMLTemplate={true}
        />
      </Box>
    </>
  );
};

export default InvoicesTemplate;