// Request to get campaigns by account uuid
// Update in 20/04/2024 ( OK )

import axios from 'axios';

export async function CampaignsByUuidAccount() {
    try {
        const token = localStorage.getItem('jwt_token');
        const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/campaigns-by-uuid-account`,
            { 
                headers: { 
                    Authorization: `Bearer ${token}`  // Envia o token no cabeçalho Authorization
                },
            }
            
        );
        
        const responseData = response.data;
        return responseData;
    } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
        // Melhor manejo do erro, exibindo mensagem de erro do servidor, se disponível
        const errorMsg = error.response?.data?.message || 'Erro desconhecido ao buscar dados da API';
        throw new Error(`Erro ao buscar dados da API: ${errorMsg}`);
    }
}

