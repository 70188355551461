// Endpoint used to try to recover the password.
// The email is sent to the backend and the email is verified.
// The backend returns the email that was verified and the email that was not verified.
// Author: Vitor de Jesus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/133201922/AuthRoutes

import axios from 'axios';

export async function TryRecovery(data) {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/try-recovery`,
            data,
            { headers: { 'Content-Type': 'application/json' } }
        );
        return response.data;
    } catch (error) {
        if (error.response) {
            const status = error.response.status;
            if (status === 401) {
                return { 
                    status: 401,
                    message: 'Usuário ou senha inválidos.'
                };
            } else if (status === 429) {
                return {
                    status: 429,
                    message: 'Muitas solicitações. Tente novamente mais tarde.'
                };
            } else if (status === 404){
                return {
                    status: 404,
                    message: 'Esse email ou usuário é inválido.'
                };
            }
        }  
    }
}
