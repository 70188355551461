// This component is responsible for rendering the login form and handling the login process.
// It uses the AuthLogin function from the backend API to authenticate the user.
// It also uses the useLocation and useNavigate hooks from react-router-dom to handle the redirection after the login process.
// It uses the useState and useEffect hooks from React to manage the component's state and side effects.
// It uses the Box and Grid components from the Material-UI library to create the layout of the login form.
// It uses the Cookies library to manage cookies in the browser.
// Author: Vitor Jesus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/101089281/Login
// Prototype: https://www.figma.com/design/NqSBP9Hjy6KFGUik7FKy4F/UIUX-design?node-id=24-11&t=M6SZc7mIECmoXB0I-4

import React, { useState } from 'react';

import { useLocation } from 'react-router-dom';

import { Box, Grid } from '@mui/material';

import { AuthLogin } from '../../api/backend/Auth/AuthLogin';
import FormInitAnimate from '../../assets/ilustrations/FormInitAnimate.gif';
import FormLogin from './FormLogin';
import Logo from '../../assets/ilustrations/Logo';
import './styles.css';
import ThemeColors from '../../components/ThemeColors/ThemeColors';

// Render The Component
const LoginForm = () => {
  // State Variables
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const messageParams = location.state ? location.state.message : '';
  const [message, setMessage] = useState(messageParams ? messageParams : '');

  const [checked, setChecked] = React.useState(false);
  const FRONTURL_BASED = process.env.REACT_APP_FRONTEND_URL;

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleSubmit = async (event) => {

    event.preventDefault();

    if (!username || !password) {
      setMessage('Por favor, preencha todos os campos.');
      return;
    }

    setLoading(true);

    try {

      let loginInfo = { 'user': username, 'password': password };
      const trylogin = await AuthLogin(loginInfo);
      if (trylogin.Access_token) {
        // Limpa cookies indesejados
        document.cookie.split(";").forEach((cookie) => {
          const cookieName = cookie.split("=")[0].trim();
          if (cookieName !== '_chatwoot_session' && cookieName !== 'cw_conversation') {
            document.cookie = cookie.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/; SameSite=None; Secure");
          }
        });

        // Armazena o token no localStorage
        localStorage.setItem('jwt_token', trylogin.Access_token);

        // Verifica se o token foi salvo corretamente
        const savedToken = localStorage.getItem('jwt_token');
        console.log('Token salvo no localStorage:', savedToken);  // Exibe o token no console

        if (savedToken === trylogin.Access_token) {
          console.log('O token foi salvo corretamente!');
        } else {
          console.error('Erro ao salvar o token no localStorage.');
        }

        // Redireciona para o painel
        window.location.href = FRONTURL_BASED + '/painel';
      } else {
        setMessage(trylogin.message);
      }

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div style={{
        position: 'absolute',
        top: '20px',
        left: '20px',
        padding: '10px',
      }}>
        <Logo />
      </div>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: { xs: 'start', lg: 'center' }, height: '100vh', backgroundColor: ThemeColors.gray10, paddingTop: { xs: '80px', lg: '0' } }}>
        <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'stretch', justifyContent: 'center', maxWidth: { xl: 1200, lg: 1100 }, height: { xl: 460.200, lg: 422.0034 }, width: '100%', margin: '0 auto', padding: '0px' }}>
          <Grid item xs={12} md={4} sx={{ display: 'flex', maxHeight: '100%' }}>
            <Box sx={{ width: '100%', backgroundColor: 'transparent', boxShadow: 'none', display: { xs: 'flex', lg: 'block' }, justifyContent: { xs: 'center', lg: 'none' } }}>
              <Box component='img' src={FormInitAnimate} sx={{ width: { xs: '400px', lg: '100%' }, height: { xs: '456px', lg: '100%' }, objectFit: { xs: 'contain', md: 'cover' } }} />
            </Box>
          </Grid>
          <Grid item xs={12} md={4.5} sx={{ display: 'flex', justifyContent: 'left', alignItems: { xl: 'left', lg: 'end' }, maxHeight: '100%' }}>
            <FormLogin
              username={username}
              setUsername={setUsername}
              password={password}
              setPassword={setPassword}
              message={message}
              loading={loading}
              checked={checked}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            />
          </Grid>
        </Grid>
      </Box >
    </>
  );
};

export default LoginForm;