import React, { useState, useEffect } from "react";
import Sidebar from "./components/layouts/Sidebar";
import MainContent from "./components/layouts/MainContent";
import RoutesApp from "./routes";
import { CircularProgress } from "@mui/material";
import { BrowserRouter } from "react-router-dom"; // Importe o BrowserRouter
import Ativo1 from "./assets/Icons/svg/Ativo 1.svg";
import Ativo2 from "./assets/Icons/svg/Ativo 2.svg";
import Ativo3 from "./assets/Icons/svg/Ativo 3.svg";
import Ativo4 from "./assets/Icons/svg/Ativo 4.svg";
import Ativo5 from "./assets/Icons/svg/Ativo 5.svg";
import Ativo6 from "./assets/Icons/svg/Ativo 6.svg";
import Ativo7 from "./assets/Icons/svg/Ativo 7.svg";
import Ativo8 from "./assets/Icons/svg/Ativo 8.svg";
import Ativo9 from "./assets/Icons/svg/Ativo 9.svg";
import Ativo10 from "./assets/Icons/svg/Ativo 10.svg";
import Ativo11 from "./assets/Icons/svg/Ativo 11.svg";
import Ativo12 from "./assets/Icons/svg/Ativo 12.svg";
import Ativo13 from "./assets/Icons/svg/Ativo 13.svg";
import Ativo14 from "./assets/Icons/svg/Ativo 14.svg";
import Ativo15 from "./assets/Icons/svg/Ativo 15.svg";
import Ativo16 from "./assets/Icons/svg/Ativo 16.svg";
import Ativo17 from "./assets/Icons/svg/Ativo 17.svg";
import Ativo18 from "./assets/Icons/svg/Ativo 18.svg";
import Ativo19 from "./assets/Icons/svg/Ativo 19.svg";
import Ativo20 from "./assets/Icons/svg/Ativo 20.svg";
import Ativo21 from "./assets/Icons/svg/Ativo 21.svg";
import Ativo22 from "./assets/Icons/svg/Ativo 22.svg";
import Ativo23 from "./assets/Icons/svg/Ativo 23.svg";
import Ativo24 from "./assets/Icons/svg/Ativo 24.svg";
import Ativo25 from "./assets/Icons/svg/Ativo 25.svg";
import Ativo26 from "./assets/Icons/svg/Ativo 26.svg";
import Ativo27 from "./assets/Icons/svg/Ativo 27.svg";
import Ativo28 from "./assets/Icons/svg/Ativo 28.svg";
import Ativo29 from "./assets/Icons/svg/Ativo 29.svg";
import Ativo30 from "./assets/Icons/svg/Ativo 30.svg";
import Styles from "./components/Styles/Styles";
import { NavigationControlProvider } from "./NavigationControlContext";
import { ThemeProvider } from "@mui/material/styles";
import CreateTheme from "./components/ThemeColors/CreateTheme";
import { Toaster } from "react-hot-toast";
import { useAuth } from "./context/AuthContext";

import axios from 'axios';


axios.interceptors.response.use(
  (response) => {
    // Se a resposta for bem-sucedida, apenas retornamos os dados normalmente
    return response;
  },
  (error) => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {

      localStorage.removeItem('jwt_token');
      
     window.location.href = '/login';
    }
    
    // Caso o erro não seja 401 ou 403, rejeita o erro normalmente
    return Promise.reject(error);
  }
);

const icons = [
  Ativo1,
  Ativo2,
  Ativo3,
  Ativo4,
  Ativo5,
  Ativo6,
  Ativo7,
  Ativo8,
  Ativo9,
  Ativo10,
  Ativo11,
  Ativo12,
  Ativo13,
  Ativo14,
  Ativo15,
  Ativo16,
  Ativo17,
  Ativo18,
  Ativo19,
  Ativo20,
  Ativo21,
  Ativo22,
  Ativo23,
  Ativo24,
  Ativo25,
  Ativo26,
  Ativo27,
  Ativo28,
  Ativo29,
  Ativo30,
];

const AppLayout = ({ children }) => {
  const { userAuthenticated } = useAuth();

  return (
    <>
      {userAuthenticated && <Sidebar />}
      {(userAuthenticated && <MainContent>{children}</MainContent>) || (
        <>{children}</>
      )}
    </>
  );
};

function App() {
  const { userAuthenticated } = useAuth();
  const [loadingAuthentication, setLoadingAuthentication] = useState(true);

  // Selecione o elemento pelo nome da classe
  const elemento = document.querySelector(".woot-widget-bubble");

  var tooltip = document.createElement("div");
  tooltip.style.position = "relative";
  tooltip.style.top = "50%"; // Adjusts the tooltip vertically to the center of the element
  tooltip.style.transform = "translateY(-50%)"; // Ensures the tooltip is centered on the element
  tooltip.style.left = "90%"; // Positions the tooltip to the right of the element
  tooltip.style.backgroundColor = "lightgray";
  tooltip.style.padding = "5px";
  tooltip.style.borderRadius = "5px";
  tooltip.style.display = "none"; // Hides the tooltip initially
  tooltip.textContent = "Fale com a gente!"; // Sets the tooltip text

  // Verifique se o elemento existe
  if (elemento) {
    // Adicione um event listener para o evento de hover
    elemento.addEventListener("mouseover", () => {
      // Escolha um ícone aleatório da lista de ícones
      const randomIcon = icons[Math.floor(Math.random() * icons.length)];
      // Altere a URL da imagem de fundo do pseudo-elemento
      elemento.style.setProperty(
        "--background-image-url",
        `url("${randomIcon}")`
      );
    });

    // Remova a URL da imagem de fundo quando o mouse sair do elemento
    elemento.addEventListener(
      "mouseout",
      () => {
        elemento.style.removeProperty("--background-image-url");
        // Oculte o tooltip padrão
        tooltip.style.display = "none";
      }

      // Adicione o tooltip FORA do evento de mouseover
    );
  }

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length) {
          const brandingLink = document.querySelector(
            ".branding--link[data-v-448a7326]"
          );
          if (brandingLink) {
            brandingLink.style.display = "none";
          }
        }
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, []);


  useEffect(() => {
    setTimeout(() => {
      setLoadingAuthentication(false);
    }, 1000);
  }, []);

  if (loadingAuthentication) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress style={{ color: "rgb(52, 152, 219)" }} />
      </div>
    );
  }

  if (
    (!userAuthenticated && window.location.pathname === "/styles") ||
    (!userAuthenticated && window.location.pathname === "/buttons") ||
    (!userAuthenticated && window.location.pathname === "/icons") ||
    (!userAuthenticated && window.location.pathname === "/typography") ||
    (!userAuthenticated && window.location.pathname === "/iconography") ||
    (!userAuthenticated && window.location.pathname === "/emojis") ||
    (!userAuthenticated && window.location.pathname === "/labels") ||
    (!userAuthenticated && window.location.pathname === "/tables") ||
    (!userAuthenticated && window.location.pathname === "/ilustrations") ||
    (!userAuthenticated && window.location.pathname === "/alerts")
  ) {
    return (
      <ThemeProvider theme={CreateTheme}>
        <BrowserRouter>
          <NavigationControlProvider>
            <div className="flexContent" style={{ flex: 1 }}>
              <MainContent>
                <Styles />
              </MainContent>
            </div>
          </NavigationControlProvider>
        </BrowserRouter>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={CreateTheme}>
      <Toaster
        reverseOrder
        toastOptions={{
          position: "bottom-left",
          style: {
            maxWidth: "100vw",
          },
          duration: 5000,
        }}
      />
      <BrowserRouter>
        <AppLayout>
          <RoutesApp />
        </AppLayout>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
