// Desc: Page to display the list of spreadsheets

// React imports
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Material-UI imports
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";

// Fuctions and Components to project
import DynamicTable from "../../components/Tables/DynamicTable";
import DeleteIcon from '../../assets/Icons/components/Delete';
import EmptyTemplate from "../../assets/ilustrations/EmptyTemplates.png"
import HeaderPage from '../../components/HeaderPage/HeaderPage'
import PlusIcon from '../../assets/Icons/components/Plus';
import StatusLabel from '../../components/StatusLabel/StatusLabel';
import StatusIcon from '../../components/StatusLabel/StatusIcon';
import ThemeColors from "../../components/ThemeColors/ThemeColors";
import Titles from "../../components/Titles/Titles";
import ViewStreamIcon from '@mui/icons-material/ViewStream';

import { DeleteListagens } from "../../api/backend/Listagens/DeleteListagens";
import { GetListagens } from "../../api/backend/Listagens/GetListagens";
import { isAuthenticated } from '../../authMiddleware';
import Loading from "../../components/Loading/Loading";
import { truncateText } from "../../utils/utils";

function Sheets() {

  // States to contro authetication and user info
  const { authenticated, userType, uuidClient } = isAuthenticated();
  const [userInfo, setUserInfo] = useState({});
  let navigate = useNavigate();

  // States to page control
  const [loading, setLoading] = useState(true);
  const [videoUrl, setVideoUrl] = useState("./listagens.mp4");
  const [openModalHelp, setOpenModalHelp] = useState(false);
  const [listagens, setListagens] = useState([]);

  useEffect(() => {
    if (authenticated) {
      const { decryptedData } = isAuthenticated();
      setUserInfo(decryptedData);
    }
  }, []);

  useEffect(() => {
    if (userInfo['UUID']) {
      fetchData();
    }
  }, [userInfo])

  const fetchData = async () => {

    try {
   
      const resultJson = await GetListagens();

      console.log(resultJson)
     
      const listagens = resultJson.map((listagem) => {
        return {
          id: listagem[0],
          icon: listagem[7],
          title: listagem[1],
          desc: truncateText(listagem[2], 33),
          type: listagem[7],
          link: listagem[5]
        };
      }
      );

      console.log(listagens);

      setListagens(listagens);
      setLoading(false);

    } catch (error) {
      console.error("Erro ao obter dados da API:", error);
      setLoading(false);
    }
  }

  const handleDelete = async (item) => {
    try {


      const data = { 'listagem_id': item.id };

      const resultJson = await DeleteListagens(data);

      if (resultJson['message'] === 'Listagem deletada com sucesso') {
        fetchData();
      }

    } catch (error) {
      console.error("Erro ao obter dados da API:", error);
    }
  }

  const handlePageAdd = () => {
    navigate("/planilhas/adicionar");
  };

  if(loading) {
    return <Loading open={true} />
  }

  return (
    <Fragment>
      <Fade in={!loading} timeout={1000}>
        <Box width={"100%"} height={"100vh"}>
          <HeaderPage title="Planilhas" />
          {
            listagens && listagens.length > 0 && (
              <DynamicTable
                title="Planilhas"
                data={listagens}
                columns={[
                  { header: '', field: 'icon', render: (icon) => <StatusIcon status={icon} /> },
                  { header: 'Título', field: 'title' },
                  { header: 'Descrição', field: 'desc' },
                  { header: 'Tipo', field: 'type' , render: (type) => <StatusLabel status={type}usingOnTable /> }
                ]}
                actions={[
                  {
                    icon: <ViewStreamIcon style={{ color: ThemeColors.gray30, fontSize: '14px' }}
                    />, tooltip: 'Visualizar', action: (item) => window.open(item.link, '_blank'), isVisible: (item) =>  item && item.type == 'Google planilhas'
                  },
                  {
                    icon: <DeleteIcon style={{ color: ThemeColors.gray30, fontSize: '14px' }}
                    />, tooltip: 'Excluir', action: (item) => handleDelete(item), isVisible: (item) =>  item && item.type != 'API'
                  }
                ]}
                button={{ icon: <PlusIcon />, tooltip: 'Adicionar Planilha ', action: h => handlePageAdd() }}
                activeSearch={true}
                titleSearch={'Pesquisar Planilha'}
                titleLabel={'Planilhas por página'}
              />
            )
            || (
              <Box display={"flex"} sx={{ justifyContent: "center", alignItems: "center", width: "100%", height: "90%" }}>
                <Box>
                  <img src={EmptyTemplate} alt="Você não tem modelos" width={"494px"} height={"330px"} />
                  <Box display={"flex"} sx={{ flexDirection: "column", gap: "10px", justifyContent: "center", textAlign: "center" }}>
                    <Titles bold type={"H3"} children={"Parece que você não tem planilhas :("} color={"#8033F4"} />
                    <Titles type={"P1"} children={"Importe uma agora mesmo, é fácil e rápido"} />

                    <Button
                      variant='contained'
                      style={{ color: '#fff', width: '100%', padding: '10px', marginTop: "10px" }}
                      onClick={() => handlePageAdd()}
                    >
                      Importar Planilha
                    </Button>
                  </Box>
                </Box>
              </Box>
            )
          }
        </Box>
      </Fade>
  
    </Fragment>
  );
}

export default Sheets;