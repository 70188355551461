// This endpoint is used to save the channel data in the database.
// The response is an object with a message.
// Author: Vitor de Jesus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/136806402/Channels

import axios from 'axios';

export async function ChannelCheck({code, name}) {
    try {
        
        const token = localStorage.getItem('jwt_token');

        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/save/whatsapp`, 
            {
                code,
                name
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}` 
                },
            }
                
        );
        
        const responseData = response.data;
        console.log('responseData', responseData);

        return responseData;
    } catch (error) {
        console.error('Erro ao buscar dados da API:', error.response ? error.response.data : error.message);
        throw new Error('Erro ao buscar dados da API');
    }
    
}