// Endpoint used to authenticate the user and return a token to access the application.
// The token is stored in cookies and is used to authenticate the user in the application.
// The token is valid for 1 hour and is refreshed every time the user accesses the application.
// The token is invalidated when the user logs out or when the token expires.
// The token is used in all requests to the backend to return the user's data and to perform actions in the application.
// Author: Vitor de Jesus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/133201922/AuthRoutes

import axios from 'axios';

export async function AuthLogin(data) {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/auth/login`, 
            data,
            { headers: { 'Content-Type': 'application/json' } }
        );
        return response.data;
    } catch (error) {
        if (error.response) {
            const status = error.response.status;
            if (status === 401) {
                return { 
                    status: 401,
                    message: 'Usuário ou senha inválidos.'
                };
            } else if (status === 429) {
                return {
                    status: 429,
                    message: 'Muitas solicitações. Tente novamente mais tarde.'
                };
            } else if (status === 404) {
                return {
                    status: 404,
                    message: 'Esse email ou usuário é inválido.'
                };
            }
        } else {
            return { 
                status: 500,
                message: 'Erro inesperado. Tente novamente mais tarde.' 
            };
        }
    }
}
