// This endpoint is used to update the user's notification status.
// The data is fetched from the backend API using an axios POST request.
// Author: Vitor de Jesus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/135593992/Dashboard

import axios from 'axios';

export async function ReadN(data) {
    try {
        
        const token = localStorage.getItem('jwt_token');

        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/read-notification`, 
            data,
            { 
                headers: { 
                    Authorization: `Bearer ${token}`  // Envia o token no cabeçalho Authorization
                },
            }
        );
        
        return response.data;

    } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
        throw new Error('Erro ao buscar dados da API');
    }
}
