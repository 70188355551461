// This endpoint is used to start a channel in the database.
// The response is an object with a message.
// Author: Vitor de Jesus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/136806402/Channels

import axios from 'axios';

export async function ChannelConnect (Code) {   
    try {

        const token = localStorage.getItem('jwt_token');

        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/start/whatsapp`,
            { code: Code },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`  
                },
            }
        );
        
        const responseData = response.data;

        if (response.status === 200 && responseData.message === 'Configuração do canal definida com sucesso') {

            responseData.status = 'OK';

        } else {

            responseData.status = 'ERROR';
        
        }

        return responseData;
    } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
        throw new Error('Erro ao buscar dados da API');
    }
}