// This component renders the Dashboard page, which is the main page of the application.
// It displays the user's information, notifications, and performance indicators.
// The user's information is displayed on the left side of the page, while the notifications and performance indicators are displayed on the right side.
// The notifications are displayed in a list format, and the user can view all notifications or only the new ones.
// The performance indicators are displayed in a chart format, showing the user's performance over time.
// Author: Vitor Jesus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/101416971/Dashboard
// Prototype: https://www.figma.com/design/NqSBP9Hjy6KFGUik7FKy4F/UIUX-design?node-id=986-58&t=mEOidrARSPxeHbDw-4

import React, { Fragment, useEffect, useState } from 'react';

import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Fade from '@mui/material/Fade';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import Pagination from '@mui/material/Pagination';

import AlternativeAvatar from '../../assets/images/avatar-padrao.png';
import Badge from '../../assets/badges/image.png';
import ConfigIcon from '../../assets/images/config.png';
import { DashboardData } from '../../api/backend/Dashboard/DashboardData';
import { isAuthenticated } from '../../authMiddleware';
import Loading from '../../components/Loading/Loading';
import Notify from '../../assets/ilustrations/Notify.gif'
import PerformanceIndicator from '../../components/Performance/PerformanceIndicator';
import { ReactComponent as TagIcon } from '../../assets/Icons/svg/TagIcon.svg';
import { ReactComponent as CampaignIcon } from '../../assets/Icons/svg/CampaignIcon.svg';
import { ReadN } from '../../api/backend/Dashboard/ReadN';
import StatusIcon from '../../components/StatusLabel/StatusIcon';
import ThemeColors from '../../components/ThemeColors/ThemeColors';
import Titles from '../../components/Titles/Titles';

function DashboardPage() {

    const { authenticated } = isAuthenticated();
    const [userInfo, setUserInfo] = useState({});

    const [dataFromAPI, setDataFromAPI] = useState(null);
    const [loading, setLoading] = useState(true);
    const [userAvatar, setUserAvatar] = useState('');

    const colorMapping = {
        1: {
            iconColor: '#CD4747',
            backgroundColor: ThemeColors.red01,
            textColor: '#4E1B1D',
        },
        2: {
            iconColor: '#DEA73F',
            backgroundColor: ThemeColors.yellow01,
            textColor: '#8D4D1B',
        },
        3: {
            iconColor: '#8142FC',
            backgroundColor: '#DDD4FF',
            textColor: '#4F189A',
        },
        4: {
            iconColor: '#4C8435',
            backgroundColor: '#D3EAC8',
            textColor: '#345427',
        },
        5: {
            iconColor: '#848484',
            backgroundColor: '#E5E5E5',
            textColor: '#303035',
        },
    };

    const [AllNotifications, setAllNotifications] = useState([]);
    const [newNotification, setNewNotification] = useState([]);
    const [showAllNotifications, setShowAllNotifications] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const notificationsPerPage = 4;

    useEffect(() => {
        if (showAllNotifications) {
            setNewNotification(AllNotifications);
        }
    }, [showAllNotifications]);

    const updateNotificationStatus = async (notificationId) => {
        try {
            const info = { 'notification_id': notificationId };
            const jsonData = await ReadN(info);
    
            if (jsonData['message'] === 'Notification read successfully') {
                setAllNotifications((prev) => prev.filter((notification) => notification.notification_id !== notificationId));
                setNewNotification((prev) => prev.filter((notification) => notification.notification_id !== notificationId));
            }
        } catch (error) {
            console.error('Erro ao atualizar o status da notificação:', error);
        }
    };
    
    useEffect(() => {
        if (authenticated) {
            const { decryptedData } = isAuthenticated();
            setUserInfo(decryptedData);
        }
    }, []);

    useEffect(() => {
        if (userInfo['UUID']) {
            const userHash = userInfo['UUID'] + '.png';
            fetchUserAvatar(userHash);
        }
    }, [userInfo]);

    const [mockup, setMockupData] = useState({
        'labels': ['Enviadas', 'Visualizadas', 'Recebidas', 'Geral', 'Erro'],
        'datasets': {
            'Enviadas': {
                'quantidade': 0,
                'icone': SendOutlinedIcon
            },
            'Visualizadas': {
                'quantidade': 0,
                'icone': TaskAltOutlinedIcon
            },

            'Recebidas': {
                'quantidade': 0,
                'icone': AccessTimeOutlinedIcon
            },
            'Erro': {
                'quantidade': 0,
                'icone': ErrorOutlineOutlinedIcon
            },
            'Geral': {
                'quantidade': 0,
                'icone': FactCheckOutlinedIcon
            },
        }
    });

    useEffect(() => {
        async function fetchDataWrapper() {
            if (userInfo && userInfo['UUID']) {
                
                try {
                    
                    const data = await DashboardData();

                    const jsonData = data.data;
        
                    const mappedNotifications = jsonData['notifications'].map((notification) => ({
                        notification_id: notification.id,
                        text: notification.text,
                        ...colorMapping[notification.colormapping],
                        date: notification.timestamp.split(' ')[0],
                    }));

                    setAllNotifications(mappedNotifications);

                    const mappedNewNotifications = jsonData['notifications']
                        .filter((notification) => !notification.read)
                        .map((notification) => ({
                            notification_id: notification.id,
                            text: notification.text,
                            ...colorMapping[notification.colormapping],
                            date: notification.timestamp.split(' ')[0],
                        }));

                    setNewNotification(mappedNewNotifications);

                    setDataFromAPI(jsonData);
                    setMockupData(mockup);

                } catch (error) {

                } finally {
                    setLoading(false);
                }
            }
        }
        fetchDataWrapper();
    }, [userInfo]);

    useEffect(() => {
        if (dataFromAPI) {

            try {
                const { datasets = [] } = dataFromAPI;

                const monthlyData = datasets.reduce((acc, curr) => {
                    const monthYear = curr['month-year'];
                    if (!acc[monthYear]) {
                        acc[monthYear] = { send: 0, read: 0, error: 0, total: 0, received: 0 };
                    }
                    acc[monthYear].send += curr.send;
                    acc[monthYear].read += curr.read;
                    acc[monthYear].error += curr.error;
                    acc[monthYear].total += curr.total;
                    acc[monthYear].received += curr.received;
                    return acc;
                }, {});

                const labels = Object.keys(monthlyData);
                const readTotals = labels.map(monthYear => monthlyData[monthYear].read);
                const errorTotals = labels.map(monthYear => monthlyData[monthYear].error);
                const totalTotals = labels.map(monthYear => monthlyData[monthYear].total);
                const sentAndReadTotals = labels.map(monthYear => monthlyData[monthYear].send + monthlyData[monthYear].read);
                const receivedTotals = labels.map(monthYear => monthlyData[monthYear].received);

                setMockupData(prevMockup => ({
                    ...prevMockup,
                    labels: labels,
                    datasets: {
                        ...prevMockup.datasets,
                        Enviadas: {
                            ...prevMockup.datasets['Enviadas'],
                            quantidade: sentAndReadTotals
                        },
                        Visualizadas: {
                            ...prevMockup.datasets['Visualizadas'],
                            quantidade: readTotals
                        },
                        Erro: {
                            ...prevMockup.datasets['Erro'],
                            quantidade: errorTotals
                        },
                        Geral: {
                            ...prevMockup.datasets['Geral'],
                            quantidade: totalTotals
                        },
                        Recebidas: {
                            ...prevMockup.datasets['Recebidas'],
                            quantidade: receivedTotals
                        }
                    }
                }));

            }
            catch (error) {
                console.error('Erro ao atualizar o mockup:', error);
            } finally {
                setLoading(false);
            }
        }
    }, [dataFromAPI]);

    const fetchUserAvatar = async (userHash) => {
        try {
            const imageUrl = 'https://files123zap.s3.amazonaws.com/avatar_users/' + userHash;
            const img = new Image();
            img.onload = () => {
                setUserAvatar(imageUrl);
            };
            img.onerror = () => {
                console.error('Erro ao carregar a imagem:', imageUrl);
                setUserAvatar(AlternativeAvatar);
            };
            img.src = imageUrl;
        } catch (error) {
            console.error('Erro ao buscar o avatar do usuário:', error);
            setUserAvatar(AlternativeAvatar);
        }
    };

    const handleChangePage = (event, value) => {
        setCurrentPage(value);
    };

    const indexOfLastNotification = currentPage * notificationsPerPage;
    const indexOfFirstNotification = indexOfLastNotification - notificationsPerPage;
    const currentNotifications = !showAllNotifications
        ? newNotification.slice(indexOfFirstNotification, indexOfLastNotification)
        : AllNotifications.slice(indexOfFirstNotification, indexOfLastNotification);

    if (loading) {
        <Loading open={loading} />
    }

    return (
        <Fragment>
            {!loading && (
                <Fade in={!loading}>
                    <Box display={'flex'} flexDirection={'column'} minHeight={'100vh'} width={"100%"}>
                        <Box display={'flex'} gap={3} padding={3} paddingLeft={'20px'} paddingRight={'20px'} paddingTop={'20px'} sx={{ flexDirection: { xl: 'row', lg: 'row', sm: 'column' } }}>
                            <Box flex={1} display={'flex'} flexDirection={'column'} borderRadius={'12px'} overflow={'hidden'} sx={{ minHeight: { xl: '312px', lg: '312px', sm: '290px' } }}>
                                <Box
                                    sx={{
                                        background: '#8142FC',
                                        flex: 1,
                                        display: 'flex',
                                        padding: '30px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        paddingBottom: '30px'
                                    }}
                                >
                                    <Box display={'flex'} justifyContent={'space-between'} minWidth={'100%'}>
                                        <Box display={'flex'} gap={'15px'}>
                                            <img src={userAvatar} width={'71px'} height={'71px'} alt='Logo' style={{ borderRadius: '12px' }} />
                                            <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} maxHeight={'71px'}>
                                                <Titles children={`Olá, ${userInfo['NAME']}`} type={'H2'} color={'#F5F3FF'} />
                                                <Titles children={'O que iremos fazer hoje?'} type={'P1'} color={'#F5F3FF'} />
                                            </Box>
                                        </Box>
                                        <Box
                                            component={'img'}
                                            sx={{
                                                cursor: 'pointer',
                                                transition: '.3s',
                                                width: '19.17px',
                                                height: '18.84px',
                                                '&:hover': {
                                                    scale: '1.1'
                                                }
                                            }}
                                            src={ConfigIcon}
                                            alt='Configuration'
                                        />
                                    </Box>
                                    <Box display={'flex'} justifyContent={'space-between'} gap={'15px'}>
                                        <Box
                                            sx={{
                                                background: '#8033F4',
                                                color: '#EDE8FF',
                                                borderRadius: '6px',
                                                height: '33px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '7px',
                                                flex: '1',
                                                padding: '0 0 0 10px',
                                            }}
                                        >
                                            <TagIcon width={'18px'} height={'18px'} fill={'#EDE8FF'} />
                                            <Titles color={'#EDE8FF'} children={`Canais abertos`} styles={{ fontSize: { xl: '16px', lg: '12px', md: '16px', sm: '16px' } }} />
                                            <Titles color={'#EDE8FF'} children={`${dataFromAPI && dataFromAPI['total_channels'] ? dataFromAPI['total_channels'].toString().padStart(2, '0') : '0'}`} styles={{ fontSize: { xl: '16px', lg: '12px', md: '16px', sm: '16px' } }} />
                                        </Box>
                                        <Box
                                            sx={{
                                                background: '#8033F4',
                                                color: '#EDE8FF',
                                                borderRadius: '6px',
                                                height: '33px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '7px',
                                                flex: '1',
                                                padding: '0 0 0 10px'
                                            }}
                                        >
                                            <CampaignIcon width={'18px'} height={'18px'} fill={'#EDE8FF'} />
                                            <Titles color={'#EDE8FF'} children={`Campanhas ativas`} styles={{ fontSize: { xl: '16px', lg: '12px', md: '16px', sm: '16px' } }} />
                                            <Titles color={'#EDE8FF'} children={`${dataFromAPI && dataFromAPI['total_campaigns'] ? dataFromAPI['total_campaigns'].toString().padStart(2, '0') : '0'}`} styles={{ fontSize: { xl: '16px', lg: '12px', md: '16px', sm: '16px' } }} />

                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        background: '#8033F4',
                                        height: '100px',
                                        padding: '0 30px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Box display={'flex'} gap={'10px'}>
                                        <Box
                                            component={'img'}
                                            sx={{
                                                width: '56px',
                                                height: '56px',
                                            }}
                                            alt={'Badge'}
                                            src={Badge}
                                        />
                                        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} >
                                            <Titles color={'#F5F3FF'} children={'Plano atual'} type={'P2'} />
                                            <Titles
                                                color={'#F5F3FF'}
                                                children={
                                                    dataFromAPI &&
                                                        dataFromAPI['plan'] &&
                                                        dataFromAPI['plan']['plan_name']
                                                        ? dataFromAPI['plan']['plan_name']
                                                        : 'Plano não encontrado'
                                                }
                                                styles={{ fontSize: { xl: '24px', lg: '16px', sm: '20px' } }}
                                            />
                                        </Box>
                                    </Box>
                                    <Button
                                        sx={{
                                            background: '#5F1BBC',
                                            borderRadius: '6px',
                                            padding: '12px, 24px, 12px, 24px',
                                            color: '#DDD4FF',
                                            fontSize: '16px',
                                            transition: '.3s',
                                            '&:hover': {
                                                scale: '1.05',
                                                background: '#5F1BBC',
                                            }
                                        }}
                                    >
                                        Atualizar Plano
                                    </Button>
                                </Box>
                            </Box>
                            <Box
                                flex={1}
                                borderRadius={'12px'}
                                border={'1px solid #D9D9DC'}
                                padding={'30px'}
                                sx={{
                                    background: '#fff',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    gap: '25px',
                                    minHeight: { xl: '312px', lg: '312px', sm: '290px' }
                                }}
                            >
                                {(newNotification && newNotification.length > 0) || showAllNotifications ? (
                                    <Titles children={'Notificações'} color={'#8033F4'} bold type={'H3'} />
                                ) : null}
                                <Box display="flex" flexDirection="column" width="100%" height="90%" alignItems="center">
                                    {newNotification && newNotification.length > 0 && showAllNotifications === false ? (
                                        currentNotifications.map((notification) => (
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                padding="0 10px"
                                                key={notification.notification_id}
                                                sx={{
                                                    background: notification.backgroundColor,
                                                    height: '32px',
                                                    borderRadius: '6px',
                                                    width: '100%',
                                                    marginBottom: '10px'
                                                }}
                                            >
                                                <Box display="flex" gap="7px" alignItems="center" flex="1">
                                                    <StatusIcon status="Alert" color={notification.iconColor} width="12px" height="12px" />
                                                    <Titles children={notification.date + ' - ' + notification.text} color={notification.textColor} />
                                                </Box>
                                                <Box
                                                    sx={{ cursor: 'pointer' }}
                                                    onClick={() => updateNotificationStatus(notification.notification_id)}
                                                >
                                                    <StatusIcon status="Check" color={notification.iconColor} width="12px" height="12px" />
                                                </Box>
                                            </Box>
                                        ))
                                    ) : (
                                        <>
                                            {AllNotifications && AllNotifications.length > 0 && !showAllNotifications ? (
                                                <Box display="flex" sx={{ justifyContent: "center", alignItems: "center", width: "100%", height: "90%" }}>
                                                    <Box display="flex" sx={{ alignItems: "center", justifyContent: "space-between", width: "80%" }}>
                                                        <Box display={"flex"} sx={{ flexDirection: "column", gap: "10px", textAlign: "left" }}>
                                                            <Titles bold type={"H3"} children={"Não há novas notificações"} color={"#52525B"} />
                                                            <Titles type={"P1"} children={"Deseja visualizar as notificações antigas?"} color={"#52525B"} />
                                                            <Box
                                                                component="a"
                                                                href="#"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setShowAllNotifications(true);
                                                                }}
                                                                sx={{ textDecoration: "none" }}
                                                            >
                                                                <Titles type={"P1"} children={"Clique aqui"} color={"#8142FC"} />
                                                            </Box>
                                                        </Box>
                                                        <img src={Notify} alt="Você não tem modelos" style={{ width: "30%", height: "30%" }} />
                                                    </Box>
                                                </Box>
                                            ) : (
                                                showAllNotifications ? (
                                                    currentNotifications.map((notification) => (
                                                        <Box
                                                            display="flex"
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                            padding="0 10px"
                                                            key={notification.notification_id}
                                                            sx={{
                                                                background: notification.backgroundColor,
                                                                height: '32px',
                                                                borderRadius: '6px',
                                                                width: '100%',
                                                                marginBottom: '10px'
                                                            }}
                                                        >
                                                            <Box display="flex" gap="7px" alignItems="center" flex="1">
                                                                <StatusIcon status="Alert" color={notification.iconColor} width="12px" height="12px" />
                                                                <Titles children={notification.date + ' - ' + notification.text} color={notification.textColor} />
                                                            </Box>
                                                        </Box>
                                                    ))
                                                ) : (
                                                    <Box display="flex" sx={{ justifyContent: "center", alignItems: "center", width: "100%", height: "90%" }}>
                                                        <Box display="flex" sx={{ alignItems: "center", justifyContent: "space-between", width: "80%" }}>
                                                            <Box display={"flex"} sx={{ flexDirection: "column", gap: "10px", textAlign: "left" }}>
                                                                <Titles bold type={"H3"} children={"Você ainda não possui notificações"} color={"#52525B"} />
                                                                <Titles type={"P1"} children={"Fique atento às novidades!"} color={"#52525B"} />
                                                            </Box>
                                                            <img src={Notify} alt="Você não tem modelos" style={{ width: "30%", height: "30%" }} />
                                                        </Box>
                                                    </Box>
                                                )
                                            )}
                                        </>
                                    )}
                                    {AllNotifications.length > notificationsPerPage && (
                                        <Box
                                            display="flex"
                                            justifyContent="flex-end"
                                            sx={{ mt: 'auto', width: '100%' }}
                                        >
                                            <Pagination
                                                count={Math.ceil(newNotification.length / notificationsPerPage)}
                                                page={currentPage}
                                                onChange={handleChangePage}
                                                variant='outlined'
                                                shape="rounded"
                                                sx={{ alignSelf: 'flex-end' }}
                                            />
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                        <PerformanceIndicator mockup={mockup} dataFromAPI={dataFromAPI} />
                    </Box>
                </Fade>
            )}
            <Loading open={loading} />
        </Fragment>
    );
}

export default DashboardPage;