import React, { useState, useEffect } from 'react';
import { Grid, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Button, Select, MenuItem, Typography } from '@mui/material';

function FieldSelector({ data = [], setData, handleSubmit }) {
    const [values, setValues] = useState({
        'Chave PIX Destinatário': '',
        'Nome Completo': '',
        'Data de Transferência': '',
        'Valor da Transferência': '',
    });

    const handleChangeSelect = (value, field) => {
        console.log('value', value);
        console.log('field', field);
        setValues({
            ...values,
            [field]: value
        });
    };

    useEffect(() => {
        console.log('values', values);
    }, [values]);

    return (
        <Grid item xs={12} md={12} style={{ background: '#FFF', borderRadius: '12px' }}>

            <TableContainer style={{ marginBottom: '10px', padding: '0px' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* Linha Chave PIX Destinatário */}
                        <TableRow>
                            <TableCell>Chave PIX Destinatário</TableCell>
                            <TableCell>
                                <Select
                                    value={values['Chave PIX Destinatário']}
                                    onChange={(e) => handleChangeSelect(e.target.value, 'Chave PIX Destinatário')}
                                    fullWidth
                                    size="small"
                                    sx={{ background: '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
                                >
                                    <MenuItem value="">Selecione</MenuItem>
                                    {data.map((item) => (
                                        <MenuItem key={item.field} value={item.field}>
                                            {item.field}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </TableCell>
                        </TableRow>

                        {/* Linha Nome Completo */}
                        <TableRow>
                            <TableCell>Nome Completo</TableCell>
                            <TableCell>
                                <Select
                                    value={values['Nome Completo']}
                                    onChange={(e) => handleChangeSelect(e.target.value, 'Nome Completo')}
                                    fullWidth
                                    size="small"
                                    sx={{ background: '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
                                >
                                    <MenuItem value="">Selecione</MenuItem>
                                    {data.map((item) => (
                                        <MenuItem key={item.field} value={item.field}>
                                            {item.field}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </TableCell>
                        </TableRow>

                        {/* Linha Data de Transferência */}
                        <TableRow>
                            <TableCell>Data de Transferência</TableCell>
                            <TableCell>
                                <Select
                                    value={values['Data de Transferência']}
                                    onChange={(e) => handleChangeSelect(e.target.value, 'Data de Transferência')}
                                    fullWidth
                                    size="small"
                                    sx={{ background: '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
                                >
                                    <MenuItem value="">Selecione</MenuItem>
                                    {data.map((item) => (
                                        <MenuItem key={item.field} value={item.field}>
                                            {item.field}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </TableCell>
                        </TableRow>

                        {/* Linha Valor da Transferência */}
                        <TableRow>
                            <TableCell>Valor da Transferência</TableCell>
                            <TableCell>
                                <Select
                                    value={values['Valor da Transferência']}
                                    onChange={(e) => handleChangeSelect(e.target.value, 'Valor da Transferência')}
                                    fullWidth
                                    size="small"
                                    sx={{ background: '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
                                >
                        
                                    {data.map((item) => (
                                        <MenuItem key={item.field} value={item.field}>
                                            {item.field}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth>
                Enviar
            </Button>
        </Grid>
    );
}

export default FieldSelector;
