// This page is responsible for adding credit to the user's account.
// The user can choose the amount to be added and the payment method.
// The user can choose between PIX, credit card and billet.
// The payment method is disabled, only PIX is available.
// When the user realizes the payment, the system will process the payment and the user will receive a confirmation message.
// Author: Vitor de Jesus

// Prototype: https://www.figma.com/design/NqSBP9Hjy6KFGUik7FKy4F/UIUX-design?node-id=2251-246&t=vGcPbn62U5gjfpZ4-4

import axios from 'axios';
import { io } from 'socket.io-client';
import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { FormControl } from '@mui/material';
import Footer from '../../components/Footer/Footer';
import Grid from '@mui/material/Grid';
import HelpAdd from "../../components/Cards/HelpAdd";
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import Loading from '../../components/Loading/Loading';
import { MenuItem } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { Select } from '@mui/material';

import Confirmed from '../../assets/ilustrations/Confirmed.png';
import { isAuthenticated } from '../../authMiddleware';
import QRCODE2 from '../../assets/images/QRCODE2.png';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const CurrencyInput = (props) => {

    return (
        <NumericFormat
            {...props}
            thousandSeparator="."
            decimalSeparator=","
            prefix="R$ "
            customInput={TextField}
            inputProps={{
                sx: {
                    fontSize: '58px',
                    textAlign: 'center',
                    padding: '16px',
                }
            }}
        />
    );
};

function AddCredit() {

    const { authenticated } = isAuthenticated();
    const [userInfo, setUserInfo] = useState({});

    const [value, setValue] = useState();
    const [copied, setCopied] = useState(false);
    const [codeGenerated, setCodeGenerated] = useState(false);
    const [qrcode, setQrcode] = useState(QRCODE2);
    const [showLoading, setShowLoading] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [pixCode, setPixCode] = useState('');
    const [paymentId, setPaymentId] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');
    const [token, setToken] = useState(null);

    const steps = [
        { desc: "Informe o valor que deseja adicionar", icon: 'money' },
        { desc: "Clique em “Gerar QRCode” para geração do PIX'", icon: 'add' },
        { desc: "Realize o pagamento do QRCode gerado.o", icon: 'payment' },
        { desc: "Em questão de segundos iremos processar seu pagamento.", icon: 'check' },
    ];

    const generatePIXCode = async () => {

        try {
            const token = localStorage.getItem('jwt_token');

            let valor = parseFloat(value.replace(/[^0-9.]+/g, '').replace(',', '.'));

            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/adicionar/saldo`,
                { 'valor': valor, 'cpf_cnpj': userInfo.CPF_CNPJ, 'nome': userInfo.NAME },
                {
                    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
                }
            );

            const result = response.data;
            const base64 = 'data:image/png;base64,' + result.base64;

            if (result.status === 'success') {
                setPixCode(result.pix_code);
                setPaymentId(result.payment_id);
                setQrcode(base64);
                setCodeGenerated(true);
            }

        } catch (error) {
            console.error('Erro ao gerar o código PIX:', error);
        }
    };

    useEffect(() => {

        const fetchToken = async () => {

            const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

            try {
                const response = await fetch(`${REACT_APP_BACKEND_URL}/auth/portal`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ cpf_cnpj: userInfo.CPF_CNPJ }),
                });

                if (!response.ok) {
                    throw new Error('Falha ao obter o token');
                }

                const data = await response.json();
                setToken(data.token);
            } catch (error) {
                console.error('Erro ao buscar token:', error);
            }
        };

        if (paymentId && userInfo.CPF_CNPJ) {
            fetchToken();
        }
    }, [paymentId]);

    useEffect(() => {
        if (token && paymentStatus !== 'CONFIRMADO') {
            const REACT_APP_BACKEND_SOCKET_URL = process.env.REACT_APP_BACKEND_SOCKET_URL;
            const socket = io(REACT_APP_BACKEND_SOCKET_URL, {
                reconnection: true,
                reconnectionAttempts: 5,
                reconnectionDelay: 30000,
                query: { token, txid: paymentId }
            });

            const handleConnect = () => {
                console.log('Conectado ao servidor Flask via SocketIO!');
                setIsConnected(true);
            };

            const handleDisconnect = () => {
                console.log('Desconectado do servidor Flask.');
                setIsConnected(false);
            };

            const handleReconnect = (attemptNumber) => {
                console.log(`Reconectado ao servidor Flask após ${attemptNumber} tentativas.`);
            };

            const handleReconnectError = () => {
                console.error('Erro ao tentar reconectar ao servidor Flask.');
            };

            const handleResponse = (data) => {
                console.log('Recebido do servidor:', data);
            };

            const handleMessage = (data) => {
                console.log('Mensagem da fila:', data);
                if (data.data.status === 'received') {
                    setPaymentStatus('CONFIRMADO');
                    console.log('Pagamento confirmado!');
                } else {
                    console.log('Pagamento não confirmado!');
                }
            };

            socket.on('connect', handleConnect);
            socket.on('disconnect', handleDisconnect);
            socket.on('reconnect', handleReconnect);
            socket.on('reconnect_error', handleReconnectError);
            socket.on('response', handleResponse);
            socket.on('message', handleMessage);

            return () => {
                socket.off('connect', handleConnect);
                socket.off('disconnect', handleDisconnect);
                socket.off('reconnect', handleReconnect);
                socket.off('reconnect_error', handleReconnectError);
                socket.off('response', handleResponse);
                socket.off('message', handleMessage);
                socket.disconnect();
            };
        }
    }, [token, paymentStatus]);

    useEffect(() => {
        if (authenticated) {
            const { decryptedData } = isAuthenticated();
            setUserInfo(decryptedData);
        }
    }, [authenticated]);

    const handleCopy = () => {
        navigator.clipboard.writeText(pixCode)
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000);
            })
            .catch(err => {
                console.error('Erro ao copiar o código PIX: ', err);
            });
    };

    if (showLoading) {
        return <Loading open={true} />;
    }

    return (
        <>
            <HeaderPage title="Add WhatsApp Instance" />

            <Grid container spacing={4} sx={{ padding: 5 }} position={'relative'}>
                <Grid item xs={12} lg={6}>
                    <HelpAdd Steps={steps} Title="Adcione saldo a sua conta" />
                </Grid>

                {paymentStatus === 'CONFIRMADO' ? (
                    <Grid container item xs={12} lg={6} alignItems="center" justifyContent="center" spacing={1} style={{ minHeight: 'calc(100vh - 64px)' }}>
                        <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <div style={{ backgroundColor: '#fff', borderRadius: '10px', padding: '40px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                                <img src={Confirmed} style={{ width: '200px', height: '200px', marginBottom: '20px' }} alt="Pagamento confirmado" />
                                <Typography style={{ color: '#8142FC', fontSize: '30px', fontFamily: 'Satoshi', marginBottom: '-5px' }}>
                                    Recebemos seu pagamento!
                                </Typography>
                                <Typography style={{ color: '#848484', fontSize: '18px', fontFamily: 'Satoshi-Medium', marginBottom: '20px', maxWidth: '400px', textAlign: 'center', marginTop: '10px' }}>
                                    Sua recarga foi efetuada com sucesso. Para dúvidas, entre em contato pelo email suporte@123zap.com.br
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item xs={12} lg={6} sx={{ minHeight: { lg: 'calc(100vh - 64px)', xl: 'calc(100vh - 64px)' } }}>
                        <div style={{ backgroundColor: '#fff', borderRadius: '10px', padding: '40px', height: '100%' , minHeight: { lg: 'calc(100vh - 64px)', xl: 'calc(100vh - 64px)' , md: 'calc(100vh - 64px)' } }}>
                            <Typography style={{ color: '#52525B', fontSize: '18px', fontFamily: 'Satoshi', marginBottom: '-5px' }}>
                                Qual valor deseja adicionar a sua conta?
                            </Typography>

                            <CurrencyInput
                                id="outlined-basic"
                                variant="outlined"
                                multiline
                                fullWidth
                                value={value}
                                onValueChange={(values) => {
                                    const { formattedValue, value } = values;
                                    setValue(value);
                                }}
                                placeholder='R$'
                                sx={{
                                    marginTop: '20px',
                                    backgroundColor: '#F5F3FF',
                                    '& .MuiInputBase-input': {
                                        fontSize: '40px',
                                        textAlign: 'center',
                                        color: '#4F189A',
                                    },
                                    '& .MuiInputBase-inputMultiline': {
                                        display: 'flex',
                                        alignItems: 'center',
                                        margin: '20px',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '4px',
                                    }
                                }}
                            />

                            <Grid container item xs={12} alignItems="center" spacing={1}>
                                <Grid item>
                                    <Typography style={{ color: '#52525B', fontSize: '18px', fontFamily: 'Satoshi', marginTop: '20px' }}>
                                        Selecione a forma de pagamento
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <ErrorOutlineIcon style={{ color: '#52525B', marginTop: '25px' }} />
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Select
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        name="paymentType"
                                        defaultValue="pix"
                                        sx={{ background: '#F9F9F9', marginTop: '10px', marginBottom: { xl: '8px', lg: '5px' } }}
                                    >
                                        <MenuItem value="pix">PIX</MenuItem>
                                        <MenuItem value="credit_card" disabled>Cartão de Crédito</MenuItem>
                                        <MenuItem value="boleto" disabled>Boleto</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid container item xs={12} alignItems="center" spacing={1}>
                                <Grid item>
                                    <Typography style={{ color: '#52525B', fontSize: '18px', fontFamily: 'Satoshi', marginTop: '20px' }}>
                                        Pix Copia e Cola
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <ErrorOutlineIcon style={{ color: '#52525B', marginTop: '25px' }} />
                                </Grid>
                            </Grid>

                            <Box
                                display="flex"
                                alignItems="center"
                                spacing={1}
                                sx={{ width: '100%' }}
                            >
                                <Box flexGrow={1} marginRight={1}>
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        fullWidth
                                        value={pixCode}
                                        sx={{
                                            marginTop: '5px',
                                            backgroundColor: '#F9F9F9',
                                            '& .MuiInputBase-input': {
                                                fontSize: '18px',
                                                textAlign: 'center',
                                                color: '#4F189A',
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '4px',
                                            }
                                        }}
                                        disabled
                                    />
                                </Box>
                                <Button
                                    variant="contained"
                                    sx={{ marginTop: '5px', height: '56px', backgroundColor: codeGenerated ? '#8142FC' : '#F9F9F9 !important', color: codeGenerated ? 'white' : '#52525B !important' }}
                                    onClick={handleCopy}
                                    disabled={!codeGenerated}
                                >
                                    {copied ? 'Copiado!' : 'Copiar'}
                                </Button>
                            </Box>

                            {
                                !codeGenerated && (
                                    <Button
                                        type="button"
                                        style={{
                                            backgroundColor: '#8033F4',
                                            color: 'white',
                                            width: '100%',
                                            borderRadius: '4px',
                                            padding: '15px',
                                            marginTop: '20px',
                                        }}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            generatePIXCode();
                                        }}
                                    >
                                        Adicionar saldo
                                    </Button>
                                )
                            }

                            {
                                codeGenerated && (
                                    <div style={{ display: 'flex', justifyContent: 'right', marginTop: '20px', backgroundColor: '#F9F9F9', padding: '20px', borderRadius: '4px' }}>
                                        <img src={qrcode} style={{ width: '250px', height: '250px', marginTop: '20px', alignSelf: 'right' }} alt="QR Code" />
                                    </div>
                                )
                            }
                        </div>
                    </Grid>
                )}
                <Box sx={{ display: { md: 'flex', lg: 'inherit' }, alignItems: 'start', position: { lg: 'fixed' }, bottom: { lg: 0 }, padding: { xs: '20px 0 0 40px', lg: '0 0 0 40px' } }}>
                    <Footer />
                </Box>
            </Grid>
        </>
    );
}

export default AddCredit;