// Endpoint used to verify the URLs of the token.
// The token is sent to the backend and the URLs are verified.
// The backend returns the URLs that were verified and the URLs that were not verified.
// The URLs that were not verified are invalid and the user is redirected to the login page.
// The URLs that were verified are valid and the user is redirected to the application.
// Author: Vitor de Jesus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/133201922/AuthRoutes

import axios from 'axios';

export async function VerifyUrls(data) {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/verify-urls`,
            data,
            { headers: { 'Content-Type': 'application/json' } }
        );
        return response.data;
    } catch (error) {
        if (error.response) {
            const status = error.response.status;
            if (status === 400) {
                return { 
                    status: 400,
                    message: 'Erro ao verificar token.'
                };
            } else if (status === 429) {
                return {
                    status: 429,
                    message: 'Muitas solicitações. Tente novamente mais tarde.'
                };
            } else if (status === 404){
                return {
                    status: 404,
                    message: 'Token inválido.'
                };
            }
        }  
    }
}
