// Custom functions to use in the project

import toast from "react-hot-toast";
import CustomAlert from "../components/Alerts/CustomAlert";
import { isAuthenticated } from '../authMiddleware';

export const notify = (type, text) => {
  toast.remove();
  toast.custom(<CustomAlert message={text} type={type} />);
};

export const truncateText = (text, number) => {
  if (text.length < number) {
    return text;
  }

  return text.substring(0, number) + "...";
};

// Function to check if cookies are expired
export const checkAuth = () => {
  const authStatus = isAuthenticated();
  const { authenticated } = authStatus;

  return authenticated
};

// Function to create a object from one array
export function createObjectFromArray(array, initialValue) {
  const result = {};
  array.forEach((str) => {
    result[str] = initialValue; // All properties start with this value
  });
  return result;
}

// Function to apply a mask for cpf or cnpj
export const insertMaskForCpfOrCnpj = (cpfOrCnpj) => {
  cpfOrCnpj = cpfOrCnpj.replace(/\D/g, ""); // Remove all that isn't a number

  if (cpfOrCnpj.length <= 11) {
      // Apply cpf mask
      cpfOrCnpj = cpfOrCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  } else {
      // Apply cnpj mask
      cpfOrCnpj = cpfOrCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }

  return cpfOrCnpj;
}

// Function to insert mask in a phone Number
export const insertMaskForPhoneNumber = (phone) => {
  const noMask = phone.replace(/\D/g, ''); // Remove all that isn't a number
    const { length } = noMask;
  
    if (length <= 11) { // Insert mask
      return noMask
        .replace(/\D/g,"")
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(length === 11 ? /(\d{5})(\d)/ : /(\d{4})(\d)/, '$1-$2');
    }

    return phone; 
}

// Function to insert Mask in CEP
export const insertMaskForCep = (text) => {
  text = text.replace(/\D/g,"");
  text = text.replace(/(\d{5})(\d)/, '$1-$2');
  text = text.substring(0, 9);

  return text;
}