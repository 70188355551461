import axios from 'axios';

export async function ConsultPortal() {
    try {
        const token = localStorage.getItem('jwt_token');

        const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/consultar/portal`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            }
        );

        return response.data;
    }
    catch (error) {
        console.error('Erro ao buscar dados da API:', error);
    }

}