import React, { useEffect } from 'react';
import { Button, Typography, Box, Grid } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import Dropzone from 'react-dropzone';

import modelo_roxo from '../../assets/images/modelo-roxo.png';
import modelo_azul from '../../assets/images/modelo-azul.png';
import modelo_amarelo from '../../assets/images/modelo-laranja.png';
import modelo_verde from '../../assets/images/modelo-verde.png';
import modelo_vermelho from '../../assets/images/modelo-vermelho.png';
import modelo_rosa from '../../assets/images/modelo-rosa.png';

import HeaderPage from '../../components/HeaderPage/HeaderPage';
import ThemeColors from '../../components/ThemeColors/ThemeColors';
import Titles from '../../components/Titles/Titles';

const ChooseLayout = ({ templates, setColorCharge, setLayoutCharge, setLogoCharge, NextPage }) => {

    const [selected, setSelected] = React.useState('Padrão');
    const [selectedColor, setSelectedColor] = React.useState('#7B25FF');
    const [selectedFiles, setSelectedFiles] = React.useState([]);
    const [errorImage, setErrorImage] = React.useState(false);

    const colors = ['#82BD69', '#1837D7', '#DEA73F', '#BB0404', '#7B25FF', '#ed15be'];

    const mappColor = {
        '#82BD69': 'Verde',
        '#1837D7': 'Azul',
        '#DEA73F': 'Amarelo',
        '#BB0404': 'Vermelho',
        '#7B25FF': 'Roxo',
        '#ed15be': 'Rosa',
    };

    const mappImage = {
        '#82BD69': modelo_verde,
        '#1837D7': modelo_azul,
        '#DEA73F': modelo_amarelo,
        '#BB0404': modelo_vermelho,
        '#7B25FF': modelo_roxo,
        '#ed15be': modelo_rosa,
    };

    const [ImageActive, setImageActive] = React.useState(modelo_roxo);

    useEffect(() => {
        setColorCharge(selectedColor);
        setLayoutCharge(0);
        setLogoCharge('');
    }, []);

    const handleColorChange = (color) => {

        const colorName = mappColor[color];
        const image = mappImage[color];

        setImageActive(image);
        setColorCharge(colorName);
        setSelectedColor(color);
    };

    const handleFileChange = (files) => {
        if (files.length > 0) {
            if (files[0].type.includes('image')) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const newFile = {
                        file: {
                            name: files[0].name,
                            type: files[0].type,
                        },
                        base64String: reader.result,
                    };
                    setSelectedFiles([newFile]);
                    setErrorImage(false);
                    setLogoCharge(reader.result);
                };
                reader.readAsDataURL(files[0]);
            } else {
                setErrorImage(true);
            }
        }
    };

    const handleClick = (button) => {
        setLayoutCharge(button.index);
        setSelected(button.title);
    };

    const deleteFile = () => {
        setSelectedFiles([]);
        setLogoCharge('');
    };

    const [screenSmall, setScreenSmall] = React.useState(false);
    const [exibirPrevia, setExibirPrevia] = React.useState(false);

    useEffect(() => {
        const handleResize = () => {
            setScreenSmall(window.innerWidth < 1200);
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    const HelpAdd = (desc) => (
        <Box sx={{ marginBottom: '10px' }}>
            <Grid container>
                <Grid item xs={12} sx={{ background: '#FFF', padding: '10px', borderRadius: '12px', marginBottom: '10px' }}>
                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', padding: '10px' }}>
                        <Titles type={"P1"}>{desc}</Titles>
                    </div>
                </Grid>
            </Grid>
        </Box>
    );

    return (
        <>
            <HeaderPage />
            <Grid container spacing={4} sx={{ padding: 5 }}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="h5" sx={{ color: ThemeColors.gray50, marginBottom: '15px' }}>
                        Personalize suas faturas
                    </Typography>
                    {HelpAdd("Qual será o modelo de layout da sua fatura?")}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
                        {templates.map((template, index) => (
                            <Button
                                key={template.index}
                                onClick={() => handleClick(template)}
                                sx={{
                                    flexGrow: 1,
                                    mx: index === 0 || index === templates.length - 1 ? 0 : 0.5,
                                    bgcolor: selected === template.title ? '#8142FC' : (index === 0 ? ThemeColors.gray30 : 'lightgray'),
                                    color: '#fff',
                                    borderRadius: '15px',
                                    '&:hover': { bgcolor: selected === template.title ? '#8142FC' : 'gray' },
                                    cursor: index === 0 ? 'pointer' : 'default',
                                    pointerEvents: index === 0 ? 'auto' : 'none',
                                    minWidth: '100px', // Ensures buttons are at least this width
                                }}
                                disabled={index !== 0}
                            >
                                {template.title}
                            </Button>
                        ))}
                    </Box>
                    {HelpAdd("Agora me conta: qual será a cor das suas faturas?")}
                    <Grid container spacing={2} sx={{ marginBottom: '10px' }}>
                        {colors.map((color) => (
                            <Grid item key={color}>
                                <Box
                                    sx={{
                                        width: '50px', height: '50px', background: color,
                                        opacity: selectedColor === color ? 1 : 0.2, borderRadius: '12px',
                                        cursor: 'pointer', marginBottom: '10px', marginLeft: '10px'
                                    }}
                                    onClick={() => handleColorChange(color)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    {HelpAdd("Insira o logotipo da sua empresa na fatura.")}
                    <Grid item xs={12}>
                        <Dropzone
                            onDrop={handleFileChange}
                            accept="image/*"
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div
                                    {...getRootProps()}
                                    style={{
                                        cursor: selectedFiles.length === 0 ? 'pointer' : 'default',
                                        textAlign: 'center',
                                        background: errorImage ? ThemeColors.red01 : '#E5E5E5',
                                        marginTop: '10px',
                                        borderRadius: '4px',
                                        height: '170px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <input {...getInputProps()} />
                                    {selectedFiles.length === 0 && (
                                        <>
                                            <CloudUploadIcon style={{ fontSize: '64px', color: errorImage ? ThemeColors.red03 : '#848484' }} />
                                            <Titles children={errorImage ? 'Adicione apenas imagens' : 'Clique para adicionar arquivo'} color={errorImage ? ThemeColors.red03 : '#848484'} type={'P1'} />
                                        </>
                                    )}
                                    <Box sx={{ width: '90%' }}>
                                        {selectedFiles.map((file, index) => (
                                            <Box key={index} display={'flex'} alignItems={'center'} justifyContent={'space-between'} minHeight={'48px'} sx={{ background: '#fff', borderRadius: '4px', padding: '0 10px', marginBottom: '10px', width: '100%' }}>
                                                <Box display={'flex'} gap={'10px'} alignItems={'end'}>
                                                    <img src={file.base64String} alt="file preview" width={'24px'} height={'24px'} style={{ objectFit: 'cover' }} />
                                                    <p style={{ fontSize: '13px', color: '#303035' }}>
                                                        {file.file.name.length > 25 ? `${file.file.name.substring(0, 25)}...` : file.file.name}
                                                    </p>
                                                </Box>
                                                <Box onClick={deleteFile} sx={{ cursor: 'pointer', '&:hover': { scale: '1.1' }, transition: '.3s' }}>
                                                    <DeleteIcon style={{ color: '#FF3D3D' }} />
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box>
                                </div>
                            )}
                        </Dropzone>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                            <Button
                                variant="outlined"
                                color="primary"
                                style={{ padding: '15px', width: '250px', borderRadius: '6px', marginRight: '10px', width: '50%' }}
                                onClick={() => setExibirPrevia(!exibirPrevia)}
                                sx={{ display: { xs: 'block', sm: 'block', md: 'block', lg: 'none', xl: 'none' } }}
                            >
                                {exibirPrevia ? 'Ocultar' : 'Exibir'} Prévia
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ padding: '15px', width: '250px', borderRadius: '6px', width: '50%' }}
                                onClick={() => NextPage()}
                            >
                                Continuar
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                {(screenSmall ? exibirPrevia : true) && (
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: "flex", flexDirection: "column" }}>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                background: `${selectedColor}20`,
                                borderRadius: '12px',
                                padding: '20px',
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                transition: 'background 0.5s ease'
                            }}
                        >
                            <img
                                src={ImageActive}
                                alt="Modelo"
                                style={{
                                    width: '100%',
                                    maxWidth: '500px',
                                    height: 'auto',
                                    objectFit: 'contain',
                                    transition: 'opacity 0.5s ease'
                                }}
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default ChooseLayout;
