// This component is responsible for rendering the confirmation form, where the user can confirm the password reset code sent to their email.
// It uses the VerifyToken function from the backend API to verify the token sent in the email.
// It uses the useState hook from React to manage the component's state.
// It uses the Box, Grid, Typography, TextField, Button, and FormControlLabel components from the Material-UI library to create the layout of the confirmation form.
// It uses the ThemeColors component to manage the colors of the theme.
// Author: Vitor de Jesus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/101089281/Login
// Prototype: https://www.figma.com/design/NqSBP9Hjy6KFGUik7FKy4F/UIUX-design?node-id=34-1607&t=mEOidrARSPxeHbDw-4


import React from 'react';

import { Grid, Box, Typography, TextField, Button } from '@mui/material';

import ThemeColors from '../../components/ThemeColors/ThemeColors';

const FormConfirm = ({ message, loading, handleSubmitConfirm, codeConfirm, setCodeConfirm }) => {
    return (
        <Grid container spacing={1} alignItems="center" justifyContent="center">
            <Grid item>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    padding: '10px',
                    background: message ? '#F8D6D3' : 'transparent',
                    marginBottom: '10px',
                    marginTop: '-40px',
                    borderRadius: '10px',
                }}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask0_34_1183" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                            <rect width="20" height="20" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_34_1183)">
                            <path fill={message ? '#CD4747' : 'transparent'} d="M8 10.9743C8.11622 10.9743 8.21367 10.935 8.29233 10.8563C8.371 10.7778 8.41033 10.6804 8.41033 10.5642C8.41033 10.4478 8.371 10.3504 8.29233 10.2718C8.21367 10.1932 8.11622 10.1538 8 10.1538C7.88378 10.1538 7.78633 10.1932 7.70767 10.2718C7.629 10.3504 7.58967 10.4478 7.58967 10.5642C7.58967 10.6804 7.629 10.7778 7.70767 10.8563C7.78633 10.935 7.88378 10.9743 8 10.9743ZM7.66667 8.76917H8.33333V4.76917H7.66667V8.76917ZM8.00217 14C7.1725 14 6.39244 13.8426 5.662 13.5277C4.93167 13.2128 4.29633 12.7854 3.756 12.2457C3.21567 11.7059 2.78794 11.0711 2.47283 10.3413C2.15761 9.61167 2 8.83194 2 8.00217C2 7.1725 2.15744 6.39244 2.47233 5.662C2.78722 4.93167 3.21456 4.29633 3.75433 3.756C4.29411 3.21567 4.92889 2.78794 5.65867 2.47283C6.38833 2.15761 7.16806 2 7.99783 2C8.8275 2 9.60756 2.15744 10.338 2.47233C11.0683 2.78722 11.7037 3.21456 12.244 3.75433C12.7843 4.29411 13.2121 4.92889 13.5272 5.65867C13.8424 6.38833 14 7.16806 14 7.99783C14 8.8275 13.8426 9.60756 13.5277 10.338C13.2128 11.0683 12.7854 11.7037 12.2457 12.244C11.7059 12.7843 11.0711 13.2121 10.3413 13.5272C9.61167 13.8424 8.83194 14 8.00217 14Z" />
                        </g>
                    </svg>
                    <Typography style={{ color: message ? '#CD4747' : 'transparent', fontSize: '12px', fontFamily: 'Satoshi', marginLeft: '10px' }}>
                        {message}
                    </Typography>
                </div>
            </Grid>

            <Grid item xs={12}>
                <Box sx={{ width: '100%', backgroundColor: '#fff', padding: { xl: '40px', lg: '30px' }, borderRadius: '20px', border: '1.5px solid #DBDBDB' }}>
                    <Typography sx={{ color: ThemeColors.purple500, fontSize: { xl: '24px', lg: '20px' }, fontFamily: 'Satoshi-Medium', fontWeight: 500 }}>Recuperação de senha enviada</Typography>
                    <Typography style={{ color: '#52525B', fontSize: '13px', fontFamily: 'Satoshi', marginTop: '10px', maxWidth: '80%' }}>Agora insira o código que enviamos ao seu email para criar uma nova senha.</Typography>
                    <Typography style={{ color: '#52525B', fontSize: '14px', fontFamily: 'Satoshi', marginTop: '20px', marginBottom: 0 }}>Código de recuperação</Typography>
                    <TextField

                        variant="outlined"
                        fullWidth
                        margin="normal"
                        name="username"
                        value={codeConfirm}
                        autoFocus={true}
                        size="small"
                        onChange={(e) => setCodeConfirm(e.target.value)}
                        sx={{ background: (message === 'Por favor, preencha o campo de código.' && codeConfirm === '') || message === 'Código inválido.' ? '#F8D6D3' : '#F9F9F9', marginTop: '5px' }}
                        placeholder='XXXX'
                        InputProps={{
                            endAdornment: (
                                (message === 'Por favor, preencha o campo de código.' && codeConfirm === '') || message === 'Código inválido.' ?
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="mask0_34_1183" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                            <rect width="20" height="20" fill="#D9D9D9" />
                                        </mask>
                                        <g mask="url(#mask0_34_1183)">
                                            <path d="M8 10.9743C8.11622 10.9743 8.21367 10.935 8.29233 10.8563C8.371 10.7778 8.41033 10.6804 8.41033 10.5642C8.41033 10.4478 8.371 10.3504 8.29233 10.2718C8.21367 10.1932 8.11622 10.1538 8 10.1538C7.88378 10.1538 7.78633 10.1932 7.70767 10.2718C7.629 10.3504 7.58967 10.4478 7.58967 10.5642C7.58967 10.6804 7.629 10.7778 7.70767 10.8563C7.78633 10.935 7.88378 10.9743 8 10.9743ZM7.66667 8.76917H8.33333V4.76917H7.66667V8.76917ZM8.00217 14C7.1725 14 6.39244 13.8426 5.662 13.5277C4.93167 13.2128 4.29633 12.7854 3.756 12.2457C3.21567 11.7059 2.78794 11.0711 2.47283 10.3413C2.15761 9.61167 2 8.83194 2 8.00217C2 7.1725 2.15744 6.39244 2.47233 5.662C2.78722 4.93167 3.21456 4.29633 3.75433 3.756C4.29411 3.21567 4.92889 2.78794 5.65867 2.47283C6.38833 2.15761 7.16806 2 7.99783 2C8.8275 2 9.60756 2.15744 10.338 2.47233C11.0683 2.78722 11.7037 3.21456 12.244 3.75433C12.7843 4.29411 13.2121 4.92889 13.5272 5.65867C13.8424 6.38833 14 7.16806 14 7.99783C14 8.8275 13.8426 9.60756 13.5277 10.338C13.2128 11.0683 12.7854 11.7037 12.2457 12.244C11.7059 12.7843 11.0711 13.2121 10.3413 13.5272C9.61167 13.8424 8.83194 14 8.00217 14Z" fill="#CD4747" />
                                        </g>
                                    </svg>
                                    : null
                            )
                        }}
                    />

                    <Grid container alignItems="center" justifyContent="right" style={{ marginTop: '10px' }}>
                        <Grid container spacing={1} style={{ marginTop: '5px' }}>
                            <Grid item xs={12}>
                                <Button type="submit" variant="contained"
                                    className={loading ? "button-disabled" : "button-default"}
                                    fullWidth disabled={loading} onClick={handleSubmitConfirm}>
                                    Continuar
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid container alignItems="center" justifyContent="center" style={{ marginTop: '5px' }}>
                        <Grid item>
                            <a href="/login" style={{ color: ThemeColors.purple400, textDecoration: 'none', fontSize: '14px', fontFamily: 'Satoshi' }}>Não recebi o código</a>
                        </Grid>

                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
};

export default FormConfirm;