// This component render the page for the user to choose the portal layout and colors
// Author: Vitor de Jesus

// Prototype: https://www.figma.com/design/hEcS94WglMrTuRrmafeTyR/UIUX-design---123zap?node-id=2449-1022&t=HAXZVKITkgjKulMI-4

import React, { useEffect } from 'react';

import { Button, Typography, Box, Grid } from '@mui/material';
import { FormControl, FormHelperText } from '@mui/material';
import { IconButton } from '@mui/material';
import { InputAdornment } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip'; // Importação correta do Tooltip

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';

import Dropzone from 'react-dropzone';

import modelo_roxo from '../../assets/images/celular-roxo.png';
import modelo_azul from '../../assets/images/celular-azul.png';
import modelo_amarelo from '../../assets/images/celular-laranja.png';
import modelo_verde from '../../assets/images/celular-verde.png';
import modelo_vermelho from '../../assets/images/celular-vermelho.png';
import modelo_rosa from '../../assets/images/celular-rosa.png';

import modelo_roxo2 from '../../assets/images/notebook-roxo.png';
import modelo_azul2 from '../../assets/images/notebook-azul.png';
import modelo_amarelo2 from '../../assets/images/notebook-laranja.png';
import modelo_verde2 from '../../assets/images/notebook-verde.png';
import modelo_vermelho2 from '../../assets/images/notebook-vermelho.png';
import modelo_rosa2 from '../../assets/images/notebook-rosa.png';

import HeaderPage from '../../components/HeaderPage/HeaderPage';
import ThemeColors from '../../components/ThemeColors/ThemeColors';
import Titles from '../../components/Titles/Titles';

import { PortalConfig } from '../../api/backend/Financial/PortalConfig';
import { ConsultPortal } from '../../api/backend/Financial/ConsultPortal';
import Loading from "../../components/Loading/Loading";


const ChoosePortal = () => {

    const [exibirPrevia, setExibirPrevia] = React.useState(false);
    const [selectedColor, setSelectedColor] = React.useState()
    const [selectedFiles, setSelectedFiles] = React.useState([]);
    const [errorImage, setErrorImage] = React.useState(false);
    const [currentImageIndex, setCurrentImageIndex] = React.useState()
    const [logoCharge, setLogoCharge] = React.useState('');
    const [screenSmall, setScreenSmall] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    const [address, setAddress] = React.useState('');
    const [colorName, setColorName] = React.useState('');

    const mappImage = {
        '#82BD69': [modelo_verde, modelo_verde2],
        '#1837D7': [modelo_azul, modelo_azul2],
        '#DEA73F': [modelo_amarelo, modelo_amarelo2],
        '#BB0404': [modelo_vermelho, modelo_vermelho2],
        '#7B25FF': [modelo_roxo, modelo_roxo2],
        '#ed15be': [modelo_rosa, modelo_rosa2],
    };

    const colors = Object.keys(mappImage);
    const [ImageActive, setImageActive] = React.useState();
    const [errorAddress, setErrorAddress] = React.useState(false);

    const [loadingSave, setLoadingSave] = React.useState(false);
    const [sucessSave, setSucessSave] = React.useState(false);

    useEffect(() => {
        const handleResize = () => {
            setScreenSmall(window.innerWidth < 1200);
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (selectedColor && mappImage[selectedColor]) {
            setImageActive(mappImage[selectedColor][currentImageIndex ?? 0] || mappImage[selectedColor][0]);
        }
    }, [selectedColor, currentImageIndex]);

    const handleColorChange = (color) => {
        setSelectedColor(color);
        setCurrentImageIndex(0);

        switch (color) {
            case '#82BD69':
                setColorName('green');
                break;
            case '#1837D7':
                setColorName('blue');
                break;
            case '#DEA73F':
                setColorName('yellow');
                break;
            case '#BB0404':
                setColorName('red');
                break;
            case '#7B25FF':
                setColorName('purple');
                break;
            case '#ed15be':
                setColorName('pink');
                break;
            default:
                setColorName('purple');
                break;
        }
    };

    const nextImage = () => {
        if (currentImageIndex < mappImage[selectedColor].length - 1) {
            setCurrentImageIndex(prevIndex => prevIndex + 1);
        }
    };

    const previousImage = () => {
        if (currentImageIndex > 0) {
            setCurrentImageIndex(prevIndex => prevIndex - 1);
        }
    };

    const handleFileChange = (files) => {
        if (files.length > 0) {
            if (files[0].type.includes('image')) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const newFile = {
                        file: {
                            name: files[0].name,
                            type: files[0].type,
                        },
                        base64String: reader.result,
                    };
                    setSelectedFiles([newFile]);
                    setErrorImage(false);
                    setLogoCharge(reader.result);
                };
                reader.readAsDataURL(files[0]);
            } else {
                setErrorImage(true);
            }
        }
    };

    const deleteFile = () => {
        setSelectedFiles([]);
        setLogoCharge('');
    };

    const savePortalConfig = async () => {
        if (!address || !colorName || !logoCharge) {
            console.error('Preencha todos os campos antes de salvar.');

            if (!address) {
                setErrorAddress(true);
            }

            if (!colorName) {
                console.error('Selecione uma cor antes de salvar.');
            }

            if (!logoCharge || !selectedFiles) {
                setErrorImage(true);
            }

            return;
        }

        setLoadingSave(true);

        const data = {
            address,
            theme: colorName,
            logo: logoCharge,
        };

        try {
            const result = await PortalConfig(data);
            if (result.errorCode) {

                if (result.errorCode === 409) {
                    setErrorAddress(true);
                }

                console.error(
                    'Erro ao salvar a configuração do portal:',
                    result.errorMessage ? result.errorMessage : 'Erro desconhecido'
                );
                return;
            }

            setSucessSave(true);
            setTimeout(() => setSucessSave(false), 5000); // Oculta a mensagem após 5 segundos

        } catch (error) {
            console.error('Erro ao salvar a configuração do portal:', error);
        } finally {
            setLoadingSave(false);
        }
    };

    useEffect(() => {

        const fetchData = async () => {
            try {

                const result = await ConsultPortal();

                if (!result) {
                    console.error('Resultado da consulta está indefinido.');
                    return;
                }

                if (result.errorCode) {
                    console.error(
                        'Erro ao buscar a configuração do portal:',
                        result.errorMessage ? result.errorMessage : 'Erro desconhecido'
                    );
                    return;
                }

                if (result.address && result.theme && result.logo) {

                    setAddress(result.address);

                    switch (result.theme) {
                        case 'green':
                            setSelectedColor('#82BD69');
                            setColorName('green');
                            setCurrentImageIndex(0);
                            break;
                        case 'blue':
                            setSelectedColor('#1837D7');
                            setColorName('blue');
                            setCurrentImageIndex(0);
                            break;
                        case 'yellow':
                            setSelectedColor('#DEA73F');
                            setColorName('yellow');
                            setCurrentImageIndex(0);
                            break;
                        case 'red':
                            setSelectedColor('#BB0404');
                            setColorName('red');
                            setCurrentImageIndex(0);
                            break;
                        case 'purple':
                            setSelectedColor('#7B25FF');
                            setColorName('purple');
                            setCurrentImageIndex(0);
                            break;
                        case 'pink':
                            setSelectedColor('#ed15be');
                            setColorName('pink');
                            setCurrentImageIndex(0);
                            break;
                        default:
                            setSelectedColor('#7B25FF');
                            setColorName('purple');
                            setCurrentImageIndex(0);
                            break;
                    }

                    setLogoCharge(result.logo);
                    setSelectedFiles([{ file: { name: 'Logo', type: 'image' }, base64String: result.logo }]);


                }

            } catch (error) {
                console.error('Erro ao buscar a configuração do portal:', error);
            }
            finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const HelpAdd = (desc) => (
        <Box sx={{ marginBottom: '10px' }}>
            <Grid container>
                <Grid item xs={12} sx={{ background: '#FFF', padding: '10px', borderRadius: '12px', marginBottom: '10px' }}>
                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', padding: '10px' }}>
                        <Titles type={"P1"}>{desc}</Titles>
                    </div>
                </Grid>
            </Grid>
        </Box>
    );

    if (loading) {
        return <Loading open={true} />
    }

    return (
        <>
            <HeaderPage />
            <Grid container spacing={2} sx={{ padding: 5 }}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h5" sx={{ color: ThemeColors.gray50, marginBottom: '15px' }}>
                        Personalize seu portal de faturas
                    </Typography>
                    {HelpAdd("Qual será o endereço final do portal?")}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'stretch',
                            marginBottom: '15px',
                        }}
                    >
                        <Typography
                            variant="body1"
                            sx={{
                                color: ThemeColors.gray50,
                                background: '#E5E5E5',
                                padding: '15px',
                                borderRadius: '6px 0 0 6px',
                                width: '50%',
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            https://faturas.123zap.com.br/
                        </Typography>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            value={address}
                            onChange={(e) => {
                                setErrorAddress(false);
                                const newValue = e.target.value.replace(/[^a-z0-9-]/g, '').toLowerCase();
                                setAddress(newValue);
                            }}
                            sx={{
                                width: '50%',
                                background: errorAddress ? ThemeColors.red01 : '#F0F0F0',
                                borderRadius: '0 6px 6px 0',
                                display: 'flex',
                                alignItems: 'stretch',
                            }}
                            error={errorAddress}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Tooltip
                                            title={
                                                <span style={{ fontSize: '14px' }}>
                                                    {address.length === 0 ? 'É necessário preencher o endereço final do portal.' :
                                                        errorAddress
                                                            ? 'Essa URL já está em uso por outro usuário. Por favor, escolha outro endereço.'
                                                            : 'Este endereço deve ser único e não pode estar em uso por outro usuário.'}
                                                </span>
                                            }
                                            arrow
                                            placement="top"
                                        >
                                            <IconButton>
                                                <HelpOutlineIcon sx={{ color: errorAddress ? ThemeColors.red02 : 'inherit', backgroundColor: 'transparent' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    {HelpAdd("Qual será a cor tema do portal?")}
                    <Grid container spacing={2} sx={{ marginBottom: '10px' }}>
                        {colors.map((color) => (
                            <Grid item key={color}>
                                <Box
                                    sx={{
                                        width: '50px', height: '50px', background: color,
                                        opacity: selectedColor === color ? 1 : 0.2, borderRadius: '12px',
                                        cursor: 'pointer', marginBottom: '10px', marginLeft: '10px'
                                    }}
                                    onClick={() => handleColorChange(color)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    {HelpAdd("Insira o logotipo da sua empresa na fatura.")}
                    <Grid item xs={12}>
                        <Dropzone
                            onDrop={handleFileChange}
                            accept="image/*"
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div
                                    {...getRootProps()}
                                    style={{
                                        cursor: selectedFiles.length === 0 ? 'pointer' : 'default',
                                        textAlign: 'center',
                                        background: errorImage ? ThemeColors.red01 : '#E5E5E5',
                                        marginTop: '10px',
                                        borderRadius: '4px',
                                        height: '170px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <input {...getInputProps()} />
                                    {selectedFiles.length === 0 && (
                                        <>
                                            <CloudUploadIcon style={{ fontSize: '64px', color: errorImage ? ThemeColors.red03 : '#848484' }} />
                                            <Titles children={errorImage ? 'Adicione apenas imagens' : 'Clique para adicionar arquivo'} color={errorImage ? ThemeColors.red03 : '#848484'} type={'P1'} />
                                        </>
                                    )}
                                    <Box sx={{ width: '90%' }}>
                                        {selectedFiles.map((file, index) => (
                                            <Box key={index} display={'flex'} alignItems={'center'} justifyContent={'space-between'} minHeight={'48px'} sx={{ background: '#fff', borderRadius: '4px', padding: '0 10px', marginBottom: '10px', width: '100%' }}>
                                                <Box display={'flex'} gap={'10px'} alignItems={'end'}>
                                                    <img src={file.base64String} alt="file preview" width={'24px'} height={'24px'} style={{ objectFit: 'cover' }} />
                                                    <p style={{ fontSize: '13px', color: '#303035' }}>
                                                        {file.file.name.length > 25 ? `${file.file.name.substring(0, 25)}...` : file.file.name}
                                                    </p>
                                                </Box>
                                                <Box onClick={deleteFile} sx={{ cursor: 'pointer', '&:hover': { scale: '1.1' }, transition: '.3s' }}>
                                                    <DeleteIcon style={{ color: '#FF3D3D' }} />
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box>
                                </div>
                            )}
                        </Dropzone>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                            <Button
                                variant="outlined"
                                color="primary"
                                style={{ padding: '15px', width: '250px', borderRadius: '6px', marginRight: '10px', width: '50%' }}
                                onClick={() => setExibirPrevia(!exibirPrevia)}
                                sx={{ display: { xs: 'block', sm: 'block', md: 'block', lg: 'none', xl: 'none' } }}
                            >
                                {exibirPrevia ? 'Ocultar' : 'Exibir'} Prévia
                            </Button>
                            <Button
                                onClick={savePortalConfig}
                                disabled={loadingSave}
                                sx={{
                                    marginTop: '20px', padding: '15px', width: '250px', borderRadius: '6px', width: '50%',
                                    backgroundColor: loadingSave ? '#EDE8FF  !important' : sucessSave ? '#D3EAC8 !important' : '#7B25FF !important',
                                    color: loadingSave ? '#A986FF !important' : sucessSave ? '#4C8435 !important' : '#FFFFFF !important'
                                }}
                            >
                                {loadingSave ? 'Aguarde,enquanto salvamos...' : sucessSave ? 'Configuração salva com sucesso!' : 'Salvar Configuração'}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                {(screenSmall ? exibirPrevia : true) && (
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}

                        sx={{ display: "flex", flexDirection: "column", height: 'calc(100vh - 100px)' }}>
                        {/* Conteúdo da prévia */}
                        <div style={{ display: 'flex', gap: '8px', marginBottom: '10px', marginTop: '0px' }}>
                            <Button
                                onClick={previousImage}
                                disabled={currentImageIndex === 0}
                                sx={{ padding: '10px 8px', flexGrow: 1, maxHeight: '50px', backgroundColor: currentImageIndex === 0 ? '#F0F0F0' : '#EDE8FF', color: currentImageIndex === 0 ? '#1E1E1E' : '#210D33' }}

                            >
                                Imagem Anterior
                            </Button>
                            <Button
                                onClick={nextImage}
                                disabled={currentImageIndex === mappImage[selectedColor].length - 1}
                                sx={{ padding: '10px', flexGrow: 1, maxHeight: '50px', backgroundColor: currentImageIndex === mappImage[selectedColor].length - 1 ? '#F0F0F0' : '#C5B2FF', color: currentImageIndex === mappImage[selectedColor].length - 1 ? '#1E1E1E' : '#210D33' }}
                            >
                                Próxima Imagem
                            </Button>
                        </div>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: '0px' }}>
                            <img
                                src={ImageActive}
                                alt="modelo"
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                    objectFit: 'contain',
                                    transition: 'opacity 0.5s ease',
                                    minHeight: '200px'
                                }}
                            />
                        </Box>
                    </Grid>
                )}
            </Grid>

        </>
    );
};

export default ChoosePortal;